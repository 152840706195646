import * as React from 'react'
import type { WalletCardStaticProperties } from './WalletCard.types'
import WalletCardPaying from './WalletCardPaying'

const WalletCard: React.FC & WalletCardStaticProperties = () => {
    return <React.Fragment>WalletCard</React.Fragment>
}

WalletCard.Paying = WalletCardPaying

export default WalletCard
