import * as React from 'react'
import type { ProfilePatternProps } from './Sidebar.types'
import { LightenDarkenColor } from '@core/utils'

const ProfilePattern: React.FC<ProfilePatternProps> = ({ color }) => {
    const light = React.useMemo(() => LightenDarkenColor(color, 40), [color])
    const dark = React.useMemo(() => LightenDarkenColor(color, -20), [color])
    const pastel = React.useMemo(() => LightenDarkenColor(color, 20), [color])

    return (
        <svg
            width="20"
            height="82"
            viewBox="0 0 20 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13422_17415)">
                <path
                    d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0L16.8 0C17.9201 0 18.4802 0 18.908 0.217987C19.2843 0.409734 19.5903 0.715695 19.782 1.09202C20 1.51984 20 2.0799 20 3.2L20 78.8C20 79.9201 20 80.4802 19.782 80.908C19.5903 81.2843 19.2843 81.5903 18.908 81.782C18.4802 82 17.9201 82 16.8 82H16C10.3995 82 7.59921 82 5.46009 80.9101C3.57847 79.9513 2.04867 78.4215 1.08993 76.5399C0 74.4008 0 71.6005 0 66L0 16Z"
                    fill={color}
                />
                <rect
                    x="-23.2339"
                    y="37.0508"
                    width="44"
                    height="12"
                    rx="6"
                    transform="rotate(-45 -23.2339 37.0508)"
                    fill={pastel}
                />
                <rect
                    x="-11.9204"
                    y="65.335"
                    width="55"
                    height="20"
                    rx="10"
                    transform="rotate(-45 -11.9204 65.335)"
                    fill={light}
                />
                <rect
                    x="-13.3345"
                    y="55.4355"
                    width="44"
                    height="8"
                    rx="4"
                    transform="rotate(-45 -13.3345 55.4355)"
                    fill={pastel}
                />
                <rect
                    x="-12.6274"
                    y="83.0127"
                    width="44"
                    height="8"
                    rx="4"
                    transform="rotate(-45 -12.6274 83.0127)"
                    fill={dark}
                />
                <rect
                    x="-6.9707"
                    y="88.6689"
                    width="47"
                    height="15"
                    rx="7.5"
                    transform="rotate(-45 -6.9707 88.6689)"
                    fill={dark}
                />
                <rect
                    x="10"
                    y="71.6982"
                    width="44"
                    height="12"
                    rx="6"
                    transform="rotate(-45 10 71.6982)"
                    fill={light}
                />
                <rect
                    x="-18.9912"
                    y="49.7783"
                    width="44"
                    height="8"
                    rx="4"
                    transform="rotate(-45 -18.9912 49.7783)"
                    fill={pastel}
                />
                <rect
                    x="2.3457"
                    y="28.4414"
                    width="31.8251"
                    height="8"
                    rx="4"
                    transform="rotate(-45 2.3457 28.4414)"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_13422_17415">
                    <path
                        d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0L16.8 0C17.9201 0 18.4802 0 18.908 0.217987C19.2843 0.409734 19.5903 0.715695 19.782 1.09202C20 1.51984 20 2.0799 20 3.2L20 78.8C20 79.9201 20 80.4802 19.782 80.908C19.5903 81.2843 19.2843 81.5903 18.908 81.782C18.4802 82 17.9201 82 16.8 82H16C10.3995 82 7.59921 82 5.46009 80.9101C3.57847 79.9513 2.04867 78.4215 1.08993 76.5399C0 74.4008 0 71.6005 0 66L0 16Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ProfilePattern
