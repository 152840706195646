export default {
    GenerateQR: {
        route_header_title: 'Pay',
    },
    Capture: {
        empty_sheet_title: 'Processing Transaction...',
        empty_sheet_description:
            'Please do not close until the payment process is completed...',
        status_reject_message: 'Payment process has been canceled',
        cancel_alert_title: 'Cancel Payment',
        cancel_alert_message:
            'Are you sure you want to cancel the payment process?',
        cancel_alert_approve_button: 'Yes',
        cancel_alert_cancel_button: 'Cancel',
        action_button_cancel_title: 'Cancel',
        button_payment_title: 'Pay',
        price_header_title: 'Amount to Pay',
        price_header_description: 'Please pay attention to the payment details',
        wallet_row_title: 'WALLET',
        wallet_switch_title: 'Use Gift Points',
        toast_chip_label: 'Load Balance',
        toast_success_message: 'Balance load was successful',
        toast_error_message: 'You need to load your balance to make a payment',
        discount_chip_label: 'Discount',
    },
    PaymentRefund: {
        route_header_title: 'Expense Refund',
        submit_button: 'Request Refund',
        refund_title: 'REFUND REASON',
        refund_description: 'REFUND COMMENTS',
        refund_request_success: 'Refund request has been created',
        comment_placeholder: 'Enter comment',
    },
    PaymentRefundEftPos: {
        route_header_title: 'Expense Refund',
        order_title: 'Refund Number',
        title: 'Refund Warning',
        description:
            'For payments made with POS device, you need to provide the "Order Number" to the company for the refund of the expense amount',
        submit_button: 'Add Coupon',
    },
    utils: {
        PaymentAlert: {
            alert_title: 'Payment Successful',
            alert_message:
                'You can check wallet transactions to view payment details',
            alert_action_view_code: 'Show Child Code',
            alert_action_submit: 'OK',
        },
    },
}
