import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@components'
import type { TopUpHeaderProps } from './Header.types'

const TopUpHeader: React.FC<TopUpHeaderProps> = ({ buttonProps }) => {
    const { t } = useTranslation()

    return (
        <div className="sticky top-0 z-50 bg-semantic-content-brand lg:static lg:z-auto">
            <div className="lg:container lg:h-[224px] lg:pt-8">
                <div className="flex items-center gap-6 p-6 lg:p-0">
                    <IconButton
                        variant="bg-blurred"
                        name="arrow-left"
                        className="bg-tint-white-10 fill-white focus:ring-0"
                        {...buttonProps}
                    />
                    <h2 className="text-display-md-b text-semantic-background-inkInverse lg:text-display-lg-b">
                        {t('@deposit.TopUp.route_header_title')}
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default TopUpHeader
