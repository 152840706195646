import * as React from 'react'
import type { HeaderProps, HeaderStaticProperties } from './Header.types.ts'
import HeaderModules from './HeaderModules'
import MoneyWrapper from './HeaderMoneyWrapper'
import HeaderOnboarding from './HeaderOnboarding'
import HeaderTop from './HeaderTop'

const Header: React.FC<HeaderProps> & HeaderStaticProperties = ({
    color,
    children,
}) => {
    return (
        <section
            className="wallet-detail-header animate-enter"
            style={{ backgroundColor: color }}>
            <div className="container">{children}</div>
        </section>
    )
}

Header.Top = HeaderTop
Header.MoneyWrapper = MoneyWrapper
Header.Modules = HeaderModules
Header.Onboarding = HeaderOnboarding

export default Header
