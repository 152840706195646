import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import { InputCurrency } from '@components'
import i18n from '@core/translations/Translator.ts'
import type { CustomAmountFieldInputProps } from './CustomAmountField.types'

const CustomAmountFieldInput: React.FC<CustomAmountFieldInputProps> = ({
    amount,
    currency,
    maximumDepositAmount,
    isVisible,
    onChangeValue,
}) => {
    const details = React.useMemo(() => {
        if (!currency) return { symbol: '', seperator: '.', decimal: ',' }
        const symbol = CurrencyFormatter.getSymbol(currency)
        const seperators = CurrencyFormatter.getLocaleSeparators(
            i18n.getLocale(),
        )

        return {
            symbol: symbol,
            decimal: seperators.decimal,
            seperator: seperators.separator,
        }
    }, [currency])

    if (!isVisible) return null

    return (
        <div
            data-state={isVisible}
            className="data-[state=true]:animate-in data-[state=true]:fade-in data-[state=false]:zoom-out-90 data-[state=true]:zoom-in-90">
            <InputCurrency
                value={amount === 0 ? '' : amount}
                className="mt-6"
                placeholder={`${details.symbol}0`}
                autoFocus={true}
                prefix={details.symbol}
                decimalSeparator={details.seperator}
                groupSeparator={details.decimal}
                decimalScale={0}
                maxLength={maximumDepositAmount.toString().length}
                allowNegativeValue={false}
                onValueChange={value => {
                    if (value === undefined) return onChangeValue(0)
                    onChangeValue(Number(value))
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                step={1}
            />
        </div>
    )
}
export default CustomAmountFieldInput
