import type {
    InstallmentCardComponentSizes,
    InstallmentCardItemComponentSizes,
} from './InstallmentCard.types.ts'

export const InstallmentCardSizes: InstallmentCardComponentSizes = {
    closedHeight: 195,
}

export const InstallmentCardItemSizes: InstallmentCardItemComponentSizes = {
    height: 65,
}
