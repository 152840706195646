import type { SidebarMenuItemProps } from './Sidebar.types'

export const SidebarMenuItem: SidebarMenuItemProps[] = [
    {
        title: 'content_config.Sidebar.wallet',
        icon: 'wallet',
        pathname: '/',
    },
    {
        title: 'content_config.Sidebar.account_activity',
        icon: 'document',
        pathname: '/account-activities',
    },
    {
        title: 'content_config.Sidebar.campaigns',
        icon: 'explore',
        pathname: '/campaign',
    },
]
