import * as React from 'react'
import type { UseTextInputMaskFunction } from './Input.types'
import {
    getCreditCardMask,
    getCVCMask,
    getDateTimeMask,
    getOnlyNumbersMask,
} from './Input.utils'

export const useMaskTextInput: UseTextInputMaskFunction = (
    type,
    value,
    options,
) => {
    return React.useMemo(() => {
        if (type === 'only-numbers') return getOnlyNumbersMask(value.length + 1)

        if (type === 'date-time')
            return getDateTimeMask(options?.dateTimePattern ?? 'DD/MM/YYYY')

        if (type === 'credit-card') return getCreditCardMask(value)

        if (type === 'cvc') return getCVCMask(options?.cvcReferenceValue ?? '')

        return ''
    }, [options?.cvcReferenceValue, options?.dateTimePattern, type, value])
}
