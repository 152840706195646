import * as React from 'react'
import { IconButton } from '@components'
import { cn } from '@core/utils'
import { useCarousel } from './Carousel.context'

const CarouselPrevious = React.forwardRef<
    HTMLButtonElement,
    React.ComponentProps<typeof IconButton>
>(({ className, variant = 'bg-blurred', size = 'small', ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel()

    return (
        <IconButton
            ref={ref}
            variant={variant}
            size={size}
            name="arrow-left"
            className={cn(
                'absolute h-8 w-8 rounded-full',
                orientation === 'horizontal'
                    ? '-left-12 top-1/2 -translate-y-1/2'
                    : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
                className,
            )}
            disabled={!canScrollPrev}
            onClick={scrollPrev}
            {...props}>
            <span className="sr-only">Previous slide</span>
        </IconButton>
    )
})
CarouselPrevious.displayName = 'CarouselPrevious'

export default CarouselPrevious
