const LightenDarkenColor = (col: string, amt: number): string => {
    col = col?.startsWith('#') ? col.slice(1) : col

    const num = parseInt(col, 16)

    let r = (num >> 16) + amt
    let g = ((num >> 8) & 0x00ff) + amt
    let b = (num & 0x0000ff) + amt

    r = Math.min(Math.max(0, r), 255)
    g = Math.min(Math.max(0, g), 255)
    b = Math.min(Math.max(0, b), 255)

    const result = (r << 16) | (g << 8) | b
    return '#' + result.toString(16).padStart(6, '0')
}

export default LightenDarkenColor
