export default {
    CouponApply: {
        route_header_title: 'Kupon Ekle',
        content_header_description:
            'Kupon kodunu aşağı girerek puan kazanabilirsin',
        coupon_add_button: 'Kupon Ekle',
        coupon_toast_message:
            '{{amount}} puan değerindeki kupon başarıyla tanımlandı',
        coupon_input_placeholder: 'Kupon Kodu Giriniz',
    },
    AccountActivitySectionList: {
        empty_sheet_title: 'Hesap Haraketi Yok',
        empty_sheet_description: 'Herhangi bir hesap hareketi bulunamadı',
    },
    WalletDetail: {
        header_title: 'BAKİYE',
        header_point: 'Puan: {{point}}',
        help: 'Yardım',
        help_description: 'Bir sorun mu var?',
        read_qr: 'QR Öde',
        menu_earnings_title: 'Kazançlarım',
        menu_earnings_subtitle: "Macellan SuperApp'in bütçene katkısı",
        menu_brand_title: 'Marka Hakkında',
        menu_brand_subtitle: 'Menü, kampanyalar, şubeler ve daha fazlası',
        wallet_history_title: 'Cüzdan Hareketleri',
        wallet_history_subtitle: 'Gerçekleştirilen son 5 işlem',
        wallet_history_all: 'Tüm Hareketler',
        agreement_updated:
            '{{wallet}} cüzdanına ait sözleşme koşulları güncellenmiştir',
        passive_status_toast_message: '{{wallet}} cüzdanı pasife alınmıştır',
        wallet_benefits_title: 'Cüzdan avantajları nelerdir?',
    },
    WalletModules: {
        route_header_title: 'Tüm İşlemler',
        payment: 'Ödeme Yap',
        deposit: 'Bakiye Yükle',
        coupon: 'Kupon Ekle',
        wallet_share: 'Cüzdan Paylaş',
        gift_card: 'Hediye Kartı',
        ticket_system: 'Biletlerim',
        branches: 'Şubeler',
        history: 'Hesap Hareketleri',
        help: 'Yardım',
        agreement: 'Cüzdan Sözleşmesi',
        wallet_visibility: 'Cüzdan Görünürlüğü',
    },
    AccountActivities: {
        content_header_title: 'Hesap Hareketleri',
        content_header_description:
            'Tüm hesap hareketlerine buradan kolayca ulaşabilirsin',
        search_field_placeholder: 'Filtrele',
    },
    AccountActivitiesFilter: {
        content_header_title: 'Hareketleri Filtrele',
        content_header_description:
            'Gerekli filtrelemeleri yap ve sonuçlara kolayca ulaş',
        categories: 'KATEGORİLER',
        filters: 'FİLTRELER',
        date: 'TARİH ARALIĞI',
        started_date: 'Başlangıç Tarihi',
        end_date: 'Bitiş Tarihi',
        submit: 'Sonuçları Göster',
        balance: 'Bakiye',
        refund: 'İade',
        payment_refund: 'Harcama İadesi',
        deposit_refund: 'Yükleme İadesi',
        cash_deposit_refund: 'Nakit Yükleme İadesi',
        capture: 'Bakiye Harcama',
        top_up: 'Bakiye Yükleme',
        date_time_place_holder: 'Örn. 07/07/2023',
    },
    AccountActivityDetail: {
        content_header_title: 'İşlem Detayı',
        content_header_description:
            'Tüm işlem detaylarına buradan kolayca ulaşabilirsin',
        refund_reason: 'İade Nedeni',
        customer_comment: 'Müşteri Açıklaması',
        company_comment: 'İşletme Açıklaması',
        reference_number: 'Talep No',
        refund_button_title: 'İade İste',
        branch_name: 'Şube Adı',
        cashier: 'Kasiyer',
        total_earned_points: 'Toplam Kazanılan Puan',
        point_group: 'Puan Grubu',
        earned_points: 'Kazanılan Puan',
        earned_wallet: 'Kazanılan Cüzdan',
        top_up: 'Bakiye Yükleme',
        capture: 'Bakiye Harcama',
        process_type: 'İşlem Tipi',
        spent_amount: 'Harcama Miktarı',
        payment_point: 'Ödeme Noktası',
        order_id: 'Sipariş Numarası',
        date: 'Tarih',
        discount_amount: 'İndirim Tutarı',
        amount_after_discount: 'İndirim Sonrası Tutar',
        spent_point: 'Harcanan Puan',
        spent_balance: 'Harcanan Bakiye',
        wallet_type: 'Cüzdan Tipi',
        shared_wallet: 'Paylaşılan Cüzdan',
        sharer: 'Paylaşan Kişi',
        shared: 'Paylaşılan  Kişi',
        expire_date: 'Bitiş Tarihi',
        description: 'Açıklama',
        gift_card: 'Hediye Kartı',
        undefined_user: 'Tanımlanamayan Kullanıcı',
        loaded_amount: 'Yükleme Miktarı',
        bank_amount: 'Karta İade Edilen Miktar',
        point_deducted_balance: 'Bakiyeden Düşülen Puan',
        total_revoked_point: 'İptal Edilen Puan',
        reference_code: 'Referans Kod',
        loaded_type: 'Yükleme Tipi',
        cash: 'Nakit Yükleme',
        dcb: 'Faturama Yansıt',
        card_number: 'Kart Numarası',
        payment_refund: 'Harcama İadesi',
        deposit_refund: 'Yükleme İadesi',
        cash_deposit_refund: 'Nakit Yükleme İadesi',
        refunded_amount: 'İade Edilen Tutar',
        refunded_point: 'İade Edilen Puan',
        transaction_details: 'İşlem Bilgileri',
        branch_details: 'Firma Bilgileri',
        additional_details: 'Detay Bilgiler',
        soft_play_details: 'Soft Play Bilgileri',
        bank_transfer: 'Banka Transferi',
        payment_refund_history: 'HARCAMA GEÇMİŞİ',
        payment_refund_history_spent_amount: 'Harcama Miktarı',
        soft_play_child: 'Çocuk',
        soft_play_duration: 'Süre',
        soft_play_price: 'Ücret',
        installment_count: 'Taksit Sayısı',
        total_installment_amount: 'Toplam Taksitli Tutar',
    },
}
