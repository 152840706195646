import * as React from 'react'
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom'
import { isEqual } from 'lodash'
import { Footer, Sidebar } from '@components'
import { TopUpProvider } from '@core/context'
import { SocketService } from '@core/services'
import { useAppSelector } from '@core/store'
import { NotificationProvider, useSocketListener } from '@core/hooks'
import AuthMobileHeader from './AuthMobileHeader.tsx'
import '../Layout.css'

const Auth: React.FC = () => {
    const user = useAppSelector(state => state.baseSlice.me)
    const company = useAppSelector(state => state.baseSlice.company)
    const platform = useAppSelector(state => state.baseSlice.platform)
    const token = localStorage.getItem('user.access_token')
    useSocketListener()

    React.useEffect(() => {
        if (!token) return
        SocketService.connect()
    }, [token])

    if (!token) return <Navigate to="/404" replace />

    return (
        <React.Fragment>
            <NotificationProvider>
                <TopUpProvider>
                    <div className="flex flex-col lg:flex-row">
                        <Sidebar
                            fullName={user.first_name}
                            logo={company.logo}
                            avatarUrl={user.details.avatar_url}
                            color={company.color}
                            isMobile={isEqual(platform, 'mobile_waas')}
                        />
                        <AuthMobileHeader
                            fullName={user.first_name}
                            logo={company.logo}
                            avatarUrl={user.details.avatar_url}
                        />
                        <main className="main-wrapper">
                            <div className="main-content">
                                <Outlet />
                            </div>
                            <Footer />
                        </main>
                    </div>
                </TopUpProvider>
            </NotificationProvider>
            <ScrollRestoration />
        </React.Fragment>
    )
}

export default Auth
