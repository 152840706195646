import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge } from '@components'
import i18n from '@core/translations'
import type { CapturePriceProps } from './Capture.types'

const CapturePrice: React.FC<CapturePriceProps> = ({
    amount,
    currency,
    discountedAmount,
    discountPercent,
    hasDiscount,
}) => {
    const { t } = useTranslation()

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
    }, [amount, currency])

    const formattedDiscountedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(
            discountedAmount,
            i18n.getLocale(),
            currency,
        )
    }, [currency, discountedAmount])

    return (
        <div className="container flex flex-col gap-12 border-b border-b-tint-ink-10 bg-semantic-background-secondary pb-[72px] pt-6">
            <div className="space-y-2">
                <h3 className="text-display-sm text-semantic-content-ink">
                    {t('@payment.Capture.price_header_title')}
                </h3>
                <p className="text-body-md-r text-semantic-content-inkLight">
                    {t('@payment.Capture.price_header_description')}
                </p>
            </div>
            <div className="flex flex-col gap-2">
                {hasDiscount ? (
                    <span className="text-display-sm text-semantic-content-ink line-through">
                        {formattedAmount}
                    </span>
                ) : null}
                <span className="text-title-lg-b text-semantic-content-ink">
                    {formattedDiscountedAmount}
                </span>
                {hasDiscount ? (
                    <div className="mt-3 flex items-center gap-3">
                        <span className="text-body-md-r text-semantic-content-inkLight">
                            {t('@payment.Capture.discount_chip_label')}
                        </span>
                        <Badge
                            variant="green"
                            size="small"
                            children={`%${discountPercent}`}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default CapturePrice
