import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { filter, map } from 'lodash'
import { Badge } from '@components'
import { AccountActivitiesSectionHeader } from '@wallet/views'
import type { FilterSectionProps } from './AccountActivitiesFilter.types'

const FilterSection: React.FC<FilterSectionProps> = ({
    filters,
    selectedFilters,
    handleOnClick,
}) => {
    const { t } = useTranslation()

    if (!filters?.length) return null

    return (
        <div className="space-y-2 duration-300 animate-in zoom-in-95">
            <AccountActivitiesSectionHeader
                title={t('@wallet.AccountActivitiesFilter.filters')}
            />
            <div className="flex flex-wrap gap-2">
                {map(filters, (item, index) => (
                    <Badge
                        key={index.toString() + item.type}
                        variant={
                            filter(
                                selectedFilters,
                                filteredItem => filteredItem.type === item.type,
                            ).length > 0
                                ? 'brand-tonal'
                                : 'outlined'
                        }
                        className="animate-in fade-in"
                        children={t(
                            `@wallet.AccountActivitiesFilter.${item.labelType}`,
                        )}
                        size="medium"
                        onClick={() => {
                            handleOnClick(item)
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
export default FilterSection
