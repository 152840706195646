import React from 'react'
import { Skeleton } from '@components'
import type { AccountActivitySkeletonProps } from './AccountActivityList.types'
import { cn } from '@core/utils'

const AccountActivityLoader: React.FC<AccountActivitySkeletonProps> = ({
    limit = 5,
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'animate-pulse overflow-y-hidden animate-in',
                className,
            )}
            {...props}>
            <div>
                <Skeleton className="mb-3 mt-6 h-2 w-[100px] rounded bg-semantic-background-secondary" />
                {[...Array(limit)].map((_, index) => (
                    <div className="space-y-4 py-3" key={index}>
                        <div className="flex items-center space-x-4 px-4">
                            <Skeleton className="h-12 w-12 shrink-0 rounded-full bg-semantic-background-secondary" />
                            <div className="flex w-full items-center justify-between gap-4">
                                <Skeleton className="col-span-2 h-2 w-full rounded bg-semantic-background-secondary md:w-[250px]" />
                                <div className="flex shrink-0 flex-col items-center justify-center gap-2">
                                    <Skeleton className="col-span-1 ml-auto h-2 w-16 rounded bg-semantic-background-secondary" />
                                    <Skeleton className="col-span-1 ml-auto h-2 w-16 rounded bg-semantic-background-secondary" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AccountActivityLoader
