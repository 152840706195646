import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import type { RewardChartTableProps } from './RewardChart.types'
import { useContentConfig } from '@core/hooks'
import RewardChartTableList from './RewardChartTableList'

const RewardChartTable: React.FC<RewardChartTableProps> = ({
    amount,
    color,
    currency,
    amountList,
}) => {
    const content = useContentConfig()

    const formattedDetails = React.useMemo(() => {
        return CurrencyFormatter.formatToDetails(
            amount,
            content.locale,
            currency,
        )
    }, [amount, content.locale, currency])

    return (
        <div className="reward-chart__container">
            <div className="reward-chart__left-wrapper">
                <h6 className="reward-chart__left-wrapper-title">
                    {content.rewardChart.tableTypeTitle}
                </h6>
                <div className="reward-chart__left-wrapper-content">
                    <div className="reward-chart-progress__left-wrapper-amount">
                        <span>{formattedDetails.symbol}</span>
                        <span>{formattedDetails.digid}</span>
                    </div>
                    <p className="text-body-sm-r text-semantic-content-ink">
                        {content.rewardChart.tableTypeDescription}
                    </p>
                </div>
            </div>
            <div className="grid justify-end lg:items-center lg:justify-center">
                <RewardChartTableList
                    amountList={amountList}
                    color={color}
                    currency={currency}
                />
            </div>
        </div>
    )
}

export default RewardChartTable
