import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { IconButton, Timer, type TimerMethods } from '@components'
import { useTheme } from '@core/utils'
import { HeaderOnboardingContents } from './Header.constants'

const HeaderOnboarding = () => {
    const { t } = useTranslation()
    const theme = useTheme()

    const [currentIndex, setCurrentIndex] = React.useState<number>(0)

    const currentItem = React.useMemo(() => {
        return HeaderOnboardingContents[currentIndex]
    }, [currentIndex])

    const timerRefs = React.useMemo(() => {
        return HeaderOnboardingContents.map(() =>
            React.createRef<TimerMethods>(),
        )
    }, [])

    const swiperItemDurations = React.useMemo(() => {
        return map(HeaderOnboardingContents, item => item.duration)
    }, [])

    const handleOnAnimationFinish = React.useCallback(() => {
        if (currentIndex < HeaderOnboardingContents.length - 1) {
            setCurrentIndex(currentIndex + 1)
            timerRefs[currentIndex + 1]?.current?.resetTimer(true)
            return
        }

        timerRefs.forEach((timer, index) =>
            timer?.current?.resetTimer(index === 0),
        )

        setCurrentIndex(0)
    }, [currentIndex, timerRefs])

    React.useEffect(() => {
        const interval = setInterval(() => {
            handleOnAnimationFinish()
        }, swiperItemDurations[currentIndex])
        return () => clearInterval(interval)
    }, [currentIndex, handleOnAnimationFinish, swiperItemDurations, timerRefs])

    return (
        <div className="relative hidden justify-end xl:flex">
            <div className="header-onboarding__masked animate-enter">
                <div className="header-onboarding__content">
                    <div className="space-y-1">
                        <h4
                            data-slide={currentIndex}
                            key={`${currentIndex}-title`}>
                            {t(currentItem.title)}
                        </h4>
                        <p
                            data-slide={currentIndex}
                            key={`${currentIndex}-description`}>
                            {t(currentItem.description)}
                        </p>
                    </div>
                    <IconButton
                        size="small"
                        name="arrow-right"
                        data-slide={currentIndex}
                        key={`${currentIndex}-button`}
                        className="bg-solid-green-5 focus:ring-tint-green-20"
                    />
                </div>
                <img src={currentItem.image} alt="Onboarding" />
            </div>
            <div className="absolute bottom-[18px] left-2 flex w-[72px] gap-2">
                {map(HeaderOnboardingContents, (_, index) => (
                    <Timer.Bar
                        ref={timerRefs[index]}
                        key={index}
                        auto={index === currentIndex}
                        duration={swiperItemDurations[currentIndex]}
                        className="bg-tint-white-60"
                        indicatorColor={theme.colors.tint.white['100']}
                    />
                ))}
            </div>
        </div>
    )
}

export default HeaderOnboarding
