import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type { BadgeVariantProps } from '@components'
import type { IconProps } from '@components'
import i18n from '@core/translations'
import { useTheme } from '@core/utils'

const useContentConfig = () => {
    const { t } = useTranslation()
    const theme = useTheme().colors

    const contents = React.useMemo(() => {
        const data = {
            locale: i18n.getLocale(),
            historyItem: {
                types: [
                    {
                        key: 'payment_refund',
                        icon: 'refund' as IconProps['name'],
                        title: t('content_config.HistoryItem.refund'),
                        amountColor: theme.semantic.content.error,
                        pointBadgeType: 'brand' as BadgeVariantProps['variant'],
                    },
                    {
                        key: 'deposit_refund',
                        icon: 'refund' as IconProps['name'],
                        title: t('content_config.HistoryItem.refund'),
                        amountColor: theme.semantic.content.error,
                        pointBadgeType: 'brand' as BadgeVariantProps['variant'],
                    },
                    {
                        key: 'cash_deposit_refund',
                        icon: 'refund' as IconProps['name'],
                        title: t('content_config.HistoryItem.refund'),
                        amountColor: theme.semantic.content.error,
                        pointBadgeType: 'brand' as BadgeVariantProps['variant'],
                    },
                    {
                        key: 'top-up',
                        icon: 'top-up' as IconProps['name'],
                        title: t('content_config.HistoryItem.topUp'),
                        amountColor: theme.semantic.content.success,
                        pointBadgeType:
                            'yellow' as BadgeVariantProps['variant'],
                    },
                    {
                        key: 'capture',
                        icon: 'fullscreen' as IconProps['name'],
                        title: t('content_config.HistoryItem.capture'),
                        amountColor: theme.semantic.content.ink,
                        pointBadgeType:
                            'yellow' as BadgeVariantProps['variant'],
                    },
                    {
                        key: 'approve',
                        icon: 'warning' as IconProps['name'],
                        title: t('content_config.HistoryItem.refund_approval'),
                        amountColor: theme.semantic.content.ink,
                        pointBadgeType: 'brand' as BadgeVariantProps['variant'],
                    },
                    {
                        key: 'close',
                        icon: 'warning' as IconProps['name'],
                        title: t('content_config.HistoryItem.refund_reject'),
                        amountColor: theme.semantic.content.error,
                        pointBadgeType: 'brand' as BadgeVariantProps['variant'],
                    },
                ],
            },
            campaignItem: {
                detailsButtonText: t(
                    'content_config.CampaignItem.details_button_text',
                ),
                disabledButtonText: t(
                    'content_config.CampaignItem.disabled_button_text',
                ),
                remainingTime: t('content_config.CampaignItem.remaining_time'),
                day: t('content_config.CampaignItem.day'),
                pointSuffix: t('content_config.CampaignItem.point_suffix'),
            },
            rewardChart: {
                tableTypeTitle: t(
                    'content_config.RewardChart.table_type_title',
                ),
                progressTypeTitle: t(
                    'content_config.RewardChart.progress_type_title',
                ),
                progressTypeDescription: t(
                    'content_config.RewardChart.progress_type_description',
                ),
                tableTypeDescription: t(
                    'content_config.RewardChart.table_type_description',
                ),
            },
        }
        return data
    }, [t, theme])

    return contents
}

export default useContentConfig
