import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import baseApi, { type QueryTagTypes } from '@core/api'
import { useAppDispatch } from '@core/store'
import type {
    TopUpCompleteEvent,
    TopUpProviderProps,
} from './TopUpContext.types'
import { useNotification } from '@core/hooks'
import { emitter, SendPostMessage, useTheme } from '@core/utils'
import TopUpContext from './TopUpContext'

const TopUpProvider: React.FC<TopUpProviderProps> = ({ children }) => {
    const theme = useTheme().colors

    const { t } = useTranslation()
    const { alert } = useNotification()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const update = React.useCallback(
        (updateTags: QueryTagTypes) => {
            dispatch(baseApi.util.invalidateTags(updateTags))
        },
        [dispatch],
    )

    const complete = React.useCallback(
        async (event: TopUpCompleteEvent) => {
            update([
                'WalletDetail',
                'BalanceHistoryList',
                'DepositCampaignDetail',
            ])

            debounce(
                () =>
                    alert({
                        icon: 'check-circle',
                        iconColor: theme.semantic.content.success,
                        title: t('context.TopUpProvider.alert_success_title'),
                        message: t(
                            'context.TopUpProvider.toast_success_message',
                        ),
                        duration: 10000,
                        actions: [
                            {
                                children: t(
                                    'context.TopUpProvider.alert_success_action',
                                ),
                            },
                        ],
                    }),
                750,
            )()

            SendPostMessage(
                JSON.stringify({ success: true }),
                'deposit-success',
            )

            if (event?.canGoBack)
                return navigate(event.canGoBack.path, {
                    state: {
                        ...event.canGoBack,
                    },
                })

            navigate('/', {
                state: { walletId: event.walletId },
                replace: true,
            })
        },
        [alert, navigate, t, theme, update],
    )

    React.useEffect(() => {
        emitter.on('topup-complete', complete)

        emitter.on('topup-update', update)

        return () => {
            emitter.off('topup-complete', complete)

            emitter.off('topup-update', update)
        }
    }, [complete, update])

    return (
        <TopUpContext.Provider value={undefined}>
            {children}
        </TopUpContext.Provider>
    )
}

export default TopUpProvider
