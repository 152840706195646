export default {
    CampaignRewardList: {
        campaign_section_title: 'Kampanyalar',
        campaign_section_description:
            'Fırsatları takip et, bütçene katkı sağla',
        campaigns_chips_label_all: 'Tüm Kampanyalar',
    },
    CampaignSegmentedList: {
        segmented_section_topup_title: 'Yükle Kazan',
        segmented_section_capture_title: 'Harca Kazan',
    },
    Campaigns: {
        route_header_title: 'Kampanyalar',
        route_header_description:
            'Tüm kampanyalara buradan kolayca ulaşabilirsin.',
        campaigns_chips_label_all: 'Tümü',
        campaigns_chips_label_deposit_gift: 'Yükle Kazan',
        campaigns_chips_label_payment_count_gift: 'Harca Kazan',
        section_bar_title: 'Sana Özel',
        empty_sheet_title: 'Kampanya Bulunamadı',
        empty_sheet_description: 'Aradığın kriterlere göre kampanya bulunamadı',
    },
    CampaignDetail: {
        route_header_title: 'Kampanya Detay',
        content_header_left_title: 'SON TARİH',
        content_header_right_title: 'HEDİYE PUAN',
        footer_deposit_button: 'Bakiye Yükle',
        footer_add_wallet_button: 'Cüzdan Ekle',
        footer_deposit_description: 'Fırsattan faydalanmak için bakiye yükle!',
        footer_add_wallet_description:
            'Fırsattan faydalanmak için cüzdanı ekle!',
        campaign_detail_share: 'Paylaş:',
        condition_participation_title: 'Katılım Koşulları',
        condition_participation_button_more_title: 'Devamını Oku',
        condition_participation_button_hide_title: 'Gizle',
        other_campaigns_title: 'İlgini Çekebilir',
        other_campaigns_description: 'Markaya ait diğer kampanyalar',
        payment_count_gift_toast_message:
            '{{formattedEarnablePoint}} hediye puan kazanmana {{remainderCount}} harcama kaldı',
        deposit_gift_toast_message:
            'Bu kampanyadan {{usageCount}} kez faydalandın',
        deposit_gift_disposable_toast_message:
            'Bu kampanyadan zaten faydalandın',
        toast_message_chip_title: 'Kazanılan: {{formattedWonPoint}}',
    },
}
