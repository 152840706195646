import React from 'react'
import type { RowDescriptionProps } from './Row.types'
import { cn } from '@core/utils'

const RowDescription: React.FC<RowDescriptionProps> = props => {
    const { title, message, className } = props

    return (
        <div
            className={cn(
                'py-5; flex flex-col gap-2 border-b border-semantic-background-tertiary',
                className,
            )}>
            <h3 className="text-body-md-b text-semantic-content-ink">
                {title}
            </h3>
            <p className="text-body-sm-r text-semantic-content-ink">
                {message}
            </p>
        </div>
    )
}

export default RowDescription
