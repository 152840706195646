import * as React from 'react'
import { Skeleton } from '@components'
import {
    CampaignRewardListLoader,
    CampaignSegmentedList,
} from '@campaign/components'
import { AccountActivityList } from '@wallet/components'

const WalletDetailLoader: React.FC = () => {
    return (
        <div className="space-y-10">
            <Skeleton className="h-[277px] w-full rounded-none py-12 lg:h-[365px] xl:h-[444px]" />
            <div className="container flex flex-col gap-10">
                <CampaignRewardListLoader />
                <CampaignSegmentedList.Loader limit={3} />
                <AccountActivityList.Loader className="pb-8" />
            </div>
        </div>
    )
}
export default WalletDetailLoader
