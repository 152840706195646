import * as React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import type { ProgressBarProps } from './Progress.types'
import { cn } from '@core/utils'

const ProgressBar = React.forwardRef<
    React.ElementRef<typeof ProgressPrimitive.Root>,
    ProgressBarProps
>(({ className, value, indicatorColor, ...props }, ref) => (
    <ProgressPrimitive.Root
        ref={ref}
        className={cn(
            'relative h-1 w-full overflow-hidden rounded-full bg-semantic-background-tertiary',
            className,
        )}
        {...props}>
        <ProgressPrimitive.Indicator
            className="h-full w-full flex-1 bg-semantic-content-ink transition-none"
            style={{
                transform: `translateX(-${100 - (value || 0)}%)`,
                backgroundColor: indicatorColor,
            }}
        />
    </ProgressPrimitive.Root>
))

ProgressBar.displayName = 'ProgressBar'
export default ProgressBar
