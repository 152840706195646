import * as React from 'react'
import { map } from 'lodash'
import { Badge } from '@components'
import type { PaymentRefundReasonListProps } from './PaymentRefund.types'

const PaymentRefundReasonList: React.FC<PaymentRefundReasonListProps> = ({
    reasons,
    activeReason,
    onClick,
}) => {
    return (
        <div className="flex flex-wrap gap-2">
            {map(reasons, (item, index) => (
                <Badge
                    type="button"
                    key={index.toString() + item.value}
                    style={{ borderWidth: 1 }}
                    variant={
                        activeReason?.value === item.value
                            ? 'brand-tonal'
                            : 'outlined'
                    }
                    children={item.label}
                    size="medium"
                    onClick={() => onClick(item)}
                />
            ))}
        </div>
    )
}
export default PaymentRefundReasonList
