import MasterPassLogo from '@core/assets/images/MasterPassLogo.jpeg'
import type { CardType } from '@deposit/api'

const SupportedDepositMethods: CardType[] = ['masterpass']

const Titles = {
    masterpass: 'MasterPass',
}

const Logos = {
    masterpass: MasterPassLogo,
}

export default {
    SupportedDepositMethods: SupportedDepositMethods,
    Titles: Titles,
    Logos: Logos,
}
