import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge } from '@components'
import i18n from '@core/translations'
import type { HeaderMoneyWrapperProps } from './Header.types'

const HeaderMoneyWrapper: React.FC<HeaderMoneyWrapperProps> = ({
    balance,
    point,
    currency,
}) => {
    const { t } = useTranslation()

    const formattedBalance = React.useMemo(() => {
        return CurrencyFormatter.formatToDetails(
            balance,
            i18n.getLocale(),
            currency,
        )
    }, [balance, currency])

    const formattedPoint = React.useMemo(() => {
        return CurrencyFormatter.format(point, i18n.getLocale(), currency)
    }, [currency, point])

    return (
        <div className="wallet-detail-header__money-wrapper">
            <div className="wallet-detail-header__money-wrapper-balance animate-enter">
                <span className="wallet-detail-header__money-wrapper-balance-text">
                    {t('@wallet.WalletDetail.header_title')}
                </span>
                <div className="wallet-detail-header__money-wrapper-balance-amount">
                    <span className="wallet-detail-header__money-wrapper-balance-amount-currency">
                        {formattedBalance?.symbol}
                    </span>
                    <span className="wallet-detail-header__money-wrapper-balance-amount-text">
                        {formattedBalance?.digid}
                    </span>
                </div>
            </div>
            <Badge
                as="span"
                variant="yellow"
                size="small"
                className="w-fit px-4"
                children={t('@wallet.WalletDetail.header_point', {
                    point: formattedPoint,
                })}
            />
        </div>
    )
}

export default HeaderMoneyWrapper
