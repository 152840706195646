import baseApi from '@core/api'
import type {
    DetailsData,
    DetailsResponse,
    WalletSubscribeData,
    WalletSubscribeResponse,
} from './CompanyApi.types'

const companyApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        details: builder.query<DetailsResponse, DetailsData>({
            query: data => ({
                url: `v2/company/${data.company_id}/details`,
                method: 'GET',
            }),
        }),
        walletSubscribe: builder.mutation<
            WalletSubscribeResponse,
            WalletSubscribeData
        >({
            query: data => ({
                url: 'v2/wallet/subscribe',
                method: 'POST',
                body: data,
            }),
        }),
    }),
    overrideExisting: true,
})

export default companyApi
