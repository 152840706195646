export default {
    Onboarding: {
        swiper_items_1_title: 'Fırsatları Keşfet',
        swiper_items_1_description:
            'Markaların dijital cüzdanlarını\ntek bir uygulamada kullan, fırsatları kaçırma!',
        swiper_items_2_title: 'Cüzdan Oluştur',
        swiper_items_2_description:
            'Dilediğin markayı seç,\ndijital cüzdanını anında oluştur!',
        swiper_items_3_title: 'Hızlı ve Güvenle Öde',
        swiper_items_3_description:
            'Harcamanı Macellan SuperApp üzerinden gerçekleştir,\nQR kod ile güvenle öde!',
        login_button: 'Giriş Yap',
        register_button: 'Kayıt Ol',
        copyright: `Macellan İleri Teknoloji A.Ş. © ${new Date().getFullYear()}. Her hakkı saklıdır.`,
    },
    OTPVerify: {
        hello_full_name: 'Merhaba {{fullName}}',
        content_header_title: 'Onay Kodunu Gir',
        content_header_description:
            '{{phone_number}} numaralı telefonuna gönderilen kodu gir',
        resend_toast_message: 'Doğrulama kodun tekrar gönderildi',
        resend_verify_button: 'Tekrar Gönder',
        resend_verify_second: 'sn',
        sms_not_received: 'SMS kodun gelmedi mi?',
        send_to_email: 'E-postama gönder',
        update_phone_success_message: 'Telefon numarası başarıyla güncellendi',
    },
    CompanyWalletSubscribe: {
        route_header_title: '{{company_name}} CÜZDAN SÖZLEŞMESİ',
        add_button_title: 'Okudum, Onaylıyorum',
        agreement_description:
            'Cüzdanı eklediğinizde <0 href="{{agreement_url}}">Cüzdan Sözleşmesi</0>‘ni kabul etmiş sayılırsınız',
        agreement_message:
            'Fırsattan faydalanmak için cüzdan sözleşmesini onaylayın!',
        success_message: 'Cüzdanın başarıyla oluşturuldu',
    },
}
