import * as React from 'react'
import { cn } from '@core/utils'
import { useCarousel } from './Carousel.context'

const CarouselContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel()

    return (
        <div ref={carouselRef} className="overflow-hidden">
            <div
                ref={ref}
                className={cn(
                    'flex',
                    orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
                    className,
                )}
                {...props}
            />
        </div>
    )
})
CarouselContent.displayName = 'CarouselContent'

export default CarouselContent
