import * as React from 'react'
import { Progress } from '@components'
import type { RewardChartProgressProps } from './RewardChart.types'
import { useContentConfig, useWindowSize } from '@core/hooks'
import { useTheme } from '@core/utils'
import { RewardChartSizes } from './RewardChart.constants'
import './RewardChart.css'

const RewardChartProgress: React.FC<RewardChartProgressProps> = ({
    name,
    color,
    processCount,
    remainderCount,
    progressProps,
}) => {
    const theme = useTheme()
    const content = useContentConfig()
    const windowSize = useWindowSize().width

    return (
        <div className="reward-chart__container">
            <div className="reward-chart__left-wrapper">
                <h6 className="reward-chart__left-wrapper-title">
                    {content.rewardChart.progressTypeTitle}
                </h6>
                <div className="reward-chart__left-wrapper-content">
                    <div className="flex items-center gap-2 lg:gap-4">
                        <div
                            className="reward-chart-progress__left-wrapper-remainder-count"
                            style={{ backgroundColor: color }}>
                            {remainderCount}
                        </div>
                        <span className="whitespace-pre-line text-body-md-b text-semantic-content-ink">
                            {content.rewardChart.progressTypeDescription}
                        </span>
                    </div>
                    <p className="text-body-sm-r text-semantic-content-ink">
                        {name}
                    </p>
                </div>
            </div>
            <div className="grid justify-end lg:items-center lg:justify-center">
                <Progress.Circle
                    {...progressProps}
                    valueTextColor={theme.colors.semantic.content.disabled}
                    indicatorColor={color}
                    backgroundColor={theme.colors.semantic.background.tertiary}
                    value={processCount - remainderCount}
                    totalValue={processCount}
                    size={RewardChartSizes({ width: windowSize })}
                />
            </div>
        </div>
    )
}

export default RewardChartProgress
