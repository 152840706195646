import * as React from 'react'
import { map } from 'lodash'
import { Button, Icon } from '@components'
import type { EmptySheetProps } from './EmptySheet.types'
import { cn, useTheme } from '@core/utils'

const EmptySheet: React.FC<EmptySheetProps> = ({
    icon,
    title,
    description,
    error,
    actions,
    className,
    ...props
}) => {
    const theme = useTheme().colors

    return (
        <div
            className={cn(
                'animate-enter mx-auto flex max-w-[674px] flex-col items-center justify-center space-y-[56px]',
                className,
            )}
            {...props}>
            <div className="grid h-[160px] w-[160px] place-items-center rounded-full bg-semantic-background-primary">
                <Icon
                    name={icon}
                    size={56}
                    color={theme.semantic.content.inkLight}
                />
            </div>
            <div className="space-y-12">
                <div className="text-center">
                    <h4 className="mb-6 text-display-hg text-semantic-content-ink">
                        {title}
                    </h4>
                    <p className="text-body-lg-r text-semantic-content-inkLight">
                        {description}
                    </p>
                    {error ? (
                        <strong className="mt-12 text-overline-md text-semantic-content-inkLight">
                            {error}
                        </strong>
                    ) : null}
                </div>
                {actions ? (
                    <div className="flex flex-col gap-2">
                        {map(actions, (actionButtonProps, index) => (
                            <Button
                                key={index}
                                className="w-full"
                                {...actionButtonProps}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default EmptySheet
