import { find, includes } from 'lodash'
import { Environment, RTKQuery } from '@core/constants'

export const extractUrlDetails = (url: string) => {
    const urlObject = new URL(url, Environment.BASE_URL)

    const urlWithoutParams = urlObject.origin + urlObject.pathname

    const params = new URLSearchParams(urlObject.search.replace('?', ''))
    const paramsAsObject = Object.fromEntries(params.entries())

    return {
        url: urlWithoutParams,
        params: paramsAsObject,
        path: urlObject.pathname,
    }
}

export const checkIsEndpointIgnored = (
    endpoint: string,
    statusCode: number,
) => {
    const path = extractUrlDetails(endpoint).path

    const ignoredEndpoint = find(RTKQuery.ignoredEndpoints, item => {
        return typeof item.pattern === 'string'
            ? item.pattern === path
            : item.pattern.test(path)
    })

    const isIgnoredEndpointsWithStatusCode =
        ignoredEndpoint?.statusCodes === '*' ||
        includes(ignoredEndpoint?.statusCodes, statusCode)

    return Boolean(isIgnoredEndpointsWithStatusCode)
}

export default {
    extractUrlDetails: extractUrlDetails,
    checkIsEndpointIgnored: checkIsEndpointIgnored,
}
