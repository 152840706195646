import * as React from 'react'
import dayjs from 'dayjs'
import { Calendar, Input, Popover } from '@components'
import type { DateTimeFieldProps } from './DateTimeField.types'

const DateTimeField: React.FC<DateTimeFieldProps> = ({
    date,
    setDate,
    CalendarProps,
    InputProps,
}) => {
    return (
        <React.Fragment>
            <Popover>
                <Popover.Trigger className="w-full">
                    <Input
                        InputContainerAs="div"
                        className="animate-enter w-full hover:cursor-pointer"
                        labelClassName="hover:cursor-pointer"
                        inputClassName="hover:cursor-pointer"
                        readOnly
                        rightIcon="calendar"
                        placeholder="Örn. 07/07/2023"
                        value={date ? dayjs(date).format('DD/MM/YYYY') : ''}
                        {...InputProps}
                    />
                </Popover.Trigger>
                <Popover.Content className="w-auto p-0" align="start">
                    <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        className="rounded-md border border-semantic-background-secondary shadow"
                        {...CalendarProps}
                    />
                </Popover.Content>
            </Popover>
        </React.Fragment>
    )
}
export default DateTimeField
