import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@components'
import type { TopUpPayingProps } from './Paying.types'
import { useFormatCurrency } from './Paying.hooks'

const TopUpPaying: React.FC<TopUpPayingProps> = ({
    name,
    point,
    currency,
    amount,
}) => {
    const { t } = useTranslation()
    const { currencySymbol, formattedAmount, formattedPoint } =
        useFormatCurrency(amount, point, currency)

    return (
        <div className="flex items-center justify-between border-b border-semantic-background-secondary px-6 py-7 lg:px-8">
            <div className="space-y-1">
                <h4 className="text-body-lg-b text-semantic-content-ink">
                    {name}
                </h4>
                <p className="text-body-sm-r text-semantic-content-inkMedium">
                    {t('@deposit.TopUp.balance')}
                </p>
            </div>
            <div className="flex flex-col items-end gap-1">
                <p className="text-display-md-b text-semantic-content-ink">{`${currencySymbol}${formattedAmount}`}</p>
                <Badge
                    as="span"
                    children={`${t('@deposit.EarnablePoint.point_label')}: ${currencySymbol}${formattedPoint}`}
                    variant="yellow"
                    size="tiny"
                    className="w-fit"
                />
            </div>
        </div>
    )
}

export default TopUpPaying
