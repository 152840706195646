import * as React from 'react'
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom'
import { TopUpProvider } from '@core/context'
import { SocketService } from '@core/services'
import { NotificationProvider, useSocketListener } from '@core/hooks'

const Payment: React.FC = () => {
    const token = localStorage.getItem('user.access_token')
    useSocketListener()

    React.useEffect(() => {
        if (!token) return
        SocketService.connect()
    }, [token])

    if (!token) return <Navigate to="/404" replace />

    return (
        <React.Fragment>
            <NotificationProvider>
                <TopUpProvider>
                    <Outlet />
                </TopUpProvider>
            </NotificationProvider>
            <ScrollRestoration />
        </React.Fragment>
    )
}

export default Payment
