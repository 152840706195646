import React from 'react'
import { Icon, RadioButton } from '@components'
import type {
    DepositCardProps,
    DepositCardStaticProperties,
} from './DepositCard.types'
import { cn, useTheme } from '@core/utils'
import DepositCardLoader from './DepositCardLoader'
import DepositCardOther from './DepositCardOther'

const DepositCard: React.FC<DepositCardProps> & DepositCardStaticProperties = ({
    title,
    selected,
    avatarImageSource,
    description,
    LayoutRightComponent,
    layoutRightIconProps,
    containerProps,
    ...props
}) => {
    const { colors } = useTheme()

    const LayoutRightDefaultComponent = React.useMemo(() => {
        return (
            <Icon
                name="caret-right"
                size={24}
                color={colors.semantic.content.inkLight}
                {...layoutRightIconProps}
            />
        )
    }, [colors.semantic.content.inkLight, layoutRightIconProps])

    return (
        <div
            {...containerProps}
            className={cn(
                'animate-enter flex w-full items-center justify-between rounded-[20px] bg-semantic-background-primary px-3',
                containerProps?.className,
            )}>
            <button
                className="flex flex-grow items-center gap-3 py-[15px]"
                {...props}>
                {selected !== undefined ? (
                    <RadioButton value={selected} />
                ) : null}
                <img
                    src={avatarImageSource}
                    alt={title}
                    className="h-12 w-12 shrink-0 rounded-full bg-white object-contain"
                />
                <div className="flex flex-col items-start">
                    <h6 className="text-body-lg-b text-semantic-content-ink">
                        {title}
                    </h6>
                    <p className="text-body-sm-r text-semantic-content-inkMedium">
                        {description}
                    </p>
                </div>
            </button>
            <div className="shrink-0">
                {LayoutRightComponent
                    ? LayoutRightComponent
                    : LayoutRightDefaultComponent}
            </div>
        </div>
    )
}

DepositCard.displayName = 'DepositCard'
DepositCard.Loader = DepositCardLoader
DepositCard.Other = DepositCardOther

export default DepositCard
