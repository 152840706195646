import * as React from 'react'
import { RewardChart, SectionBar } from '@components'

const CampaignRewardListLoader: React.FC = () => {
    return (
        <div className="space-y-8">
            <SectionBar.Loader />
            <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                <RewardChart.Loader />
                <RewardChart.Loader className="hidden lg:block" />
            </div>
        </div>
    )
}
export default CampaignRewardListLoader
