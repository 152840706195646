import * as React from 'react'
import { Skeleton } from '@components'
import type { DepositCardLoaderProps } from './DepositCard.types'
import { cn } from '@core/utils'

const DepositCardLoader: React.FC<DepositCardLoaderProps> = ({
    logoSize = 48,
    description = true,
    className,
    ...props
}) => {
    return (
        <Skeleton
            className={cn(
                'animate-enter flex items-center rounded-[20px] bg-semantic-background-primary px-3 py-[15px]',
                className,
            )}
            {...props}>
            <div className="flex items-center gap-3">
                <Skeleton
                    className="h-12 w-12 shrink-0 rounded-full bg-semantic-background-tertiary"
                    style={{ width: logoSize, height: logoSize }}
                />
                <div>
                    <Skeleton className="h-3 w-24 rounded-full bg-semantic-background-tertiary" />
                    {description ? (
                        <Skeleton className="mt-1 h-2 w-32 rounded-full bg-semantic-background-tertiary" />
                    ) : null}
                </div>
            </div>
        </Skeleton>
    )
}

DepositCardLoader.displayName = 'DepositCard.Loader'

export default DepositCardLoader
