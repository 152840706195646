import {
    type BaseQueryFn,
    type FetchArgs,
    fetchBaseQuery,
    type FetchBaseQueryError,
    type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { SocketService } from '@core/services'
import i18n from '@core/translations'
import type { QueryReturnValue, RefreshTokenResponse } from './BaseApi.types'
import { Environment } from '@core/constants'

import { Mutex } from 'async-mutex'

const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
    baseUrl: Environment.BASE_URL,
    prepareHeaders: async headers => {
        const localization = i18n.getSupportedLocale()
        const waasToken = localStorage.getItem('user.waas_token')
        const accessToken = localStorage.getItem('user.access_token')

        headers.append('X-Localization', localization)
        headers.append('X-Waas-Token', waasToken || '')
        headers.append('Authorization', `Bearer ${accessToken}`)
    },
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()

            const refreshToken = localStorage.getItem('user.refresh_token')

            try {
                const refreshResult = (await baseQuery(
                    {
                        url: 'v2/oauth/refresh_token',
                        method: 'POST',
                        body: { client_id: 3, refresh_token: refreshToken },
                    },
                    api,
                    extraOptions,
                )) as QueryReturnValue<
                    RefreshTokenResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >

                if (refreshResult.data) {
                    localStorage.setItem(
                        'user.refresh_token',
                        refreshResult.data.refresh_token,
                    )
                    localStorage.setItem(
                        'user.access_token',
                        refreshResult.data.access_token,
                    )

                    await SocketService.connect()

                    result = await baseQuery(args, api, extraOptions)
                }

                if (!refreshResult.data) {
                    SocketService.disconnect()
                    localStorage.removeItem('user.refresh_token')
                    localStorage.removeItem('user.access_token')
                    window.location.href = '/session-timeout'
                }
            } finally {
                release()
            }
        } else {
            await mutex.waitForUnlock()

            if (localStorage.getItem('user.access_token')) {
                result = await baseQuery(args, api, extraOptions)
            }
        }
    }

    return result
}

export default baseQueryWithReauth
