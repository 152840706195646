import * as React from 'react'
import { Skeleton } from '@components'

const SectionBarLoader: React.FC = () => {
    return (
        <div className="space-y-2">
            <div className="flex items-center justify-between">
                <Skeleton className="h-8 w-40" />
                <Skeleton className="h-6 w-6 rounded-full lg:w-28" />
            </div>
            <Skeleton className="h-4 w-36" />
        </div>
    )
}
export default SectionBarLoader
