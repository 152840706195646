import * as React from 'react'
import { map, slice } from 'lodash'
import { Header } from '@wallet/components'
import type { WalletDetailHeaderProps } from './WalletDetail.types'

const WalletDetailHeader: React.FC<WalletDetailHeaderProps> = ({
    title,
    color,
    balance,
    point,
    currency,
    walletId,
    modules,
}) => {
    return (
        <Header color={color}>
            <Header.Top title={title} color={color} />
            <div className="flex justify-between">
                <div className="wallet-detail-header__content">
                    <Header.MoneyWrapper
                        balance={balance}
                        point={point}
                        currency={currency}
                    />
                    <div className="grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-1 lg:flex lg:gap-4">
                        {map(slice(modules, 0, 3), (module, index) => (
                            <Header.Modules
                                key={index}
                                name={module.name}
                                params={{
                                    walletId: walletId,
                                    currency: currency,
                                    index: index,
                                }}
                            />
                        ))}
                    </div>
                </div>
                {/*<Header.Onboarding />*/}
            </div>
        </Header>
    )
}

export default WalletDetailHeader
