export default {
    CouponApply: {
        route_header_title: 'Apply Coupon',
        content_header_description:
            'Enter the coupon code below to earn points',
        coupon_add_button: 'Apply Coupon',
        coupon_toast_message:
            'Coupon worth {{amount}} points successfully applied',
        coupon_input_placeholder: 'Enter your coupon code',
    },
    AccountActivitySectionList: {
        empty_sheet_title: 'No Account Activity',
        empty_sheet_description: 'No account activity found',
    },
    WalletDetail: {
        header_title: 'BALANCE',
        header_point: 'Points: {{point}}',
        help: 'Help',
        help_description: 'Facing an issue?',
        read_qr: 'QR Pay',
        menu_earnings_title: 'My Earnings',
        menu_earnings_subtitle:
            'Contribution of Macellan SuperApp to your budget',
        menu_brand_title: 'About the Brand',
        menu_brand_subtitle: 'Menu, campaigns, branches, and more',
        wallet_history_title: 'Wallet Transactions',
        wallet_history_subtitle: 'Last 5 transactions',
        wallet_history_all: 'View All',
        agreement_updated:
            'Terms and conditions of {{wallet}} wallet have been updated',
        passive_status_toast_message:
            'The {{wallet}} wallet has been deactivated',
        wallet_benefits_title: 'What are the benefits of the wallet?',
    },
    WalletModules: {
        route_header_title: 'All Transactions',
        payment: 'Make Payment',
        deposit: 'Load Balance',
        coupon: 'Apply Coupon',
        wallet_share: 'Share Wallet',
        gift_card: 'Gift Card',
        ticket_system: 'My Tickets',
        branches: 'Branches',
        history: 'Account Activities',
        help: 'Help',
        agreement: 'Wallet Agreement',
        wallet_visibility: 'Wallet Visibility',
    },
    AccountActivities: {
        content_header_title: 'Account Activities',
        content_header_description:
            'Access all account activities easily from here',
        search_field_placeholder: 'Filter',
    },
    AccountActivitiesFilter: {
        content_header_title: 'Filter Activities',
        content_header_description:
            'Apply the necessary filters and easily access the results',
        categories: 'CATEGORIES',
        filters: 'FILTERS',
        date: 'DATE RANGE',
        started_date: 'START DATE',
        end_date: 'END DATE',
        submit: 'Show Results',
        balance: 'Balance',
        refund: 'Refund',
        payment_refund: 'Spending Refund',
        deposit_refund: 'Load Refund',
        cash_deposit_refund: 'Cash Load Refund',
        capture: 'Balance Spending',
        top_up: 'Balance Load',
        date_time_place_holder: 'e.g., 07/07/2023',
    },
    AccountActivityDetail: {
        content_header_title: 'Transaction Details',
        content_header_description:
            'Access all transaction details easily from here',
        refund_reason: 'Refund Reason',
        customer_comment: 'Customer Comment',
        company_comment: 'Company Comment',
        reference_number: 'Request No',
        refund_button_title: 'Request Refund',
        branch_name: 'Branch Name',
        cashier: 'Cashier',
        total_earned_points: 'Total Earned Points',
        point_group: 'Point Group',
        earned_points: 'Earned Points',
        earned_wallet: 'Earned Wallet',
        top_up: 'Load',
        capture: 'Capture',
        process_type: 'Process Type',
        spent_amount: 'Spent Amount',
        payment_point: 'Payment Point',
        order_id: 'Order ID',
        date: 'Date',
        discount_amount: 'Discount Amount',
        amount_after_discount: 'Amount After Discount',
        spent_point: 'Spent Points',
        spent_balance: 'Spent Balance',
        wallet_type: 'Wallet Type',
        shared_wallet: 'Shared Wallet',
        sharer: 'Sharer',
        shared: 'Shared',
        expire_date: 'Expiration Date',
        description: 'Description',
        gift_card: 'Gift Card',
        undefined_user: 'Undefined User',
        loaded_amount: 'Loaded Amount',
        bank_amount: 'Bank Amount',
        point_deducted_balance: 'Point Deducted from Balance',
        total_revoked_point: 'Revoked Point',
        reference_code: 'Reference Code',
        loaded_type: 'Load Type',
        cash: 'Cash Load',
        dcb: 'Reflect on Invoice',
        card_number: 'Card Number',
        payment_refund: 'Payment Refund',
        deposit_refund: 'Deposit Refund',
        cash_deposit_refund: 'Cash Deposit Refund',
        refunded_amount: 'Refunded Amount',
        refunded_point: 'Refunded Point',
        transaction_details: 'Transaction Details',
        branch_details: 'Branch Details',
        additional_details: 'Additional Details',
        soft_play_details: 'Soft Play Details',
        bank_transfer: 'Bank Transfer',
        payment_refund_history: 'EXPENSE HISTORY',
        payment_refund_history_spent_amount: 'Spent Amount',
        soft_play_child: 'Child',
        soft_play_duration: 'Duration',
        soft_play_price: 'Price',
        installment_count: 'Number of Installments',
        total_installment_amount: 'Total Installment Amount',
    },
}
