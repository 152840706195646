import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import type { MasterPassCardsNewProps } from './MasterPassCards.types'

const MasterPassCardsNew: React.FC<MasterPassCardsNewProps> = ({
    mpCardsLength,
    setView,
}) => {
    const { t } = useTranslation()

    if (mpCardsLength >= 7) return null

    return (
        <Button
            className="w-full gap-2"
            children={t(
                '@deposit.MasterPassCards.register_card_row_item_title',
            )}
            variant="secondary"
            leftIcon="credit-card"
            onClick={() => setView('cardRegister')}
        />
    )
}
export default MasterPassCardsNew
