import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import { InstallmentCard, Row } from '@components'
import i18n from '@core/translations'
import depositApi from '@deposit/api'
import type { InstallmentSectionProps } from './TopUpAnotherCard.types'

const InstallmentSection: React.FC<InstallmentSectionProps> = ({
    amount,
    walletId,
    currency,
    bin,
    onChangeCount,
}) => {
    const { t } = useTranslation()
    const installmentInfoQuery = depositApi.useInstallmentInfoQuery(
        { wallet_id: walletId, bin: bin, amount: amount },
        { skip: !bin || amount <= 0 },
    )

    const installmentInfo = React.useMemo(() => {
        return installmentInfoQuery.data?.data ?? []
    }, [installmentInfoQuery.data?.data])

    const formattedInstallmentData = React.useMemo(() => {
        return map(installmentInfo, (item, index) => {
            const formattedInstallmentAmount = CurrencyFormatter.format(
                item.installment_amount,
                i18n.getLocale(),
                currency,
            )

            return {
                title:
                    index === 0
                        ? t(
                              '@deposit.TopUp.installment_section_single_payment_title',
                          )
                        : t('@deposit.TopUp.installment_section_other_title', {
                              installmentCount: item.installment_count,
                              formattedInstallmentAmount:
                                  formattedInstallmentAmount,
                          }),
                amount: item.total_amount,
            }
        })
    }, [installmentInfo, currency, t])

    const handleSelectItem = React.useCallback(
        (index: number) => {
            if (!installmentInfo.length) return
            onChangeCount(installmentInfo?.[index].installment_count)
        },
        [installmentInfo, onChangeCount],
    )

    if (!installmentInfo.length) return null

    return (
        <Row className="mt-8 space-y-4">
            <Row.Title title={t('@deposit.TopUp.installment_section_title')} />
            <InstallmentCard
                data={formattedInstallmentData}
                currency={currency}
                buttonText={{
                    more: t(
                        '@deposit.TopUp.installment_section_more_button_title',
                        { count: formattedInstallmentData.length },
                    ),
                    hide: t(
                        '@deposit.TopUp.installment_section_hide_button_title',
                    ),
                }}
                onActionClick={handleSelectItem}
            />
        </Row>
    )
}
export default InstallmentSection
