import { find, forEach, includes } from 'lodash'
import store from '@core/store'
import type { DepositMethods } from '@company/api'
import type { CardType, UserCard } from '@deposit/api'
import depositApi from '@deposit/api'
import { DepositMethodsConstants } from '@deposit/constants'

const dispatch = store.dispatch

const getTitle = (type: CardType) => {
    return DepositMethodsConstants.Titles[type]
}

const getLogo = (type: CardType) => {
    return DepositMethodsConstants.Logos[type]
}

const getSelectedCard = (cardList: UserCard[], type: CardType) => {
    return find(cardList, item => item.type === type)
}

const setLastDepositMethod = (walletId: number, type: CardType) => {
    localStorage.setItem('deposit_methods.' + walletId, type)
}

const getAvailableDepositMethods = (depositMethods: DepositMethods) => {
    const resultAvailableDepositMethods: CardType[] = []

    forEach(
        DepositMethodsConstants.SupportedDepositMethods,
        (method: CardType) => {
            if (!depositMethods?.[method as keyof DepositMethods]) return
            resultAvailableDepositMethods.push(method)
        },
    )

    return resultAvailableDepositMethods
}

const getLastDepositMethod = async (
    walletId: number,
    depositMethods: DepositMethods,
) => {
    const storageMethod = localStorage.getItem(
        'deposit_methods.' + walletId,
    ) as keyof DepositMethods

    const activeDepositMethods = getAvailableDepositMethods(depositMethods)

    if (!activeDepositMethods.length) return null

    const cardListResult = await dispatch(
        depositApi.endpoints.userCardList.initiate(undefined),
    ).unwrap()

    const selectedCard = getSelectedCard(cardListResult.data, storageMethod)

    const isSupportted = includes(activeDepositMethods, storageMethod)

    if (isSupportted && selectedCard) return storageMethod

    for (const method of activeDepositMethods) {
        const selected = await getSelectedCard(
            cardListResult.data,
            method as CardType,
        )

        if (selected) return method
    }

    return activeDepositMethods[0]
}

export default {
    getTitle: getTitle,
    getLogo: getLogo,
    getSelectedCard: getSelectedCard,
    setLastDepositMethod: setLastDepositMethod,
    getAvailableDepositMethods: getAvailableDepositMethods,
    getLastDepositMethod: getLastDepositMethod,
}
