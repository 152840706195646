import Onboarding_1 from '@core/assets/lottie/Onboarding_1.json'
import Onboarding_2 from '@core/assets/lottie/Onboarding_2.json'
import Onboarding_3 from '@core/assets/lottie/Onboarding_3.json'
import i18n from '@core/translations'
import type { OnboardingSwiperItem } from './Onboarding.types'

export const OnboardingSwiperContents: OnboardingSwiperItem[] = [
    {
        source: Onboarding_1,
        duration: 4000,
        title: i18n.t('@guest.Onboarding.swiper_items_1_title'),
        description: i18n.t('@guest.Onboarding.swiper_items_1_description'),
    },
    {
        source: Onboarding_2,
        duration: 5000,
        title: i18n.t('@guest.Onboarding.swiper_items_2_title'),
        description: i18n.t('@guest.Onboarding.swiper_items_2_description'),
    },
    {
        source: Onboarding_3,
        duration: 5000,
        title: i18n.t('@guest.Onboarding.swiper_items_3_title'),
        description: i18n.t('@guest.Onboarding.swiper_items_3_description'),
    },
]
