import * as React from 'react'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cn } from '@core/utils'

const SheetDescription = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Description
        ref={ref}
        className={cn(
            '!text-body-lg-r text-semantic-content-inkLight',
            className,
        )}
        {...props}
    />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

export default SheetDescription
