import * as React from 'react'
import { cn } from '@core/utils'

const Skeleton: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'animate-pulse rounded-md bg-semantic-background-secondary',
                className,
            )}
            {...props}
        />
    )
}

Skeleton.displayName = 'Skeleton'

export default Skeleton
