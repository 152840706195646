import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import type { TabsProps, TabsStaticProperties } from './Tabs.types'
import TabsContent from './TabsContent'
import TabsList from './TabsList'
import TabsTrigger from './TabsTrigger'

const Tabs: React.FC<TabsProps> & TabsStaticProperties = ({
    children,
    ...props
}) => {
    return <TabsPrimitive.Root {...props}>{children}</TabsPrimitive.Root>
}

Tabs.Trigger = TabsTrigger
Tabs.List = TabsList
Tabs.Content = TabsContent

export default Tabs
