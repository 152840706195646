import * as React from 'react'
import type { CampaignDetailContentProps } from './CampaignDetail.types'

const CampaignDetailContent: React.FC<CampaignDetailContentProps> = ({
    title,
    description,
}) => {
    return (
        <div className="space-y-4 py-6">
            <h2 className="text-display-md-b text-semantic-content-ink">
                {title}
            </h2>
            <div
                className="text-body-lg-r text-semantic-content-inkMedium"
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    )
}
export default CampaignDetailContent
