export default {
    MasterPassCardRegister: {
        route_header_title: 'Kart Ekle',
        content_header_title: 'Masterpass ile Kart Ekle',
        content_header_description:
            'Kart bilgilerin Masterpass güvencesi altında tutulmaktadır',
        form_label_card_number: 'Kart Numarası',
        form_label_expiry_date: 'Son Kullanma Tarihi',
        form_label_account_alias_name: 'Karta Bir İsim Ver',
        form_placeholder_expiry_date: 'AA/YY',
        form_placeholder_account_alias_name: 'Örn: Benim Kartım',
        form_agreement_checkbox_title:
            'Masterpass <0 href="https://www.masterpassturkiye.com/TermsAndConditions.aspx">Kullanıcı Sözleşmesi</0>‘ni okudum ve kartımı Masterpass altyapısında saklamak istiyorum',
        mp_user_agreement_url:
            'https://www.masterpassturkiye.com/TermsAndConditions.aspx',
        mp_user_agreement_header_title: 'Masterpass Kullanıcı Sözleşmesi',
        form_submit_button: 'Kartı Ekle',
    },
    MasterPassCards: {
        route_header_title: 'Masterpass Kartlarım',
        content_header_description:
            'İstediğin ödeme yöntemini seçili hale getirebilir, ödeme yöntemlerini düzenleyebilirsin',
        mp_delete_alert_title: 'Kartı Sil',
        mp_delete_alert_message:
            "Masterpass'e kayıtlı kartını\nsilmek istediğine emin misin?",
        mp_delete_alert_approve_button: 'Evet',
        mp_delete_alert_cancel_button: 'Vazgeç',
        register_card_row_item_title: 'Yeni Kart Ekle',
        delete_alert_button: 'Sil',
    },
    MasterPassHelp: {
        route_header_title: 'Masterpass Destek',
        question_card_title: 'Kart sahibi siz misiniz?',
        question_card_description:
            'Eğer kart sahibinin kayıtlı cep numarası size ait değilse SMS size gönderilmez, kart sahibine gönderilir.',

        question_time_title: 'Kartın kullanım süresi doldu mu?',
        question_time_description:
            'Eklenen kartın geçerlilik süresi dolduysa giriş işlemleri sırasında tarafınıza SMS gelmeyebilir.',
        question_number_title: 'Kayıtlı numaranızı değiştirdiniz mi?',
        question_number_description:
            'Size ait olan bir kart, önceden farklı numara ile kaydedilmişse tarafınıza SMS gelmeyebilir.',
        question_do_what_title: 'Ne yapmalıyım?',
        question_do_what_description:
            "Tüm bu durumlar için Masterpass'in resmi sayfasından hesabınızla giriş yaparak kart bilgilerinizi güncelleyebilir, ardından Macellan SuperApp'te eşleştirmeyi yeniden deneyebilirsiniz.",
        action_button_help: 'Masterpass Desteğe Git',
    },
    MasterPassLink: {
        route_header_title: 'Kart Eşleştirme',
        info_sheet_title: 'Masterpass Kart Eşleştirme',
        info_sheet_description:
            'Daha hızlı yükleme yapabilmek için MasterPass hesabınızı bağlayabilirsin',
        info_sheet_question: 'Devam etmek istiyor musun?',
        info_sheet_approve_button: 'Kart Eşleştir',
        info_sheet_cancel_button: 'Vazgeç',
    },
    MasterPass3D: {
        error_message: '3D doğrulama işlemin başarısız oldu',
    },
    MasterPassOTP: {
        route_header_title: 'Masterpass Doğrulama',
        content_header_title: 'Masterpass Doğrulama',
        content_header_description: 'Telefonuna gelen 6 haneli kodu gir',
        resend_otp_toast_message: 'Doğrulama kodun yeniden gönderildi',
        success_type_link: 'Masterpass hesabın başarıyla eşleştirildi',
        success_type_register: 'Kart ekleme işlemin başarıyla gerçekleştirildi',
        resend_otp_button: 'Tekrar Gönder',
        help_button_text: 'Bir Sorun Mu Var ?',
        error_message: 'Ödeme işlemi başarısız oldu',
    },
    TopUp: {
        route_header_title: 'Bakiye Yükleme',
        amount_section_title: 'YÜKLENECEK BAKİYE',
        deposit_chips_other_label: 'Farklı Tutar',
        payment_method_section_title: 'ÖDEME YÖNTEMİ',
        toast_success_message: 'Yükleme işlemin başarıyla gerçekleştirildi',
        footer_balance_title: 'YÜKLENECEK BAKİYE',
        footer_submit_button: 'Bakiye Yükle',
        other_payment_method_section_title: 'DİĞER ÖDEME YÖNTEMLERİ',
        other_deposit_method_deposit_card_title: 'Kart Numarası Girerek Yükle',
        other_payment_method_deposit_card_title: 'Kart Numarası Girerek Öde',
        other_payment_method_cash_deposit_card_title: 'Nakit Yükleme',
        empty_payment_method_title: 'İşlem Bilgisi',
        empty_payment_method_description:
            'Bu markaya çok yakında bakiye yükleme yapılabileceksin',
        empty_payment_method_question:
            'Dilersen kupon ekleyerek harcama yapabilirsin',
        empty_payment_method_coupon_button: 'Kupon Ekle',
        balance: 'Bakiye',
        info_title: 'Bakiye yüklemede kazançlarım nelerdir?',
        info_description:
            'Bakiye yüklediğinizde cüzdandaki kampanyalara göre puan kazanırsınız.',
        installment_section_title: 'TAKSİT SEÇENEKLERİ',
        installment_section_more_button_title:
            'Tüm Taksit Seçenekleri ({{count}})',
        installment_section_hide_button_title: 'Gizle',
        installment_section_single_payment_title: 'Tek Çekim',
        installment_section_other_title:
            '{{installmentCount}} Taksit x {{formattedInstallmentAmount}}',
    },
    EarnablePoint: {
        point_title: 'Kazanacağın hediye puan',
        point_label: 'Puan',
        up_has_toast_message:
            '<0>{{formattedIncreaseAmount}}</0> daha fazla yükle,\n<0>{{formattedHasPoint}} puan</0> kazan!',
        up_has_toast_chip: 'Hemen Ekle',
    },
    MasterPassDepositCard: {
        register_description: 'Masterpass ile Kart Ekle',
        link_description: 'Masterpass ile Kart Eşleştir',
        edit_button: 'Düzenle',
    },
    TopUpDepositCard: {
        change_button: 'Değiştir',
    },
    TopUpAnotherCard: {
        content_header_title: 'Kart Numarası Girerek Yükle',
        content_header_description:
            'Kart bilgilerin Masterpass güvencesi altında tutulmaktadır',
        form_label_credit_card_no: 'Kart Numarası',
        form_label_expire_date: 'Son Kullanma Tarihi',
        form_label_cvc_no: 'Güvenlik Kodu',
        form_placeholder_expire_date: 'AA/YY',
        form_placeholder_cvc_no: 'CVC/CVV',
        form_label_name: 'Karta Bir İsim Ver',
        form_placeholder_name: 'Örn: Benim Kartım',
        form_save_card_title: 'Sonraki yüklemeler için bu kartı kaydet',
        form_submit_button: 'Bakiye Yükle',
        form_agreement_checkbox_title:
            'Masterpass <0 href="https://www.masterpassturkiye.com/TermsAndConditions.aspx">Kullanıcı Sözleşmesi</0>‘ni okudum ve kartımı Masterpass altyapısında saklamak istiyorum',
        mp_user_agreement_url:
            'https://www.masterpassturkiye.com/TermsAndConditions.aspx',
        mp_user_agreement_header_title: 'Masterpass Kullanıcı Sözleşmesi',
        max_number_of_cards_error: 'En fazla 7 tane kart ekleyebilirsiniz.',
    },
    GoldFramer: {
        warning_message: 'Kartınızdan {{amount}} çekilecektir.',
    },
}
