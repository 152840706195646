import React from 'react'
import { Icon } from '@components'
import type { DepositCardOtherProps } from './DepositCard.types'
import { useTheme } from '@core/utils'

const DepositCardOther: React.FC<DepositCardOtherProps> = ({
    title,
    avatarIcon,
    layoutRightIconProps,
    LayoutRightComponent,
    ...props
}) => {
    const { colors } = useTheme()

    const LayoutRightDefaultComponent = React.useMemo(() => {
        return (
            <Icon
                name="caret-right"
                size={24}
                color={colors.semantic.content.inkLight}
                {...layoutRightIconProps}
            />
        )
    }, [colors.semantic.content.inkLight, layoutRightIconProps])

    return (
        <button
            className="animte-enter flex w-full items-center justify-between rounded-[13px] bg-semantic-background-primary p-3"
            {...props}>
            <div className="flex items-center gap-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-semantic-content-inkInverse">
                    {avatarIcon ? (
                        <Icon
                            name={avatarIcon}
                            size={16}
                            color={colors.semantic.content.ink}
                        />
                    ) : null}
                </div>
                <div className="flex flex-col items-start">
                    <h6 className="text-body-md-b text-semantic-content-ink">
                        {title}
                    </h6>
                </div>
            </div>
            <div className="shrink-0">
                {LayoutRightComponent
                    ? LayoutRightComponent
                    : LayoutRightDefaultComponent}
            </div>
        </button>
    )
}

export default DepositCardOther
