import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type { WalletContractHeaderProps } from './WalletContract.types'

const WalletContractHeader: React.FC<WalletContractHeaderProps> = ({
    title,
}) => {
    const { t } = useTranslation()

    return (
        <div className="wallet-contract-content__header">
            <strong>
                {t('@guest.CompanyWalletSubscribe.route_header_title', {
                    company_name: title,
                })}
            </strong>
        </div>
    )
}
export default WalletContractHeader
