import * as React from 'react'
import dayjs from 'dayjs'
import { map, slice } from 'lodash'
import { HistoryItem, Sheet } from '@components'
import walletApi from '@wallet/api'
import type {
    AccountActivityListProps,
    AccountActivityListStaticProperties,
} from '@wallet/components'
import { AccountActivityDetail } from '@wallet/views'
import { usePagination } from '@core/hooks'
import AccountActivityListLoader from './AccountActivityListLoader'
import AccountActivitySectionList from './AccountActivitySectionList'

const AccountActivityList: React.FC<AccountActivityListProps> &
    AccountActivityListStaticProperties = ({
    config,
    limit = 0,
    ListHeaderComponent,
}) => {
    const paginate = usePagination(walletApi.useBalanceHistoryListQuery, {
        ...config,
    })

    if (paginate.query.isLoading) return <AccountActivityListLoader />

    if (!paginate.data || !paginate.meta || !paginate.data.length) return null

    const data = limit > 0 ? slice(paginate.data, 0, limit) : paginate.data

    return (
        <React.Fragment>
            {ListHeaderComponent && ListHeaderComponent}
            <div>
                {map(data, (item, index) => (
                    <Sheet key={index}>
                        <Sheet.Trigger className="w-full" asChild>
                            <div>
                                <HistoryItem
                                    key={index}
                                    type={item.type}
                                    amount={item.amount}
                                    point={item.total_earned_points}
                                    currency={item.company.currency}
                                    datetime={dayjs(item.created_at).toDate()}
                                    contentStyle={
                                        data.length - 1 === Number(index)
                                            ? { borderBottom: 'none' }
                                            : {}
                                    }
                                />
                            </div>
                        </Sheet.Trigger>
                        <Sheet.Content className="overflow-y-scroll">
                            <AccountActivityDetail key={index} id={item.id} />
                        </Sheet.Content>
                    </Sheet>
                ))}
            </div>
        </React.Fragment>
    )
}

AccountActivityList.displayName = 'AccountActivityList'

AccountActivityList.SectionList = AccountActivitySectionList
AccountActivityList.Loader = AccountActivityListLoader
export default AccountActivityList
