/* eslint-disable  */
import { CreditCardFormatter, DateTimeFormatter } from '@macellan/formatter'
import type { TextInputMaskType } from '@components'

export type GetRawValuePattern<T extends TextInputMaskType> = T extends 'date-time' ? string : undefined

export type GetRawValueReturnType<T extends TextInputMaskType> = T extends 'only-numbers' | 'cvc'
  ? number
  : T extends 'date-time'
    ? Date
    : string

export type GetRawValueFunction = <T extends TextInputMaskType>(
  maskType: T,
  value: string,
  pattern?: GetRawValuePattern<T>,
) => GetRawValueReturnType<T>

const getRawValue: GetRawValueFunction = (maskType, value, pattern) => {
  if (maskType === 'cvc' || maskType === 'only-numbers')
    return parseInt(value, 10) as GetRawValueReturnType<'cvc' | 'only-numbers'>

  if (maskType === 'credit-card') return CreditCardFormatter.getRawValue(value) as GetRawValueReturnType<'credit-card'>

  if (maskType === 'date-time')
    return DateTimeFormatter.getRawValue(value, pattern ?? 'DD/MM/YYYY') as GetRawValueReturnType<'date-time'>

  return value as any
}

export default {
  getRawValue: getRawValue,
}
