import type { TimerStaticProperties } from './Timer.types'
import TimerBar from './TimerBar'
import TimerCircle from './TimerCircle'

const Timer: TimerStaticProperties = () => {
    return null
}

Timer.Bar = TimerBar
Timer.Circle = TimerCircle

export default Timer
