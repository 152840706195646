import * as React from 'react'
import campaignApi from '@campaign/api'
import CampaignDetailFooter from '@campaign/views/CampaignDetail/CampaignDetailFooter.tsx'
import type { CampaignDetailProps } from './CampaignDetail.types'
import CampaignDetailContent from './CampaignDetailContent'
import CampaignDetailHeader from './CampaignDetailHeader'
import CampaignDetailToast from './CampaignDetailToast'
import ConditionParticipation from './ConditionParticipation'
import './CampaignDetail.css'

const CampaignDetail: React.FC<CampaignDetailProps> = ({ campaignId }) => {
    const campaignDetailQuery = campaignApi.useCampaignDetailsQuery(
        { company_id: campaignId ?? 0 },
        { refetchOnMountOrArgChange: true },
    )

    const details = React.useMemo(() => {
        return campaignDetailQuery.data?.data.details
    }, [campaignDetailQuery.data?.data.details])

    if (!details) return null

    return (
        <React.Fragment>
            <div className="min-h-[calc(100vh-380px)] px-6 pb-10 md:px-10">
                <CampaignDetailHeader
                    point={details.campaign.point_amount}
                    source={details.background_image}
                    endDate={details.end_date}
                    currency={details.company.currency}
                />
                <CampaignDetailContent
                    title={details.title}
                    description={details.html_description}
                />
                <ConditionParticipation
                    htmlDescription={details.html_participation_terms}
                />
                <CampaignDetailToast
                    campaignType={details.campaign.type}
                    usageCount={details.campaign_usage_count}
                    campaignWon={details.campaign_won}
                    userUsed={details.campaign_user_used}
                    multipleUsage={details.campaign.multiple_usage}
                    processCount={details.campaign_process_count}
                    pointAmount={details.campaign.point_amount}
                    currency={details.company.currency}
                    remainderCount={details.user_process_remainder}
                />
            </div>
            <CampaignDetailFooter walletId={details.wallet?.id} />
        </React.Fragment>
    )
}
export default CampaignDetail
