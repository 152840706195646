import type * as React from 'react'
import type { ModuleItemStaticProperties } from './ModuleItem.types'
import ModuleItemButton from './ModuleItemButton'

const ModuleItem: React.FC & ModuleItemStaticProperties = () => {
    return null
}
ModuleItem.displayName = 'ModuleItem'

ModuleItem.Button = ModuleItemButton

export default ModuleItem
