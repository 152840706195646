import * as React from 'react'
import type { RowTitleProps } from './Row.types'
import { cn } from '@core/utils'

const RowTitle: React.FC<RowTitleProps> = props => {
    const { title, className } = props

    return (
        <h4
            className={cn(
                'animate-enter !text-overline-md text-semantic-content-inkLight',
                className,
            )}>
            {title}
        </h4>
    )
}

export default RowTitle
