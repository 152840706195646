import baseApi from '@core/api'
import type {
    LoginPhoneVerifyData,
    LoginPhoneVerifyResponse,
    LoginSendSmsData,
    LoginSendSmsResponse,
    UserMeResponse,
} from './GuestApi.types'

const guestApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        loginSendSms: builder.mutation<LoginSendSmsResponse, LoginSendSmsData>({
            query: data => ({
                url: 'waas/login/send-sms',
                method: 'POST',
                body: data,
            }),
        }),
        loginPhoneVerify: builder.mutation<
            LoginPhoneVerifyResponse,
            LoginPhoneVerifyData
        >({
            query: data => ({
                url: 'waas/login/phone-verify',
                method: 'POST',
                body: data,
            }),
        }),
        me: builder.query<UserMeResponse, void>({
            query: () => ({
                url: 'v2/user/me',
                method: 'GET',
            }),
            providesTags: ['Me'],
        }),
    }),
    overrideExisting: true,
})

export default guestApi
