import * as React from 'react'
import { debounce } from 'lodash'
import { EarnablePoint } from '@deposit/components'
import type { CustomAmountFieldProps } from './CustomAmountField.types.ts'
import CustomAmountFieldInput from './CustomAmountFieldInput.tsx'

const CustomAmountField: React.FC<CustomAmountFieldProps> = ({
    amount,
    currency,
    maximumDeposit,
    minimumDeposit,
    isVisible,
    onChangeAmount,
}) => {
    const [debouncedAmount, setDebouncedAmount] = React.useState<number>(0)

    const maximumDepositAmount = React.useMemo(() => {
        return maximumDeposit.amount ?? 100000
    }, [maximumDeposit.amount])

    const minimumDepositAmount = React.useMemo(() => {
        return minimumDeposit.amount ?? 1
    }, [minimumDeposit.amount])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeDebouncedValue = React.useCallback(
        debounce(value => {
            setDebouncedAmount(value)
        }, 500),
        [],
    )

    const handleChangeValue = React.useCallback(
        (value: number) => {
            const resultValue = value ?? 0
            handleChangeDebouncedValue(resultValue)
            onChangeAmount(value)
        },
        [handleChangeDebouncedValue, onChangeAmount],
    )

    React.useEffect(() => {
        if (isVisible) return
        handleChangeDebouncedValue(amount)
    }, [amount, handleChangeDebouncedValue, isVisible])

    return (
        <React.Fragment>
            <CustomAmountFieldInput
                amount={amount}
                currency={currency}
                maximumDepositAmount={maximumDepositAmount}
                isVisible={isVisible}
                onChangeValue={handleChangeValue}
            />
            <EarnablePoint
                amount={debouncedAmount}
                currency={currency}
                minimumDepositAmount={minimumDepositAmount}
                maximumDepositAmount={maximumDepositAmount}
                onChangeAmount={handleChangeValue}
            />
        </React.Fragment>
    )
}

export default CustomAmountField
