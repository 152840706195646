import * as React from 'react'
import { TopUpHeader } from '@deposit/components'
import type {
    TopUpContainerProps,
    TopUpContainerStaticProperties,
} from './Container.types'
import TopUpContainerLeft from './ContainerLeft'
import TopUpContainerRight from './ContainerRight'

const TopUpContainer: React.FC<TopUpContainerProps> &
    TopUpContainerStaticProperties = ({ goBackProps, children }) => {
    return (
        <div className="min-h-inherit lg:bg-semantic-background-primary">
            <TopUpHeader buttonProps={goBackProps} />
            <div className="pb-10 lg:container lg:-mt-[109px]">
                <div className="flex bg-semantic-content-inkInverse lg:rounded-3xl">
                    {children}
                </div>
            </div>
        </div>
    )
}

TopUpContainer.Left = TopUpContainerLeft
TopUpContainer.Right = TopUpContainerRight

export default TopUpContainer
