import type { RouteObject } from 'react-router-dom'
import { Guest } from '@core/routes/Layout'
import { Initialize, Onboarding, OtpVerify, WalletContract } from '@guest/views'

export const GuestRouter: RouteObject[] = [
    {
        path: '/init',
        element: <Initialize />,
    },
    {
        element: <Guest />,
        children: [
            {
                path: '/onboarding',
                element: <Onboarding />,
            },
            {
                path: '/otp-verify',
                element: <OtpVerify />,
            },
            {
                path: '/wallet-contract',
                element: <WalletContract />,
            },
        ],
    },
]
