import * as React from 'react'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import { IconButton } from '@components'
import type { SheetContentProps } from './Sheet.types'
import { cn } from '@core/utils'
import { SheetPortal } from './Sheet'
import { sheetVariants } from './Sheet.styles'
import SheetOverlay from './SheetOverlay'

const SheetContent = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Content>,
    SheetContentProps
>(({ side = 'right', loading, className, children, ...props }, ref) => (
    <SheetPortal>
        <SheetOverlay />
        <SheetPrimitive.Content
            ref={ref}
            className={cn('sheet-content', sheetVariants({ side }), className)}
            {...props}>
            {loading && (
                <div className="absolute left-0 top-0 h-1.5 w-full overflow-hidden bg-semantic-background-quaternary">
                    <div className="animate-progress left-right h-full w-full bg-semantic-content-brand"></div>
                </div>
            )}
            {children}
            <SheetPrimitive.Close className="absolute right-10 top-12 data-[state=open]:!bg-semantic-content-inkInverse">
                <IconButton
                    size="small"
                    name="close"
                    variant="ink-tonal"
                    as="span"
                />
                <span className="sr-only">Close</span>
            </SheetPrimitive.Close>
        </SheetPrimitive.Content>
    </SheetPortal>
))
SheetContent.displayName = SheetPrimitive.Content.displayName

export default SheetContent
