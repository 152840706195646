import * as React from 'react'
import { CampaignRewardList, CampaignSegmentedList } from '@campaign/components'
import type { CampaignSectionProps } from './WalletDetail.types'

const CampaignSection: React.FC<CampaignSectionProps> = ({
    walletId,
    companyId,
    color,
    currency,
}) => {
    return (
        <div className="container my-10 space-y-10">
            <CampaignRewardList
                walletId={walletId}
                companyId={companyId}
                color={color}
                currency={currency}
            />
            <CampaignSegmentedList companyId={companyId} />
        </div>
    )
}

export default CampaignSection
