import { map } from 'lodash'
import baseApi from '@core/api'
import type {
    BalanceHistoryDetailsData,
    BalanceHistoryDetailsResponse,
    BalanceHistoryListData,
    BalanceHistoryListResponse,
    PointCouponApplyData,
    PointCouponApplyResponse,
    WalletDetailsData,
    WalletDetailsResponse,
    WalletLastEarnedDepositGiftPointsData,
    WalletLastEarnedDepositGiftPointsResponse,
    WalletPointWonCampaignData,
    WalletPointWonCampaignResponse,
} from './WalletApi.types'
import WalletApiUtils from './WalletApi.utils'

const walletApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        walletDetails: builder.query<WalletDetailsResponse, WalletDetailsData>({
            query: data => ({
                url: 'v2/wallet/details/' + data.wallet_id,
                method: 'GET',
            }),
            providesTags: ['WalletDetail'],
            transformResponse: WalletApiUtils.transformWalletDetailsResponse,
        }),
        balanceHistoryList: builder.query<
            BalanceHistoryListResponse,
            BalanceHistoryListData
        >({
            query: data => {
                const filters = data.filters ? Object.values(data.filters) : []

                const balanceFilters = map(
                    filters,
                    (item, index) => 'balance_filters[' + index + ']=' + item,
                ).join('&')

                return {
                    url: `v2/wallet/balance-history/list?${balanceFilters}`,
                    method: 'GET',
                    params: data,
                }
            },

            serializeQueryArgs: ({
                endpointName,
                // eslint-disable-next-line no-unused-vars
                queryArgs: { page, ...queryArgs },
            }) => {
                return { endpointName, ...queryArgs }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.paginate = newData.meta.paginate
            },
            providesTags: ['BalanceHistoryList'],
            keepUnusedDataFor: 1,
        }),
        balanceHistoryDetails: builder.query<
            BalanceHistoryDetailsResponse,
            BalanceHistoryDetailsData
        >({
            query: data => ({
                url: `v2/wallet/balance-history/details/${data.id}`,
                method: 'GET',
            }),
            providesTags: ['BalanceHistoryDetail'],
        }),
        walletLastEarnedDepositGiftPoints: builder.query<
            WalletLastEarnedDepositGiftPointsResponse,
            WalletLastEarnedDepositGiftPointsData
        >({
            query: data => ({
                url: `v2/wallet/${data.wallet_id}/last-earned-deposit-gift-points`,
                method: 'GET',
            }),
            providesTags: ['WalletDetail'],
        }),
        walletPointWonCampaign: builder.query<
            WalletPointWonCampaignResponse,
            WalletPointWonCampaignData
        >({
            query: data => ({
                url: 'v2/wallet/point/won/campaign',
                method: 'GET',
                params: data,
            }),
        }),
        pointCouponApply: builder.mutation<
            PointCouponApplyResponse,
            PointCouponApplyData
        >({
            query: data => ({
                url: 'v2/wallet/point/coupon-apply',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_p, error) => (error ? [] : ['WalletDetail']),
        }),
    }),
    overrideExisting: true,
})

export default walletApi
