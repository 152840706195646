import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge, Toast } from '@components'
import { useAppDispatch, useAppSelector } from '@core/store'
import i18n from '@core/translations'
import type { UpHas } from '@deposit/api'
import depositApi from '@deposit/api'
import { setUpHasCompleted } from '@deposit/slice'
import type { EarnablePointProps } from './EarnablePoint.types'

const EarnablePoint: React.FC<EarnablePointProps> = ({
    amount,
    currency,
    minimumDepositAmount,
    maximumDepositAmount,
    onChangeAmount,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { upHasCompleted } = useAppSelector(state => state.depositSlice)

    const [earnablePoint, setEarnablePoint] = React.useState<number>(0)
    const [upHas, setUpHas] = React.useState<UpHas | undefined>()

    const [pointEarnable] = depositApi.usePointDepositEarnableMutation()

    const formattedPoint = React.useMemo(() => {
        return CurrencyFormatter.format(
            earnablePoint,
            i18n.getLocale(),
            currency,
        )
    }, [currency, earnablePoint])

    const formattedToastMessage = React.useMemo(() => {
        if (!upHas) return

        const formattedIncreaseAmount = CurrencyFormatter.format(
            upHas.increase_amount,
            i18n.getLocale(),
            currency,
        )

        const formattedHasPoint = CurrencyFormatter.format(
            upHas.point,
            i18n.getLocale(),
            currency,
        )

        return (
            <Trans
                i18nKey="@deposit.EarnablePoint.up_has_toast_message"
                values={{
                    formattedIncreaseAmount: formattedIncreaseAmount,
                    formattedHasPoint: formattedHasPoint,
                }}
                components={[<strong />]}
            />
        ) as unknown as string
    }, [currency, upHas])

    const handlePointEarnable = React.useCallback(async () => {
        let earnablePointResult = 0
        let upHasResult

        if (amount >= minimumDepositAmount && amount <= maximumDepositAmount) {
            const result = await pointEarnable({
                wallet_id: Number(localStorage.getItem('user.wallet_id')),
                amount: amount,
            }).unwrap()

            earnablePointResult = result.data.point
            upHasResult = result.data.up_has
        }

        setEarnablePoint(earnablePointResult)
        setUpHas(upHasResult)
    }, [amount, maximumDepositAmount, minimumDepositAmount, pointEarnable])

    const handleChangeValue = React.useCallback(() => {
        if (!upHas?.amount) return
        dispatch(setUpHasCompleted(true))
        onChangeAmount?.(upHas.amount)
    }, [dispatch, onChangeAmount, upHas?.amount])

    React.useEffect(() => {
        handlePointEarnable()
    }, [handlePointEarnable])

    if (!earnablePoint) return null

    return (
        <div className="mt-6 space-y-6">
            <div className="flex items-center gap-2 duration-300 animate-in fade-in zoom-in-95">
                <p className="text-body-sm-r text-semantic-content-inkMedium">
                    {t('@deposit.EarnablePoint.point_title')}
                </p>
                <Badge
                    as="span"
                    children={`${formattedPoint} ${t('@deposit.EarnablePoint.point_label')}`}
                    variant="yellow"
                    size="tiny"
                />
            </div>
            {formattedToastMessage && upHas && !upHasCompleted ? (
                <Toast
                    as="div"
                    variant="suggestion"
                    message={formattedToastMessage}
                    LayoutRightComponent={type => (
                        <Toast.Action
                            variant={type}
                            onClick={handleChangeValue}>
                            {t('@deposit.EarnablePoint.up_has_toast_chip')}
                        </Toast.Action>
                    )}
                />
            ) : null}
        </div>
    )
}

export default EarnablePoint
