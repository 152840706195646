import * as React from 'react'

interface WindowSize {
    width: number
    height: number
}

function useWindowSize(): WindowSize {
    const [size, setSize] = React.useState<WindowSize>({ width: 0, height: 0 })

    React.useEffect(() => {
        const updateSize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight })
        }

        window.addEventListener('resize', updateSize)

        updateSize()

        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return size
}

export default useWindowSize
