import * as React from 'react'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge } from '@components'
import i18n from '@core/translations'
import type { HistoryItemContentProps } from './HistoryItem.types'

const HistoryItemContent: React.FC<HistoryItemContentProps> = ({
    item,
    subtitle,
    amount,
    point,
    currency,
    datetime,
}) => {
    const locale = i18n.getLocale()

    const formattedDateText = React.useMemo(() => {
        return dayjs(datetime).format('DD MMM • HH:mm')
    }, [datetime])

    const formattedAmount = React.useMemo(() => {
        if (typeof amount !== 'number') return
        return CurrencyFormatter.format(amount, locale, currency)
    }, [amount, locale, currency])

    const formattedPoint = React.useMemo(() => {
        if (!point) return
        return CurrencyFormatter.format(point, locale, currency)
    }, [point, locale, currency])

    if (!item) return null

    return (
        <React.Fragment>
            <div className="history-item__content__title">
                <h4>{item.title}</h4>
                {subtitle && (
                    <span className="text-body-sm-r text-semantic-content-inkLight">
                        {subtitle}
                    </span>
                )}
            </div>
            <div className="history-item__content__amount">
                <div className="history-item__content__amount__wrapper">
                    {item.pointBadgeType && formattedPoint && (
                        <Badge
                            children={formattedPoint}
                            variant={item.pointBadgeType}
                            size="tiny"
                            as="span"
                        />
                    )}
                    {item.amountColor && formattedAmount && (
                        <span
                            className="history-item__content__amount__text"
                            style={{ color: item.amountColor }}>
                            {formattedAmount}
                        </span>
                    )}
                </div>
                <span className="history-item__content__datetime">
                    {formattedDateText}
                </span>
            </div>
        </React.Fragment>
    )
}

export default HistoryItemContent
