import * as React from 'react'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import type { SheetProps, SheetStaticProperties } from './Sheet.types'
import SheetContent from './SheetContent'
import SheetDescription from './SheetDescription'
import SheetFooter from './SheetFooter'
import SheetHeader from './SheetHeader'
import SheetOverlay from './SheetOverlay'
import SheetTitle from './SheetTitle'

const Sheet: React.FC<SheetProps> & SheetStaticProperties = ({
    children,
    ...props
}) => {
    return <SheetPrimitive.Root {...props}>{children}</SheetPrimitive.Root>
}

export const SheetTrigger = SheetPrimitive.Trigger

export const SheetClose = SheetPrimitive.Close

export const SheetPortal = SheetPrimitive.Portal

Sheet.Header = SheetHeader
Sheet.Footer = SheetFooter
Sheet.Content = SheetContent
Sheet.Title = SheetTitle
Sheet.Description = SheetDescription
Sheet.Trigger = SheetTrigger
Sheet.Close = SheetClose
Sheet.Portal = SheetPortal
Sheet.Overlay = SheetOverlay

export default Sheet
