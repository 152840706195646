import * as React from 'react'
import { moduleItemButtonStyles } from '~/src/components/ModuleItem/ModuleItemButton.styles.ts'
import { Icon } from '@components'
import type { ModuleItemButtonProps } from './ModuleItem.types.ts'
import { cn } from '@core/utils'

const ModuleItemButton: React.FC<ModuleItemButtonProps> = ({
    as = 'button',
    icon,
    IconProps,
    variant,
    className,
    children,
    ...props
}) => {
    const Comp = as || 'button'

    return (
        <Comp
            className={cn(moduleItemButtonStyles({ variant }), className)}
            {...props}>
            <Icon name={icon} size={24} {...IconProps} />
            {children}
        </Comp>
    )
}
ModuleItemButton.displayName = 'ModuleItemButton'

export default ModuleItemButton
