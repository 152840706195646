import * as React from 'react'
import type { RadioButtonProps } from './RadioButton.types'
import './RadioButton.css'

const RadioButton: React.FC<RadioButtonProps> = ({ value, size, ...props }) => {
    return (
        <div className="radio-button__container animate-enter">
            <input
                readOnly
                type="radio"
                checked={value}
                className="radio-button__input"
                {...props}
            />
            <label
                htmlFor="radioButton"
                className="radio-button__label"
                style={{ width: size, height: size }}></label>
        </div>
    )
}
export default RadioButton
