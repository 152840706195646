import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { filter } from 'lodash'
import { Button, Sheet } from '@components'
import { AccountActivitiesSectionHeader } from '@wallet/views'
import type {
    AccountActivitiesFilterProps,
    CategoryItem,
    FilterDateForm,
    FilterDateItem,
    FilterItem,
} from './AccountActivitiesFilter.types'
import { Categories } from './AccountActivitiesFilter.constants'
import { filterDateValidationSchema } from './AccountActivitiesFilter.validations'
import CategorySection from './CategorySection'
import DateSection from './DateSection'
import FilterSection from './FilterSection'

const AccountActivitiesFilter: React.ForwardRefRenderFunction<
    HTMLButtonElement,
    AccountActivitiesFilterProps
> = (
    { activeFilters, setActiveFilters, startedDate, endDate },
    forwardedRef,
) => {
    const ref = React.useRef<HTMLButtonElement>(null)
    const { t } = useTranslation()

    const [category, setCategory] = React.useState<CategoryItem>()
    const [filters, setFilters] = React.useState<FilterItem[]>([])

    React.useEffect(() => {
        const activeFilter = filter(
            activeFilters,
            filterItem =>
                filterItem.type !== 'started_date' &&
                filterItem.type !== 'end_date',
        ) as FilterItem[]

        if (activeFilters.length > 0) {
            const activeCategory = filter(
                Categories,
                categoryItem => categoryItem.type === activeFilter[0]?.category,
            )[0]
            setFilters(activeFilter)
            return setCategory(activeCategory)
        }

        setFilters([])
        setCategory(undefined)
    }, [activeFilters])

    const filteredList = React.useCallback(
        // eslint-disable-next-line
        (item: any) =>
            filter(
                filters,
                filteredItem => filteredItem.type !== item.type,
            ) as [],
        [filters],
    )

    const selectedCategory = React.useMemo(
        () => (category ? category.filters : []),
        [category],
    )

    const handleOnSubmit = React.useCallback(
        (values: FilterDateForm) => {
            const activeFilters = [...filters] as (
                | FilterItem
                | FilterDateItem
            )[]

            if (values.startedDate) {
                activeFilters.push({
                    type: 'started_date',
                    date: dayjs(values.startedDate).format('DD.MM.YYYY'),
                })
            }

            if (values.endDate) {
                activeFilters.push({
                    type: 'end_date',
                    date: dayjs(values.endDate).format('DD.MM.YYYY'),
                })
            }

            setActiveFilters(activeFilters)

            ref.current?.click()
        },
        [filters, setActiveFilters],
    )

    React.useImperativeHandle(
        forwardedRef,
        () => ref.current as HTMLButtonElement,
    )

    const formik = useFormik<FilterDateForm>({
        initialValues: {
            startedDate: startedDate
                ? dayjs(startedDate, 'DD.MM.YYYY').toDate()
                : undefined,
            endDate: endDate
                ? dayjs(endDate, 'DD.MM.YYYY').toDate()
                : undefined,
        },
        onSubmit: handleOnSubmit,
        validationSchema: filterDateValidationSchema,
        enableReinitialize: true,
    })

    return (
        <Sheet>
            <Sheet.Trigger>
                <Button
                    ref={ref}
                    asChild
                    variant="ink-inverse"
                    leftIcon="filter"
                    className="border-semantic-background-secondary">
                    <span>
                        {t(
                            '@wallet.AccountActivities.search_field_placeholder',
                        )}
                    </span>
                </Button>
            </Sheet.Trigger>
            <Sheet.Content className="overflow-y-scroll">
                <Sheet.Header className="mb-4 lg:mb-8">
                    <Sheet.Title>
                        {t(
                            '@wallet.AccountActivitiesFilter.content_header_title',
                        )}
                    </Sheet.Title>
                    <Sheet.Description>
                        {t(
                            '@wallet.AccountActivitiesFilter.content_header_description',
                        )}
                    </Sheet.Description>
                </Sheet.Header>
                <div className="space-y-4 duration-300 animate-in fade-in lg:pt-4">
                    <CategorySection
                        category={category}
                        handleOnClick={item => {
                            setCategory(item)
                            setFilters([])
                        }}
                    />
                    <FilterSection
                        filters={selectedCategory}
                        selectedFilters={filters}
                        handleOnClick={filterItem => {
                            const filtered = filteredList(filterItem)
                            if (filtered.length !== filters.length) {
                                setFilters(filtered)
                            } else {
                                setFilters([...filtered, filterItem])
                            }
                        }}
                    />
                    <div className="space-y-2">
                        <AccountActivitiesSectionHeader
                            title={t('@wallet.AccountActivitiesFilter.date')}
                        />
                        <div className="space-y-6">
                            <DateSection
                                date={formik.values.startedDate}
                                onChangeValue={date =>
                                    formik.setFieldValue('startedDate', date)
                                }
                                label={t(
                                    '@wallet.AccountActivitiesFilter.started_date',
                                )}
                                message={
                                    formik.errors.startedDate
                                        ? formik.errors.startedDate
                                        : undefined
                                }
                            />
                            <DateSection
                                date={formik.values.endDate}
                                onChangeValue={date =>
                                    formik.setFieldValue('endDate', date)
                                }
                                label={t(
                                    '@wallet.AccountActivitiesFilter.end_date',
                                )}
                                message={
                                    formik.errors.endDate
                                        ? formik.errors.endDate
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                    <Button
                        type="button"
                        className="!mt-8 w-full"
                        children={t('@wallet.AccountActivitiesFilter.submit')}
                        disabled={!formik.isValid}
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </Sheet.Content>
        </Sheet>
    )
}

export default React.forwardRef(AccountActivitiesFilter)
