import { CreditCardFormatter } from '@macellan/formatter'
import type {
    GetCreditCardMaskFunction,
    GetCVCMaskFunction,
    GetDateTimeMaskFunction,
    GetOnlyNumbersMaskFunction,
} from './Input.types'

export const getOnlyNumbersMask: GetOnlyNumbersMaskFunction = maskLength => {
    return `[${'0'.repeat(maskLength)}]`
}

export const getDateTimeMask: GetDateTimeMaskFunction = (pattern: string) => {
    const chars = ['D', 'M', 'Y', 'h', 'm', 's']
    chars.forEach(item => (pattern = pattern?.replaceAll(item, '9')))
    return pattern.replace(/(9+)/g, '$1')
}

export const getCreditCardMask: GetCreditCardMaskFunction = (value: string) => {
    const issuer = CreditCardFormatter.getIssuer(value)

    if (issuer?.name === 'amex') return '[0000] [000000] [00000]'

    return '9999 9999 9999 9999'
}

export const getCVCMask: GetCVCMaskFunction = (referenceValue: string) => {
    const cvcLength =
        CreditCardFormatter.getIssuer(referenceValue)?.cvcLength ?? 3
    return getOnlyNumbersMask(cvcLength)
}
