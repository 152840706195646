import * as React from 'react'
import { InfoSheet } from '@components'
import Logo from '@core/assets/logo_dark.svg'
import type { NotFoundProps } from './NotFound.types'

const NotFound: React.FC<NotFoundProps> = ({
    title,
    description,
    errorMessages,
    actions,
}) => (
    <div className="flex h-screen flex-col px-8 py-9">
        <img src={Logo} alt="404" className="h-fit w-fit" />
        <InfoSheet
            icon="info"
            title={title}
            description={description}
            errorMessages={errorMessages}
            actions={actions}
            className="flex-grow"
        />
    </div>
)

export default NotFound
