import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import type { SidebarProps } from './Sidebar.types'
import { SendPostMessage } from '@core/utils'
import SidebarItem from './SidebarItem'
import SidebarProfile from './SidebarProfile'
import './Sidebar.css'

const Sidebar: React.FC<SidebarProps> = ({
    fullName,
    avatarUrl,
    logo,
    color,
    isMobile,
}) => {
    const { t } = useTranslation()

    return (
        <aside className="sidebar">
            <div className="w-full flex-1">
                <div className="sidebar-logo animate-enter">
                    <img
                        src={logo}
                        alt="Logo"
                        className="max-w-12 object-contain"
                    />
                </div>
                <div className="sidebar-profile">
                    <SidebarProfile
                        fullName={fullName}
                        avatarUrl={avatarUrl}
                        color={color}
                    />
                </div>
                <div className="sidebar-menu-detail">
                    <h2 className="sidebar-menu-detail__title">
                        {t('content_config.Sidebar.my_transactions')}
                    </h2>
                    <div className="sidebar-menu-detail__items">
                        <SidebarItem isMobile={isMobile} />
                    </div>
                </div>
            </div>
            {!isMobile && (
                <div className="sidebar-footer">
                    <Button
                        variant="primary-tonal"
                        className="w-full"
                        children={t('content_config.Sidebar.return_to_site')}
                        onClick={() =>
                            SendPostMessage(
                                JSON.stringify({
                                    event: 'ReturnToSite',
                                }),
                            )
                        }
                    />
                </div>
            )}
        </aside>
    )
}

export default Sidebar
