export default {
    CampaignRewardList: {
        campaign_section_title: 'Campaigns',
        campaign_section_description:
            'Follow opportunities and contribute to your budget',
        campaigns_chips_label_all: 'All Campaigns',
    },
    CampaignSegmentedList: {
        segmented_section_topup_title: 'Load and Earn',
        segmented_section_capture_title: 'Spend and Earn',
    },
    Campaigns: {
        route_header_title: 'Campaigns',
        route_header_description:
            "Follow campaigns, don't miss out on opportunities!",
        campaigns_chips_label_all: 'All',
        campaigns_chips_label_deposit_gift: 'Load and Earn',
        campaigns_chips_label_payment_count_gift: 'Spend and Earn',
        section_bar_title: 'Personalized for You',
        empty_sheet_title: 'No Campaign Found',
        empty_sheet_description: 'No campaign found based on your criteria',
    },
    CampaignDetail: {
        route_header_title: 'Campaign Details',
        content_header_left_title: 'END DATE',
        content_header_right_title: 'GIFT POINTS',
        footer_deposit_button: 'Load Balance',
        footer_add_wallet_button: 'Add Wallet',
        footer_deposit_description:
            'Load your balance to take advantage of the offer!',
        footer_add_wallet_description:
            'Add your wallet to take advantage of the offer!',
        campaign_detail_share: 'Share:',
        condition_participation_title: 'Participation Conditions',
        condition_participation_button_more_title: 'Read More',
        condition_participation_button_hide_title: 'Hide',
        other_campaigns_title: 'You Might Also Like',
        other_campaigns_description: 'Other campaigns related to the brand',
        payment_count_gift_toast_message:
            '{{formattedEarnablePoint}} gift points earned, {{remainderCount}} spending left',
        deposit_gift_toast_message:
            'You have benefited from this campaign {{usageCount}} times',
        deposit_gift_disposable_toast_message:
            'You have already benefited from this campaign',
        toast_message_chip_title: 'Earned: {{formattedWonPoint}}',
    },
}
