import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReadMore } from '@components'
import type { ConditionParticipationProps } from './CampaignDetail.types'

const ConditionParticipation: React.FC<ConditionParticipationProps> = ({
    htmlDescription = '',
}) => {
    const { t } = useTranslation()
    return (
        <div className="space-y-3 py-6">
            <h4 className="text-display-sm text-semantic-content-ink">
                {t('@campaign.CampaignDetail.condition_participation_title')}
            </h4>
            <ReadMore
                content={htmlDescription}
                buttonText={{
                    more: t(
                        '@campaign.CampaignDetail.condition_participation_button_more_title',
                    ),
                    hide: t(
                        '@campaign.CampaignDetail.condition_participation_button_hide_title',
                    ),
                }}
            />
        </div>
    )
}
export default ConditionParticipation
