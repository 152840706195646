import * as React from 'react'
import type { RefundSectionItemInfoProps } from './AccountActivityDetail.types'

const RefundSectionItemInfo: React.FC<RefundSectionItemInfoProps> = ({
    title,
    comment,
}) => {
    return (
        <div className="flex items-center gap-1">
            <h5 className="text-body-sm-b text-semantic-content-ink">
                {title}
            </h5>
            <p className="text-body-sm-r text-semantic-content-ink">
                {comment}
            </p>
        </div>
    )
}
export default RefundSectionItemInfo
