import { enGB, type Locale, tr } from 'date-fns/locale'

const CalendarLocale: Record<string, Locale> = {
    en: enGB,
    tr: tr,
}

export const getCalendarLocale = (locale: string) => {
    return CalendarLocale[locale] || enGB
}
