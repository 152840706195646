import * as React from 'react'
import type { StepperStepProps } from './Stepper.types'
import { cn } from '@core/utils'

const StepperStep: React.FC<StepperStepProps> = ({
    isActive,
    children,
    className,
    ...props
}) => {
    if (!isActive) return null

    return (
        <div className={cn('animate-enter', className)} {...props}>
            {children}
        </div>
    )
}

StepperStep.displayName = 'StepperStep'
export default StepperStep
