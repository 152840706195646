import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge, Icon, Spinner } from '@components'
import i18n from '@core/translations'
import type { HistoryRefundCardProps } from './HistoryRefundCard.types'
import { cn, useTheme } from '@core/utils'

const HistoryRefundCard: React.FC<HistoryRefundCardProps> = ({
    title,
    amount,
    currency,
    avatarIcon,
    className,
    loading,
    ...props
}) => {
    const theme = useTheme().colors

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
    }, [amount, currency])

    return (
        <button
            className={cn(
                'animate-enter flex h-14 w-full items-center justify-between rounded-[13px] bg-semantic-background-primary px-3 hover:bg-semantic-background-secondary',
                className,
            )}
            {...props}>
            <div className="flex items-center gap-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-semantic-background-inkInverse">
                    <Icon
                        name={avatarIcon}
                        size={16}
                        color={theme.semantic.content.ink}
                    />
                </div>
                <strong className="text-body-md-b text-semantic-content-ink">
                    {title}
                </strong>
            </div>
            <div className="flex items-center gap-2">
                <Badge as="div" children={formattedAmount} size="small" />
                {!loading && (
                    <Icon
                        name="caret-right"
                        size={24}
                        color={theme.semantic.content.inkLight}
                    />
                )}
                {loading && <Spinner size={20} />}
            </div>
        </button>
    )
}
export default HistoryRefundCard
