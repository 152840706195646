/* eslint-disable  */
import * as React from 'react'
import type { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import type { SubscriptionOptions as RTKSubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState'
import type { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { isEqual } from 'lodash'
import { usePrevious } from '@core/utils'

export type RequestWitoutPage<T> = Omit<T, 'page'>

export type SubscriptionOptions = RTKSubscriptionOptions & {
    skip?: boolean
    refetchOnMountOrArgChange?: boolean
}

export interface BaseResponse {
    meta: any
    data: any
}

const usePagination = <Response extends BaseResponse, Request>(
    useQuery: UseQuery<QueryDefinition<Request, any, any, Response>>,
    params: RequestWitoutPage<Request>,
    subscriptionOptions?: SubscriptionOptions,
) => {
    const [args, setArgs] = React.useState({
        ...(params as Request),
        page: 1,
    })

    const prevParams = usePrevious(params)

    const paginateQuery = useQuery(args, {
        refetchOnMountOrArgChange: true,
        ...subscriptionOptions,
    })

    const meta = React.useMemo(() => {
        return paginateQuery.data?.meta
    }, [paginateQuery.data?.meta]) as Response['meta']

    const data = React.useMemo(() => {
        return paginateQuery.data?.data
    }, [paginateQuery.data]) as Response['data']

    const more = React.useCallback(() => {
        if (!meta?.paginate?.has_more || paginateQuery.isFetching) return

        setArgs(currentArgs => ({ ...currentArgs, page: currentArgs.page + 1 }))
    }, [meta?.paginate, paginateQuery.isFetching])

    React.useEffect(() => {
        if (isEqual(params, prevParams)) {
            return
        }

        setArgs({ page: 1, ...(params as Request) })
    }, [params, prevParams])

    return {
        meta: meta,
        data: data,
        more: more,
        query: paginateQuery,
    }
}

export default usePagination
