import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@components'
import type { SidebarProfileProps } from './Sidebar.types'
import ProfilePattern from './ProfilePattern'

const SidebarProfile: React.FC<SidebarProfileProps> = ({
    fullName,
    avatarUrl,
    color,
}) => {
    const { t } = useTranslation()

    return (
        <button className="sidebar-profile__content">
            <div className="sidebar-profile__image">
                <ProfilePattern color={color} />
                <Avatar className="avatar">
                    <Avatar.Image src={avatarUrl} />
                    <Avatar.Fallback>{fullName[0]}</Avatar.Fallback>
                </Avatar>
            </div>
            <div className="sidebar-profile__info">
                <div>
                    <p>{t('content_config.Sidebar.hello')}</p>
                    <h3>{fullName}</h3>
                </div>
                {/*<Icon name="caret-right" size={24} />*/}
            </div>
        </button>
    )
}

export default SidebarProfile
