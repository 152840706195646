export default {
    MasterPassCardRegister: {
        route_header_title: 'Add Card',
        content_header_title: 'Add Card with Masterpass',
        content_header_description:
            'Your card information is securely stored with Masterpass',
        form_label_card_number: 'Card Number',
        form_label_expiry_date: 'Expiry Date',
        form_label_account_alias_name: 'Give a Name to the Card',
        form_placeholder_expiry_date: 'MM/YY',
        form_placeholder_account_alias_name: 'e.g., My Card',
        form_agreement_checkbox_title:
            'I have read the <0 href="https://www.masterpassturkiye.com/TermsAndConditions.aspx">Masterpass User Agreement</0> and want to store my card in the Masterpass infrastructure',
        mp_user_agreement_url:
            'https://www.masterpassturkiye.com/TermsAndConditions.aspx',
        mp_user_agreement_header_title: 'Masterpass User Agreement',
        form_submit_button: 'Add Card',
    },
    MasterPassCards: {
        route_header_title: 'My Masterpass Cards',
        content_header_description:
            'You can select and edit your preferred payment methods',
        mp_delete_alert_title: 'Delete Card',
        mp_delete_alert_message:
            'Are you sure you want to delete your card\nregistered with Masterpass?',
        mp_delete_alert_approve_button: 'Yes',
        mp_delete_alert_cancel_button: 'Cancel',
        register_card_row_item_title: 'Add New Card',
        delete_alert_button: 'Delete',
    },
    MasterPassHelp: {
        route_header_title: 'Masterpass Support',
        question_card_title: 'Are you the cardholder?',
        question_card_description:
            'If the registered mobile number of the cardholder does not belong to you, the SMS will not be sent to you, but will be sent to the cardholder.',

        question_time_title: 'Has the card expired?',
        question_time_description:
            'If the validity of the added card has expired, you may not receive an SMS during the login process.',
        question_number_title: 'Have you changed your registered number?',
        question_number_description:
            'If a card belonging to you has already been registered with a different number, you may not receive an SMS.',
        question_do_what_title: 'What should I do?',
        question_do_what_description:
            'For all these cases, you can update your card information by logging in with your account on the official page of Masterpass, and then try the pairing again in Macellan SuperApp.',
        action_button_help: 'Go to Masterpass Support',
    },
    MasterPassLink: {
        route_header_title: 'Card Linking',
        info_sheet_title: 'Masterpass Card Linking',
        info_sheet_description:
            'You can link your Masterpass account for faster loading',
        info_sheet_question: 'Do you want to continue?',
        info_sheet_approve_button: 'Link Card',
        info_sheet_cancel_button: 'Cancel',
    },
    MasterPass3D: {
        error_message: '3D verification process failed',
    },
    MasterPassOTP: {
        route_header_title: 'Masterpass Verification',
        content_header_title: 'Masterpass Verification',
        content_header_description: 'Enter the 6-digit code sent to your phone',
        resend_otp_toast_message: 'Verification code has been resent',
        success_type_link:
            'Your Masterpass account has been successfully linked',
        success_type_register:
            'Card registration was successful with Masterpass',
        resend_otp_button: 'Resend',
        help_button_text: 'Facing an issue?',
        error_message: 'Payment process failed',
    },
    TopUp: {
        route_header_title: 'Load Balance',
        amount_section_title: 'AMOUNT TO BE LOADED',
        deposit_chips_other_label: 'Other Amount',
        payment_method_section_title: 'PAYMENT METHOD',
        toast_success_message: 'Loading process was successful',
        footer_balance_title: 'Amount to be Loaded',
        footer_submit_button: 'Load Balance',
        other_payment_method_section_title: 'OTHER PAYMENT METHODS',
        other_deposit_method_deposit_card_title:
            'Load Balance By Entering Card Number',
        other_payment_method_cash_deposit_card_title: 'Cash Loading',
        empty_payment_method_title: 'Transaction Information',
        empty_payment_method_description:
            'You will be able to add balance to this brand very soon',
        empty_payment_method_question:
            'If you wish, you can make a purchase by adding a coupon',
        empty_payment_method_coupon_button: 'Add Coupon',
        balance: 'Balance',
        info_title: 'What are my benefits when adding balance?',
        info_description:
            'When you add balance, you earn points according to the campaigns in the wallet.',
        installment_section_title: 'INSTALLMENT OPTİONS',
        installment_section_more_button_title:
            'All Installment Options ({{count}})',
        installment_section_hide_button_title: 'Hide',
        installment_section_single_payment_title: 'Single Payment',
        installment_section_other_title:
            '{{installmentCount}} Installment x {{formattedInstallmentAmount}}',
    },
    EarnablePoint: {
        point_title: 'Gift points you will earn',
        point_label: 'Points',
        up_has_toast_message:
            'Load <0>{{formattedIncreaseAmount}}</0> more,\nearn <0>{{formattedHasPoint}} points</0> instantly!',
        up_has_toast_chip: 'Add Now',
    },
    MasterPassDepositCard: {
        register_description: 'Add Card with Masterpass',
        link_description: 'Link Card with Masterpass',
        edit_button: 'Edit',
    },
    TopUpDepositCard: {
        change_button: 'Change',
    },
    TopUpAnotherCard: {
        content_header_title: 'Load Balance By Entering Card Number',
        content_header_description:
            'Your card information is securely stored with MasterPass',
        form_label_credit_card_no: 'Card Number',
        form_label_expire_date: 'Expiry Date',
        form_label_cvc_no: 'Security Code',
        form_placeholder_expire_date: 'MM/YY',
        form_placeholder_cvc_no: 'CVC/CVV',
        form_label_name: 'Give a Name to the Card',
        form_placeholder_name: 'e.g., My Card',
        form_save_card_title: 'Save this card for future loadings',
        form_submit_button: 'Load Balance',
        form_agreement_checkbox_title:
            'I have read the <0 href="https://www.masterpassturkiye.com/TermsAndConditions.aspx">Masterpass User Agreement</0> and want to store my card in the Masterpass infrastructure',
        mp_user_agreement_header_title: 'Masterpass User Agreement',
        mp_user_agreement_url:
            'https://www.masterpassturkiye.com/TermsAndConditions.aspx',
        max_number_of_cards_error: 'You can add up to 7 cards.',
    },
    GoldFramer: {
        warning_message: '{{amount}} will be charged to your card.',
    },
}
