import * as React from 'react'
import { map } from 'lodash'
import type { RefundSectionProps } from './AccountActivityDetail.types'
import RefundSectionItem from './RefundSectionItem'

const RefundSection: React.FC<RefundSectionProps> = ({
    refundRequests,
    type,
    currency,
}) => {
    return (
        <div className="space-y-6">
            {map(refundRequests, (item, index) => (
                <RefundSectionItem
                    key={item.id + index.toString()}
                    status={item.status}
                    type={type}
                    amount={item.amount}
                    currency={currency}
                    complete={item.complete}
                    date={item.updated_at}
                    referenceCode={item.reference_code}
                    reason={item.reason_translate}
                    companyComment={item.company_comment}
                    userComment={item.user_comment}
                />
            ))}
        </div>
    )
}
export default RefundSection
