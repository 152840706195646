export const calculateDuration = (
    expiresAt: Date,
    serverTimer: Date,
): number => {
    return new Date(expiresAt).getTime() - new Date(serverTimer).getTime()
}

export default {
    calculateDuration: calculateDuration,
}
