import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { filter, map } from 'lodash'
import { CampaignItem, Sheet } from '@components'
import { useAppSelector } from '@core/store'
import campaignApi, { type CampaignNewsListItem } from '@campaign/api'
import { CampaignDetail } from '@campaign/views'
import type { CampaignListTabKey } from './Campaigns.types'
import { useWindowSize } from '@core/hooks'
import CampaignsHeader from './CampaignsHeader'

const Campaigns: React.FC = () => {
    const { t } = useTranslation()
    const ScreenWidth = useWindowSize().width
    const company = useAppSelector(state => state.baseSlice.company)

    const [activeTab, setActiveTab] = React.useState<CampaignListTabKey>('all')
    const [open, setOpen] = React.useState<boolean>(false)
    const [selectedCampaign, setSelectedCampaign] = React.useState<
        number | null
    >(null)

    const campaignNewsListQuery = campaignApi.useCampaignNewsListQuery(
        { page: 1, company_id: company.id, campaign_types: undefined },
        { refetchOnMountOrArgChange: true },
    )

    const campaignNewsListData = React.useMemo(() => {
        return campaignNewsListQuery.data?.data ?? []
    }, [campaignNewsListQuery.data?.data])

    const depositGiftCampaignNewsList = React.useMemo(() => {
        return filter(
            campaignNewsListData,
            (campaignNews: CampaignNewsListItem) =>
                campaignNews.campaign.type === 'deposit_gift' ||
                campaignNews.campaign.type === 'cross_marketing_deposit_gift',
        )
    }, [campaignNewsListData])

    const paymentCountGiftCampaignNewsList = React.useMemo(() => {
        return filter(
            campaignNewsListData,
            (campaignNews: CampaignNewsListItem) =>
                campaignNews.campaign.type === 'payment_count_gift',
        )
    }, [campaignNewsListData])

    const filtered = React.useMemo(() => {
        if (activeTab === 'all') return campaignNewsListData

        if (activeTab === 'deposit_gift') return depositGiftCampaignNewsList

        if (activeTab === 'payment_count_gift')
            return paymentCountGiftCampaignNewsList

        return []
    }, [
        activeTab,
        campaignNewsListData,
        depositGiftCampaignNewsList,
        paymentCountGiftCampaignNewsList,
    ])

    const campaignTabItems = React.useMemo(() => {
        const tabs: { key: CampaignListTabKey; label: string }[] = []

        tabs.push({
            key: 'all',
            label: t('@campaign.Campaigns.campaigns_chips_label_all'),
        })

        if (depositGiftCampaignNewsList.length) {
            tabs.push({
                key: 'deposit_gift',
                label: t(
                    '@campaign.Campaigns.campaigns_chips_label_deposit_gift',
                ),
            })
        }

        if (paymentCountGiftCampaignNewsList.length) {
            tabs.push({
                key: 'payment_count_gift',
                label: t(
                    '@campaign.Campaigns.campaigns_chips_label_payment_count_gift',
                ),
            })
        }

        return tabs
    }, [
        depositGiftCampaignNewsList.length,
        paymentCountGiftCampaignNewsList.length,
        t,
    ])

    if (!campaignTabItems) return null

    return (
        <React.Fragment>
            <CampaignsHeader
                className="pb-10 pt-8"
                campaignTabItems={campaignTabItems}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                itemsCount={filtered.length}
                loading={campaignNewsListQuery.isLoading}>
                {map(filtered, (campaign, key) => {
                    const Comp =
                        key === 0 &&
                        ScreenWidth > 1280 &&
                        campaign.background_image
                            ? CampaignItem.Card
                            : CampaignItem.Box
                    return (
                        <Comp
                            key={key}
                            className={
                                key === 0 &&
                                ScreenWidth > 1280 &&
                                campaign.background_image
                                    ? 'col-span-2 xl:col-span-3'
                                    : 'w-full max-w-full'
                            }
                            image={
                                campaign.background_image
                                    ? campaign.background_image
                                    : ''
                            }
                            currency={campaign.company.currency}
                            point={campaign.campaign.point_amount}
                            isUsed={
                                !campaign.campaign.multiple_usage &&
                                campaign.campaign.user_used
                            }
                            description={campaign.title}
                            actionButtonClick={() => {
                                setOpen(!open)
                                setSelectedCampaign(campaign.id)
                            }}
                        />
                    )
                })}
            </CampaignsHeader>

            <Sheet open={open} onOpenChange={setOpen}>
                <Sheet.Content className="overflow-y-scroll border-none px-0 py-0 pt-12 sm:px-0">
                    <Sheet.Header className="px-10">
                        <Sheet.Title className="text-semantic-content-inkInverse">
                            {t('@campaign.CampaignDetail.route_header_title')}
                        </Sheet.Title>
                    </Sheet.Header>
                    <CampaignDetail campaignId={selectedCampaign} />
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}
export default Campaigns
