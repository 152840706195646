import type { RouteObject } from 'react-router-dom'
import { Capture, GenerateQR } from '@payment/views'

export const PaymentRouter: RouteObject[] = [
    {
        element: <GenerateQR />,
        path: '/payment-qr',
    },
    {
        element: <Capture />,
        path: '/capture',
    },
]
