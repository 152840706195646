import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import type { InitializeResponse } from '@core/api'
import type {
    RedirectPageType,
    RedirectPathsType,
    StateProps,
} from './Initialize.types'

export const useNavigateToPage = () => {
    const navigate = useNavigate()

    const RedirectPaths = React.useMemo<RedirectPathsType>(
        () => ({
            home: { path: '/', stateProps: ['walletId'] },
            deposit: {
                path: '/topup',
                stateProps: ['walletId', 'triggerData'],
            },
        }),
        [],
    )

    return React.useCallback(
        ({
            redirect_page,
            auth_data,
            wallet_id,
            company_id,
            trigger_data,
        }: InitializeResponse['data']) => {
            const { path, stateProps } =
                RedirectPaths[redirect_page as RedirectPageType]
            const state: Partial<StateProps> = {}

            stateProps.forEach(prop => {
                if (prop === 'walletId') state.wallet_id = wallet_id
                if (prop === 'triggerData') state.trigger_data = trigger_data
                if (prop === 'authData') state.auth_data = auth_data
                if (prop === 'redirectPage') state.redirect_page = redirect_page
                if (prop === 'companyId') state.company_id = company_id
            })

            navigate(path, { replace: true, state })
        },
        [navigate, RedirectPaths],
    )
}
