import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Timer, type TimerMethods } from '@components'
import type { ResendVerifyMethods, ResendVerifyProps } from './OTPVerify.types'
import { ReferenceUtils, useTheme } from '@core/utils'

const ResendVerify = React.forwardRef<ResendVerifyMethods, ResendVerifyProps>(
    ({ duration, actionButtonProps, onResetTimer }, ref) => {
        const theme = useTheme()
        const { t } = useTranslation()

        const timer = React.useRef<TimerMethods>(null)

        const [isFinished, setFinished] = React.useState<boolean>(false)

        const handleFinished = React.useCallback(() => {
            setFinished(true)
        }, [])

        const handleResetTimer = React.useCallback(() => {
            if (!isFinished) return

            setFinished(false)
            onResetTimer && onResetTimer()
            timer.current?.resetTimer(true)
        }, [isFinished, onResetTimer])

        if (!duration) return null

        return (
            <div className="otp-resend-verify">
                <Button
                    children={t('@guest.OTPVerify.resend_verify_button')}
                    variant="primary"
                    size="tiny"
                    rounded="pill"
                    leftIcon="notification"
                    disabled={!isFinished}
                    onClick={() => {
                        handleResetTimer()
                        actionButtonProps?.onClick()
                    }}
                />
                <Timer.Circle
                    ref={ReferenceUtils.mergeRefs([timer, ref])}
                    className="flex flex-row-reverse items-center gap-2"
                    durationSuffix={` ${t('@guest.OTPVerify.resend_verify_second')}`}
                    duration={duration}
                    size={22}
                    indicatorColor={theme.colors.solid.brand[5]}
                    backgroundColor={theme.colors.semantic.background.secondary}
                    valueTextStyle={{
                        fontSize: 11,
                        color: theme.colors.semantic.content.inkLight,
                    }}
                    onFinished={handleFinished}
                />
            </div>
        )
    },
)
export default ResendVerify
