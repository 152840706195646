import React from 'react'
import { Icon } from '@components'
import type { CheckBoxProps } from './CheckBox.types'
import './CheckBox.css'

const CheckBox: React.FC<CheckBoxProps> = props => {
    const { children, ...rest } = props
    return (
        <div className="checkbox animate-enter">
            <label>
                <div>
                    <input type="checkbox" {...rest} />
                    <div className="checkbox__icon">
                        <Icon name="check" size={24} color="white" />
                    </div>
                </div>
                <p className="select-none">{children}</p>
            </label>
        </div>
    )
}

export default CheckBox
