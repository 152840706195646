import * as React from 'react'
import type { BadgeProps } from './Badge.types'
import { IconSize } from './Badge.utils'
import { cn } from '@core/utils'
import Icon from '../Icon/Icon'
import Spinner from '../Spinner/Spinner'
import { badgeStyles } from './Badge.styles'

const Badge = React.forwardRef<HTMLButtonElement, BadgeProps>(
    (
        { variant, size, className, leftIcon, loading, children, as, ...props },
        ref,
    ) => {
        const Comp = as || 'button'

        return (
            <Comp
                ref={ref}
                className={cn(badgeStyles({ variant, size, className }))}
                disabled={loading || props.disabled}
                {...props}>
                {!loading && leftIcon && (
                    <Icon name={leftIcon} size={IconSize(size)} />
                )}
                {!loading && children}
                {loading && <Spinner size={IconSize(size)} />}
            </Comp>
        )
    },
)

Badge.displayName = 'Badge'

export default Badge
