import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Icon } from '@components'
import type { SidebarItemProps } from './Sidebar.types.ts'
import { SendPostMessage } from '@core/utils'
import { SidebarMenuItem } from './Sidebar.constants'

const SidebarItem: React.FC<SidebarItemProps> = ({ isMobile }) => {
    const { t } = useTranslation()
    const location = useLocation().pathname

    return (
        <React.Fragment>
            {map(SidebarMenuItem, (item, index) => (
                <Link
                    key={index}
                    to={item.pathname}
                    className="sidebar-menu-detail__item">
                    <div
                        data-state={
                            item.pathname === location ? 'active' : 'inactive'
                        }
                        className="sidebar-menu-detail__item-icon">
                        <Icon name={item.icon} size={24} />
                    </div>
                    <div className="sidebar-menu-detail__item-content">
                        <h3 className="sidebar-menu-detail__item-content-title">
                            {t(item.title)}
                        </h3>
                        <div className="sidebar-menu-detail__item-content-icon">
                            <Icon name="caret-right" size={24} />
                        </div>
                    </div>
                </Link>
            ))}
            {!isMobile && (
                <button
                    className="sidebar-menu-detail__item lg:!hidden"
                    onClick={() =>
                        SendPostMessage(
                            JSON.stringify({
                                event: 'ReturnToSite',
                            }),
                        )
                    }>
                    <div
                        data-state="inactive"
                        className="sidebar-menu-detail__item-icon">
                        <Icon name="wallet" size={24} />
                    </div>
                    <div className="sidebar-menu-detail__item-content">
                        <h3 className="sidebar-menu-detail__item-content-title">
                            {t('content_config.Sidebar.return_to_site')}
                        </h3>
                        <div className="sidebar-menu-detail__item-content-icon">
                            <Icon name="caret-right" size={24} />
                        </div>
                    </div>
                </button>
            )}
        </React.Fragment>
    )
}

export default SidebarItem
