import * as React from 'react'
import { map } from 'lodash'
import type { InstallmentCardProps } from './InstallmentCard.types'
import { cn } from '@core/utils'
import {
    InstallmentCardItemSizes,
    InstallmentCardSizes,
} from './InstallmentCard.constants'
import InstallmentCardFooter from './InstallmentCardFooter'
import InstallmentCardItem from './InstallmentCardItem'

const InstallmentCard: React.FC<InstallmentCardProps> = ({
    data,
    buttonText,
    currency,
    onActionClick,
    className,
    cardItemProps,
    onClick,
    ...props
}) => {
    const [isOpened, setOpened] = React.useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

    const closedHeight = React.useMemo(() => {
        return data.length < 4
            ? data.length * InstallmentCardItemSizes.height
            : InstallmentCardSizes.closedHeight
    }, [data.length])

    const openedHeight = React.useMemo(() => {
        return data.length * InstallmentCardItemSizes.height
    }, [data.length])

    const height = React.useMemo(() => {
        return isOpened ? openedHeight : closedHeight
    }, [closedHeight, isOpened, openedHeight])

    const handleItemPress = React.useCallback(
        (index: number) => {
            setSelectedIndex(index)
            onActionClick?.(index)
        },
        [onActionClick, setSelectedIndex],
    )

    const handleClickToggle = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            setOpened(prev => !prev)
            onClick?.(e)
        },
        [onClick],
    )

    React.useEffect(() => {
        if (data.length < 4) setOpened(false)
    }, [data, handleItemPress])

    return (
        <div
            className={cn(
                'flex flex-col overflow-hidden rounded-[13px] border border-semantic-background-tertiary pl-4 animate-in fade-in zoom-in-90',
                className,
            )}
            {...props}>
            <div
                className="flex flex-col transition-all duration-300"
                style={{ height }}>
                {map(data, (item, index) => (
                    <InstallmentCardItem
                        key={index}
                        title={item.title}
                        amount={item.amount}
                        currency={currency}
                        isSelected={selectedIndex === index}
                        onClick={() => handleItemPress(index)}
                        {...cardItemProps}
                    />
                ))}
            </div>
            <InstallmentCardFooter
                buttonText={buttonText}
                isOpened={isOpened}
                canOpen={data.length > 3}
                onClick={handleClickToggle}
            />
        </div>
    )
}
export default InstallmentCard
