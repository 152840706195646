import * as React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import type {
    CarouselApi,
    CarouselProps,
    CarouselStaticProperties,
} from './Carousel.types'
import { cn } from '@core/utils'
import { CarouselContext } from './Carousel.context'
import CarouselContent from './CarouselContent'
import CarouselItem from './CarouselItem'
import CarouselNext from './CarouselNext'
import CarouselPrevious from './CarouselPrevious'

const CarouselBase = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
    (
        {
            orientation = 'horizontal',
            opts,
            setApi,
            plugins,
            className,
            children,
            ...props
        },
        ref,
    ) => {
        const [carouselRef, api] = useEmblaCarousel(
            {
                ...opts,
                axis: orientation === 'horizontal' ? 'x' : 'y',
            },
            plugins,
        )
        const [canScrollPrev, setCanScrollPrev] = React.useState(false)
        const [canScrollNext, setCanScrollNext] = React.useState(false)

        const onSelect = React.useCallback((api: CarouselApi) => {
            if (!api) {
                return
            }

            setCanScrollPrev(api.canScrollPrev())
            setCanScrollNext(api.canScrollNext())
        }, [])

        const scrollPrev = React.useCallback(() => {
            api?.scrollPrev()
        }, [api])

        const scrollNext = React.useCallback(() => {
            api?.scrollNext()
        }, [api])

        const handleKeyDown = React.useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'ArrowLeft') {
                    event.preventDefault()
                    scrollPrev()
                } else if (event.key === 'ArrowRight') {
                    event.preventDefault()
                    scrollNext()
                }
            },
            [scrollPrev, scrollNext],
        )

        React.useEffect(() => {
            if (!api || !setApi) {
                return
            }

            setApi(api)
        }, [api, setApi])

        React.useEffect(() => {
            if (!api) {
                return
            }

            onSelect(api)
            api.on('reInit', onSelect)
            api.on('select', onSelect)

            return () => {
                api?.off('select', onSelect)
            }
        }, [api, onSelect])

        return (
            <CarouselContext.Provider
                value={{
                    carouselRef,
                    api: api,
                    opts,
                    orientation:
                        orientation ||
                        (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
                    scrollPrev,
                    scrollNext,
                    canScrollPrev,
                    canScrollNext,
                }}>
                <div
                    ref={ref}
                    onKeyDownCapture={handleKeyDown}
                    className={cn('relative', className)}
                    role="region"
                    aria-roledescription="carousel"
                    {...props}>
                    {children}
                </div>
            </CarouselContext.Provider>
        )
    },
)

const Carousel = CarouselBase as React.ForwardRefExoticComponent<
    React.PropsWithChildren<CarouselProps & React.RefAttributes<HTMLDivElement>>
> &
    CarouselStaticProperties

Carousel.Content = CarouselContent
Carousel.Item = CarouselItem
Carousel.Next = CarouselNext
Carousel.Previous = CarouselPrevious

Carousel.displayName = 'Carousel'

export default Carousel
