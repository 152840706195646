import { omit } from 'lodash'
import baseApi from '@core/api'
import type {
    AssignWalletData,
    AssignWalletResponse,
    PaymentStatusData,
    PaymentStatusResponse,
    PreApprovalData,
    PreApprovalResponse,
    QrGenerateResponse,
    QrStatusData,
    QrStatusResponse,
    RefundQrReplyData,
    RefundQrReplyResponse,
    RefundReasonListResponse,
    RefundRequestData,
    RefundRequestPreCheckData,
    RefundRequestPreCheckResponse,
    RefundRequestResponse,
    RespondData,
    RespondResponse,
} from './PaymentApi.types'

const paymentApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        qrGenerate: builder.mutation<QrGenerateResponse, void>({
            query: () => ({
                url: 'v2/qr/generate',
                method: 'GET',
            }),
        }),
        qrStatus: builder.query<QrStatusResponse, QrStatusData>({
            query: data => ({
                url: 'v2/qr/status',
                method: 'GET',
                params: data,
            }),
        }),
        assignWallet: builder.mutation<AssignWalletResponse, AssignWalletData>({
            query: data => ({
                url: `v2/payment/${data.paymentId}/assign-wallet`,
                method: 'PUT',
                body: omit(data, ['payment_id']),
            }),
        }),
        preApproval: builder.query<PreApprovalResponse, PreApprovalData>({
            query: data => ({
                url: `v2/payment/${data.paymentId}/pre-approval`,
                method: 'GET',
            }),
        }),
        respond: builder.mutation<RespondResponse, RespondData>({
            query: data => ({
                url: 'v2/payment/respond',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: response =>
                response?.data.status !== 'complete' ? [] : ['WalletDetail'],
        }),
        paymentStatus: builder.query<PaymentStatusResponse, PaymentStatusData>({
            query: data => ({
                url: 'v2/payment/status',
                method: 'GET',
                params: data,
            }),
        }),
        refundReasonList: builder.query<RefundReasonListResponse, undefined>({
            query: () => ({
                url: 'v2/payment/refund/reason-list',
                method: 'GET',
            }),
        }),
        refundRequest: builder.mutation<
            RefundRequestResponse,
            RefundRequestData
        >({
            query: data => ({
                url: 'v2/payment/refund/request',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: response =>
                response?.data.status !== 'open'
                    ? []
                    : ['BalanceHistoryDetail'],
        }),
        refundQrReply: builder.mutation<
            RefundQrReplyResponse,
            RefundQrReplyData
        >({
            query: data => ({
                url: 'v2/payment/refund/qr-reply',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_r, error) =>
                error
                    ? []
                    : [
                          'BalanceHistoryDetail',
                          'WalletDetail',
                          'BalanceHistoryList',
                      ],
        }),
        refundRequestPreCheck: builder.query<
            RefundRequestPreCheckResponse,
            RefundRequestPreCheckData
        >({
            query: data => ({
                url: 'v2/payment/refund/request/pre-check',
                method: 'GET',
                params: data,
            }),
        }),
    }),
})

export default paymentApi
