import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import { RadioButton } from '@components'
import i18n from '@core/translations'
import type { InstallmentCardItemProps } from './InstallmentCard.types'

const InstallmentCardItem: React.FC<InstallmentCardItemProps> = ({
    title,
    amount,
    currency,
    isSelected,
    onClick,
    ...props
}) => {
    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(amount, i18n.getLocale(), currency)
    }, [amount, currency])

    return (
        <button
            className="flex items-center gap-3"
            onClick={onClick}
            {...props}>
            <RadioButton size={24} value={isSelected} />
            <div className="flex w-full items-center justify-between border-b border-semantic-background-tertiary py-5 pr-6">
                <div className="animate-enter text-body-md-r text-semantic-content-inkMedium">
                    {title}
                </div>
                <div className="animate-enter text-body-lg-b text-semantic-content-inkMedium">
                    {formattedAmount}
                </div>
            </div>
        </button>
    )
}

export default InstallmentCardItem
