import * as React from 'react'
import { Progress } from '@components'
import type { TimerBarProps, TimerMethods } from './Timer.types'

const TimerBar = React.forwardRef<TimerMethods, TimerBarProps>(
    (
        { duration, auto = true, isReverse = false, onFinished, ...props },
        ref,
    ) => {
        const [progress, setProgress] = React.useState(isReverse ? 100 : 0)
        const intervalRef = React.useRef<ReturnType<typeof setInterval> | null>(
            null,
        )

        const handleStartTimer = React.useCallback(() => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
                onFinished && onFinished()
            }

            intervalRef.current = setInterval(() => {
                setProgress(currentProgress => {
                    const nextProgress = isReverse
                        ? currentProgress - 1
                        : currentProgress + 1
                    if (nextProgress <= 0 || nextProgress >= 100) {
                        clearInterval(
                            intervalRef.current as ReturnType<
                                typeof setInterval
                            >,
                        )
                        return isReverse ? 0 : 100
                    }
                    return nextProgress
                })
            }, duration / 100)
        }, [duration, isReverse, onFinished])

        const handleResetTimer = React.useCallback(
            (autoStart: boolean) => {
                if (intervalRef.current !== null) {
                    clearInterval(intervalRef.current)
                }
                setProgress(isReverse ? 100 : 0)
                autoStart && handleStartTimer()
            },
            [handleStartTimer, isReverse],
        )

        const handleSetTimerValue = React.useCallback((value: number) => {
            setProgress(value)
        }, [])

        React.useEffect(() => {
            if (auto) {
                handleStartTimer()
            }
            return () => {
                if (intervalRef.current !== null) {
                    clearInterval(intervalRef.current)
                }
            }
        }, [auto, handleStartTimer])

        React.useImperativeHandle(ref, () => ({
            startTimer: handleStartTimer,
            resetTimer: handleResetTimer,
            setTimerValue: handleSetTimerValue,
        }))

        return <Progress.Bar value={progress} {...props} />
    },
)

TimerBar.displayName = 'TimerBar'

export default TimerBar
