export default {
    401: {
        title: 'Unauthorized Access',
        description: 'You do not have permission to access this page.',
        errorMessages: 'HTTP ERROR 401',
    },
    404: {
        title: 'Oops! The page you are looking for is not here',
        description:
            'The page you are looking for might have been removed or its address might have been changed.',
        errorMessages: 'HTTP ERROR 404',
        actionsButton: 'Go to Homepage',
    },
}
