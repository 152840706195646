import * as React from 'react'
import type {
    RewardChartComponentSizes,
    RewardChartListItemComponentSizes,
} from './RewardChart.types'

export const RewardChartSizes = ({ width }: RewardChartComponentSizes) => {
    return width > 1024 ? 152 : 120
}

export const RewardChartTableListItemSizes = ({
    width,
}: RewardChartListItemComponentSizes) => {
    const size = React.useMemo(() => {
        const ChartWidth = width > 1024 ? 40 : 38
        const ChartHeight = width > 1024 ? 136 : 120

        return {
            width: ChartWidth,
            height: ChartHeight,
        }
    }, [width])

    return size
}
