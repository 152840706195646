import * as React from 'react'
import type { CanGoBack } from '@core/context'
import type { CardType } from '@deposit/api'
import { emitter } from '@core/utils'

const UseCompletePurchase = (amount?: number) => {
    return React.useCallback(
        (success: boolean, paymentMethod: CardType, canGoBack?: CanGoBack) => {
            const walletId = Number(localStorage.getItem('user.wallet_id'))

            if (!success) {
                emitter.emit('topup-failed', {
                    walletId,
                    amount,
                    payment_method: paymentMethod,
                    canGoBack,
                })
                return
            }

            emitter.emit('topup-complete', {
                walletId,
                amount,
                payment_method: paymentMethod,
                canGoBack,
            })
        },
        [amount],
    )
}
export default UseCompletePurchase
