import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { CurrencyFormatter } from '@macellan/formatter'
import type { DetailItemSection } from '@components'
import i18next from '@core/translations'
import type { BalanceHistoryDetailsResponse } from '@wallet/api'

dayjs.extend(relativeTime)

export const useDetailsByType = (
    detail: BalanceHistoryDetailsResponse['data'] | undefined,
) => {
    const { t } = useTranslation()
    if (!detail) return []

    const transactionDetails: DetailItemSection[] = []
    const branchDetails: DetailItemSection[] = []
    const additionalDetails: DetailItemSection[] = []
    const softPlayDetails: DetailItemSection[] = []

    if (detail.branch?.name) {
        branchDetails.push({
            title: t('@wallet.AccountActivityDetail.branch_name'),
            description: detail.branch.name,
        })
    }

    if (detail.cashier?.full_name) {
        branchDetails.push({
            title: t('@wallet.AccountActivityDetail.cashier'),
            description: detail.cashier.full_name,
        })
    }

    if (detail.details.total_earned_points) {
        additionalDetails.push({
            title: t('@wallet.AccountActivityDetail.total_earned_points'),
            description: CurrencyFormatter.format(
                detail.details.total_earned_points,
                i18next.getLocale(),
                detail.wallet.company.currency,
            ),
        })
    }

    detail.earned_points?.map(item => {
        additionalDetails.push(
            ...[
                {
                    title: t('@wallet.AccountActivityDetail.point_group'),
                    description: item.win_name,
                },
                {
                    title: t('@wallet.AccountActivityDetail.earned_points'),
                    description: CurrencyFormatter.format(
                        item.amount,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
            ],
        )

        if (item.target_company) {
            additionalDetails.push({
                title: t('@wallet.AccountActivityDetail.earned_wallet'),
                description: item.target_company.name,
            })
        }
    })

    if (detail.type === 'capture') {
        transactionDetails.push(
            ...[
                {
                    title: t('@wallet.AccountActivityDetail.process_type'),
                    description: t('@wallet.AccountActivityDetail.capture'),
                },
                {
                    title: t('@wallet.AccountActivityDetail.spent_amount'),
                    description: CurrencyFormatter.format(
                        detail.historyable.final_total ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.order_id'),
                    description: detail.historyable.order_id?.toString() ?? '0',
                },
                {
                    title: t('@wallet.AccountActivityDetail.date'),
                    description: dayjs(detail.historyable.payment_time)
                        .format('DD.MM.YYYY - HH:mm')
                        .toString(),
                },
            ],
        )

        if (
            detail.historyable.final_total !==
            detail.historyable.discounted_total_amount
        ) {
            transactionDetails.push(
                {
                    title: t('@wallet.AccountActivityDetail.discount_amount'),
                    description: CurrencyFormatter.format(
                        detail.historyable.discount_point,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t(
                        '@wallet.AccountActivityDetail.amount_after_discount',
                    ),
                    description: CurrencyFormatter.format(
                        detail.historyable.discounted_total_amount,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
            )
        }

        if (detail.historyable.point) {
            transactionDetails.push(
                ...[
                    {
                        title: t('@wallet.AccountActivityDetail.spent_balance'),
                        description: CurrencyFormatter.format(
                            detail.historyable.paid_price ?? 0,
                            i18next.getLocale(),
                            detail.wallet.company.currency,
                        ),
                    },
                    {
                        title: t('@wallet.AccountActivityDetail.spent_point'),
                        description: CurrencyFormatter.format(
                            detail.historyable.point,
                            i18next.getLocale(),
                            detail.wallet.company.currency,
                        ),
                    },
                ],
            )
        }

        if (detail.historyable.related?.name) {
            transactionDetails.push(
                ...[
                    {
                        title: t('@wallet.AccountActivityDetail.payment_point'),
                        description: detail.historyable.related?.name,
                    },
                ],
            )
        }

        if (detail.is_wallet_share) {
            additionalDetails.push({
                title: t('@wallet.AccountActivityDetail.wallet_type'),
                description: t('@wallet.AccountActivityDetail.shared_wallet'),
            })
        }

        if (detail.sharer) {
            additionalDetails.push({
                title: t('@wallet.AccountActivityDetail.sharer'),
                description: detail.sharer as string,
            })
        }

        if (detail.shared) {
            additionalDetails.push({
                title: t('@wallet.AccountActivityDetail.shared'),
                description: detail.shared as string,
            })
        }

        if (detail.ws_expire_date) {
            additionalDetails.push({
                title: t('@wallet.AccountActivityDetail.expire_date'),
                description: dayjs(detail.ws_expire_date).format(
                    'DD.MM.YYYY - HH:mm',
                ),
            })
        }

        if (detail.description === 'iap') {
            additionalDetails.push(
                ...[
                    {
                        title: t('@wallet.AccountActivityDetail.description'),
                        description: t(
                            '@wallet.AccountActivityDetail.gift_card',
                        ),
                    },
                    {
                        title: t('@wallet.AccountActivityDetail.shared'),
                        description:
                            detail.historyable?.related?.receiver?.full_name ||
                            detail.historyable?.related?.receiver_phone ||
                            t('@wallet.AccountActivityDetail.undefined_user'),
                    },
                ],
            )
        }
    }

    if (detail.type === 'top-up') {
        transactionDetails.push(
            ...[
                {
                    title: t('@wallet.AccountActivityDetail.process_type'),
                    description: t('@wallet.AccountActivityDetail.top_up'),
                },
                {
                    title: t('@wallet.AccountActivityDetail.loaded_amount'),
                    description: CurrencyFormatter.format(
                        detail.amount,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.reference_code'),
                    description:
                        detail.historyable.reference_code?.toString() ?? '0',
                },
                {
                    title: t('@wallet.AccountActivityDetail.date'),
                    description: dayjs(detail.historyable.created_at).format(
                        'DD.MM.YYYY - HH:mm',
                    ),
                },
            ],
        )

        if (detail.description === 'cash') {
            transactionDetails.push({
                title: t('@wallet.AccountActivityDetail.loaded_type'),
                description: t('@wallet.AccountActivityDetail.cash'),
            })
        } else if (detail.description === 'bank_transfer') {
            transactionDetails.push({
                title: t('@wallet.AccountActivityDetail.loaded_type'),
                description: t('@wallet.AccountActivityDetail.bank_transfer'),
            })
        } else if (detail.description === 'turkcell_dcb') {
            transactionDetails.push({
                title: t('@wallet.AccountActivityDetail.loaded_type'),
                description: t('@wallet.AccountActivityDetail.dcb'),
            })
        } else {
            transactionDetails.push(
                ...[
                    {
                        title: t('@wallet.AccountActivityDetail.loaded_type'),
                        description:
                            detail.description?.toLocaleUpperCase() ?? '',
                    },
                    {
                        title: t('@wallet.AccountActivityDetail.card_number'),
                        description: detail.historyable.card_number,
                    },
                ],
            )
        }
    }

    if (detail.type === 'payment_refund') {
        transactionDetails.push(
            ...[
                {
                    title: t('@wallet.AccountActivityDetail.process_type'),
                    description: t(
                        '@wallet.AccountActivityDetail.payment_refund',
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.refunded_amount'),
                    description: CurrencyFormatter.format(
                        detail.historyable.amount ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.reference_code'),
                    description:
                        detail.historyable.refundRequest?.reference_code ?? '',
                },
                {
                    title: t('@wallet.AccountActivityDetail.date'),
                    description: dayjs(detail.historyable.created_at).format(
                        'DD.MM.YYYY - HH:mm',
                    ),
                },
            ],
        )

        if (detail.historyable?.refundRequest?.reason_translate) {
            additionalDetails.push({
                title: t('@wallet.AccountActivityDetail.description'),
                description: detail.historyable.refundRequest.reason_translate,
            })
        }
    }

    if (detail.type === 'deposit_refund') {
        transactionDetails.push(
            ...[
                {
                    title: t('@wallet.AccountActivityDetail.process_type'),
                    description: t(
                        '@wallet.AccountActivityDetail.deposit_refund',
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.refunded_amount'),
                    description: CurrencyFormatter.format(
                        detail.amount ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.bank_amount'),
                    description: CurrencyFormatter.format(
                        detail.historyable.bank_amount_float ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t(
                        '@wallet.AccountActivityDetail.point_deducted_balance',
                    ),
                    description: CurrencyFormatter.format(
                        detail.historyable.point_deducted_balance_float ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t(
                        '@wallet.AccountActivityDetail.total_revoked_point',
                    ),
                    description: CurrencyFormatter.format(
                        detail.details.total_revoked_points,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.reference_code'),
                    description:
                        detail.historyable.reference_code?.toString() ?? '',
                },
                {
                    title: t('@wallet.AccountActivityDetail.date'),
                    description: dayjs(detail.historyable.refunded_date).format(
                        'DD.MM.YYYY - HH:mm',
                    ),
                },
            ],
        )
    }

    if (detail.type === 'cash_deposit_refund') {
        transactionDetails.push(
            ...[
                {
                    title: t('@wallet.AccountActivityDetail.process_type'),
                    description: t(
                        '@wallet.AccountActivityDetail.cash_deposit_refund',
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.loaded_amount'),
                    description: CurrencyFormatter.format(
                        detail.historyable.amount ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.refunded_amount'),
                    description: CurrencyFormatter.format(
                        detail.amount ?? 0,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
                {
                    title: t('@wallet.AccountActivityDetail.date'),
                    description: dayjs(detail.created_at).format(
                        'DD.MM.YYYY - HH:mm',
                    ),
                },
                {
                    title: t(
                        '@wallet.AccountActivityDetail.total_revoked_point',
                    ),
                    description: CurrencyFormatter.format(
                        detail.details.total_revoked_points,
                        i18next.getLocale(),
                        detail.wallet.company.currency,
                    ),
                },
            ],
        )
    }

    if (detail.historyable?.installment_count > 1) {
        transactionDetails.push(
            {
                title: t('@wallet.AccountActivityDetail.installment_count'),
                description: detail.historyable?.installment_count.toString(),
            },
            {
                title: t(
                    '@wallet.AccountActivityDetail.total_installment_amount',
                ),
                description: CurrencyFormatter.format(
                    detail.historyable.final_total_float,
                    i18next.getLocale(),
                    detail.wallet.company.currency,
                ),
            },
        )
    }

    const result = []

    if (transactionDetails.length) {
        result.push({
            title: t('@wallet.AccountActivityDetail.transaction_details'),
            sections: transactionDetails,
        })
    }

    if (branchDetails.length) {
        result.push({
            title: t('@wallet.AccountActivityDetail.branch_details'),
            sections: branchDetails,
        })
    }

    if (additionalDetails.length) {
        result.push({
            title: t('@wallet.AccountActivityDetail.additional_details'),
            sections: additionalDetails,
        })
    }

    if (softPlayDetails.length) {
        result.push({
            title: t('@wallet.AccountActivityDetail.soft_play_details'),
            sections: softPlayDetails,
        })
    }

    return result
}
