import * as React from 'react'
import { first, size } from 'lodash'
import type { DefaultDepositAmount } from '@company/api'
import type { useCheckAmountProps } from './TopUp.types'
import { checkDepositAmount, getAmount } from './TopUp.utils'

export const useDefaultDepositAmount = (
    defaultDepositAmounts: DefaultDepositAmount[] | undefined = [],
) => {
    const [defaultAmount, setDefaultAmount] = React.useState<number>(0)

    React.useEffect(() => {
        if (!size(defaultDepositAmounts)) return
        const firstAmount = first(defaultDepositAmounts)
        firstAmount && setDefaultAmount(firstAmount.amount)
    }, [defaultDepositAmounts])

    return defaultAmount
}

export const useCheckAmount = ({
    location,
    wallet,
    setIsCustomAmount,
}: useCheckAmountProps) => {
    const [amount, setAmount] = React.useState<number>(0)

    const checkAmount = React.useCallback(async () => {
        if (!location?.trigger_data?.payment_amount) return

        const result = await getAmount(location.trigger_data.payment_amount)

        if (!result?.deposit_balance || !wallet) return

        const isAmount = checkDepositAmount(
            wallet.company.settings.default_deposit_amounts,
            result,
        )

        setIsCustomAmount(isAmount)

        if (result.point_usage.status)
            return setAmount(result.deposit_point_and_balance)

        return setAmount(result.deposit_balance)
    }, [location?.trigger_data?.payment_amount, setIsCustomAmount, wallet])

    React.useEffect(() => {
        checkAmount()
    }, [checkAmount])

    return amount
}
