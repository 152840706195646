import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PinInput } from '@components'
import type {
    OTPVerifyContentMethods,
    OTPVerifyContentProps,
} from './OTPVerify.types'
import { PhoneMaskUtils } from './OTPVerify.utils'
import ResendVerify from './ResendVerify'

const OTPVerifyContent = React.forwardRef<
    OTPVerifyContentMethods,
    OTPVerifyContentProps
>(({ state, code, setCode, onResendVerify, onSubmitValue }, ref) => {
    const { t } = useTranslation()

    return (
        <div className="otp-content-body">
            <div className="otp-content-body-header">
                <h2>{t('@guest.OTPVerify.content_header_title')}</h2>
                <p>
                    {t('@guest.OTPVerify.content_header_description', {
                        phone_number: `(+${state.auth_data.phone.country_code}) ${PhoneMaskUtils(state.auth_data.phone.national_number)}`,
                    })}
                </p>
            </div>
            <div>
                <PinInput
                    value={code}
                    inputLength={6}
                    onChangeValue={setCode}
                    onSubmitValue={onSubmitValue}
                />
                <ResendVerify
                    ref={ref}
                    duration={state?.duration}
                    actionButtonProps={{
                        onClick: onResendVerify,
                    }}
                />
            </div>
        </div>
    )
})

export default OTPVerifyContent
