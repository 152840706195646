import * as React from 'react'
import type { CurrencyCode } from '@macellan/formatter'
import { CurrencyFormatter } from '@macellan/formatter'
import i18n from '@core/translations'

export const useFormatCurrency = (
    amount: number | undefined,
    point: number | undefined,
    currency: CurrencyCode | undefined,
) => {
    const locale = i18n.getLocale()
    const currencySymbol = React.useMemo(() => {
        if (!currency) return null
        return CurrencyFormatter.getSymbol(currency)
    }, [currency])

    const formattedAmount = React.useMemo(() => {
        if (typeof amount !== 'number' || !currency) return null
        return CurrencyFormatter.formatToDetails(amount, locale, currency).digid
    }, [amount, locale, currency])

    const formattedPoint = React.useMemo(() => {
        if (typeof point !== 'number' || !currency) return null
        return CurrencyFormatter.formatToDetails(point, locale, currency).digid
    }, [point, currency, locale])

    return {
        currencySymbol: currencySymbol,
        formattedAmount: formattedAmount,
        formattedPoint: formattedPoint,
    }
}
