import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type { TooltipProps, TooltipStaticProperties } from './Tooltip.types'
import TooltipContent from './TooltipContent'

export const TooltipProvider = TooltipPrimitive.Provider

const Tooltip: React.FC<TooltipProps> & TooltipStaticProperties = ({
    children,
    ...props
}) => {
    return (
        <TooltipPrimitive.Root delayDuration={0} {...props}>
            {children}
        </TooltipPrimitive.Root>
    )
}

export const TooltipTrigger = TooltipPrimitive.Trigger

Tooltip.Provider = TooltipProvider
Tooltip.Trigger = TooltipTrigger
Tooltip.Content = TooltipContent

export default Tooltip
