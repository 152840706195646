import React from 'react'
import { Button } from '@components'
import type { ReadMoreProps } from './ReadMore.types'
import {
    expandOrCollapseContent,
    getButtonText,
    getDisplayedContent,
    shouldShowFullContent,
} from './ReadMore.utils'

const ReadMore: React.FC<ReadMoreProps> = props => {
    const { content, limit, buttonText } = props
    const contentRef = React.useRef<HTMLDivElement>(null)
    const [showMore, setShowMore] = React.useState(false)
    const [limitContent] = React.useState(limit || 300)

    React.useEffect(() => {
        expandOrCollapseContent(contentRef, showMore)
    }, [showMore])

    if (shouldShowFullContent(content, limitContent)) {
        return (
            <div
                className="whitespace-pre-wrap text-body-lg-r text-semantic-content-inkMedium"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        )
    }

    return (
        <div className="relative flex flex-col gap-2">
            <div
                ref={contentRef}
                className="max-h-[180px] overflow-hidden whitespace-pre-wrap text-body-lg-r text-semantic-content-inkMedium transition-[max-height] duration-500 ease-in-out"
                dangerouslySetInnerHTML={{
                    __html: getDisplayedContent(
                        content,
                        limitContent,
                        showMore,
                    ),
                }}></div>
            {!showMore && (
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-b from-transparent to-white" />
            )}
            <div className="-ml-1.5 flex">
                <Button
                    children={getButtonText(showMore, buttonText)}
                    variant="ghost-on-light"
                    size="tiny"
                    rounded="pill"
                    rightIcon="caret-down"
                    onClick={() => setShowMore(!showMore)}
                />
            </div>
        </div>
    )
}

export default ReadMore
