import * as React from 'react'
import { cn } from '@core/utils'

const SheetHeader = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn('mb-8 flex flex-col space-y-4 text-left', className)}
        {...props}
    />
)
SheetHeader.displayName = 'SheetHeader'

export default SheetHeader
