/* eslint-disable @typescript-eslint/no-explicit-any */

import { filter, find, first } from 'lodash'
import { type MasterPassCard, MasterPassSDK } from '@macellan/masterpass-sdk'
import store from '@core/store'
import depositApi, { type UserCard } from '@deposit/api'
import {
    removeMpCards,
    setMpAccountLoading,
    setMpAccountType,
    setMpCards,
    setMpCardsLoading,
    setMpDeleteLoading,
    setMpLinkLoading,
    setMpMakeLoading,
    setMpPurchaseLoading,
    setMpRegisterLoading,
    setMpResendOTPLoading,
    setMpUnLinkLoading,
    setMpValidateTransactionLoading,
} from '@deposit/slice'
import type {
    MPDirectPurchaseData,
    MPMakeData,
    MPPurchaseAndRegisterData,
    MPPurchaseData,
    MPRegisterData,
} from './MasterPass.types'

const dispatch = store.dispatch

const getMpToken = async () => {
    const masterpassGetTokenResult = await dispatch(
        depositApi.endpoints.masterpassGetToken.initiate(undefined, {
            forceRefetch: true,
        }),
    ).unwrap()

    MasterPassSDK.setAddress(masterpassGetTokenResult.data.service_url)
    MasterPassSDK.setClientId(masterpassGetTokenResult.data.client_id)

    return masterpassGetTokenResult.data
}

const fail = async (depositId: number, errorResponse: object) => {
    return await dispatch(
        depositApi.endpoints.masterpassFail.initiate({
            deposit_id: depositId,
            result: errorResponse,
        }),
    ).unwrap()
}

const checkMasterPass = async () => {
    dispatch(setMpAccountLoading(true))

    try {
        const details = await getMpToken()

        const checkMasterPassResult = await MasterPassSDK.checkMasterPass({
            userId: details.user_phone,
            token: details.token,
            referenceNo: details.reference_code,
            sendSms: 'N',
            sendSmsLanguage: details.sms_language,
        })

        const accountType = MasterPassSDK.Utils.parseAccountStatus(
            checkMasterPassResult.accountStatus,
        )

        dispatch(setMpAccountType(accountType))

        return accountType
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpAccountLoading(false))
    }
}

const listCards = async () => {
    dispatch(setMpCardsLoading(true))

    try {
        const details = await getMpToken()

        const listCardsResult = await MasterPassSDK.listCards(
            details.user_phone,
            details.token,
        )

        dispatch(setMpCards(listCardsResult.cards))

        return listCardsResult.cards
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpCardsLoading(false))
    }
}

const deleteCards = async (accountAliasName: string) => {
    dispatch(setMpDeleteLoading(true))

    try {
        const details = await getMpToken()

        await MasterPassSDK.deleteCard({
            accountAliasName: accountAliasName,
            msisdn: details.user_phone,
            token: details.token,
            referenceNo: details.reference_code,
            sendSmsLanguage: details.sms_language,
            sendSms: 'N',
        })

        dispatch(removeMpCards(accountAliasName))

        const cardList = await dispatch(
            depositApi.endpoints.userCardList.initiate(undefined),
        ).unwrap()

        const selectedMpCard = find(
            cardList.data,
            card => card.type === 'masterpass',
        )

        if (selectedMpCard?.name === accountAliasName) {
            const otherMpCard = find(
                store.getState().depositSlice.mpCards,
                card => card.Name !== accountAliasName,
            )

            otherMpCard && selectCard(otherMpCard)
        }

        const mpCards = store.getState().depositSlice.mpCards

        if (!mpCards.length) {
            dispatch(setMpAccountType('not-user'))
            return []
        }

        return mpCards
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpDeleteLoading(false))
    }
}

const register = async (data: MPRegisterData) => {
    dispatch(setMpRegisterLoading(true))

    try {
        const details = await getMpToken()

        const registerResult = await MasterPassSDK.register({
            msisdn: details.user_phone,
            referenceNo: details.reference_code,
            sendSms: 'N',
            sendSmsLanguage: details.sms_language,
            rtaPan: data.rtaPan,
            expiryDate: data.expiryDate,
            accountAliasName: data.accountAliasName,
            token: details.token,
        })

        return registerResult
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpRegisterLoading(false))
    }
}

const validateTransaction = async (validationCode: string) => {
    dispatch(setMpValidateTransactionLoading(true))

    try {
        const details = await getMpToken()

        const validateTransactionResult =
            await MasterPassSDK.validateTransaction({
                validationCode: validationCode,
                token: details.token,
                referenceNo: details.reference_code,
                sendSms: 'N',
                sendSmsLanguage: details.sms_language,
            })

        const mpCards = await listCards()

        if (mpCards.length === 1) {
            await selectCard(mpCards[0])
        }

        return validateTransactionResult
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpValidateTransactionLoading(false))
    }
}

const selectCard = async (card: MasterPassCard) => {
    return await dispatch(
        depositApi.endpoints.masterpassSelect.initiate(card),
    ).unwrap()
}

const linkCardToClient = async () => {
    dispatch(setMpLinkLoading(true))

    try {
        const details = await getMpToken()

        const linkCardToClientResult = await MasterPassSDK.linkCardToClient({
            msisdn: details.user_phone,
            token: details.token,
            referenceNo: details.reference_code,
            sendSms: 'N',
            sendSmsLanguage: details.sms_language,
        })

        return linkCardToClientResult
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpLinkLoading(false))
    }
}

const unlink = async () => {
    dispatch(setMpUnLinkLoading(true))

    try {
        const unlinkResult = await dispatch(
            depositApi.endpoints.masterpassUnlink.initiate(undefined),
        ).unwrap()

        dispatch(setMpAccountType('unlinked'))

        dispatch(
            depositApi.util.updateQueryData(
                'userCardList',
                undefined,
                draft => ({
                    ...draft,
                    data: filter(
                        draft.data,
                        (card: UserCard) => card.type !== 'masterpass',
                    ),
                }),
            ),
        )

        return unlinkResult
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpUnLinkLoading(false))
    }
}

const resendOtp = async () => {
    dispatch(setMpResendOTPLoading(true))

    try {
        const details = await getMpToken()
        const resendOtpResult = await MasterPassSDK.resendOtp(
            details.sms_language,
        )

        return resendOtpResult
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpResendOTPLoading(false))
    }
}

const purchase = async (data: MPPurchaseData) => {
    let depositId: number | undefined

    dispatch(setMpPurchaseLoading(true))

    try {
        const mpInitResult = await dispatch(
            depositApi.endpoints.masterpassInit.initiate({
                wallet_id: data.walletId,
                amount: data.amount,
                card: data.card,
                installment_count: data.installmentCount,
            }),
        ).unwrap()

        depositId = mpInitResult.data.deposit_id

        if (mpInitResult.additional_data) {
            MasterPassSDK.setAdditionalParameters(mpInitResult.additional_data)
        }

        const purchaseResult = await MasterPassSDK.purchase({
            amount: mpInitResult.data.amount.toString(),
            listAccountName: data.card.Name,
            msisdn: mpInitResult.data.user_phone,
            referenceNo: mpInitResult.data.reference_code,
            sendSmsLanguage: mpInitResult.data.sms_language,
            token: mpInitResult.data.token,
            orderNo: mpInitResult.data.order_id,
            clientIp: mpInitResult.data.client_ip,
            paymentType: mpInitResult.data.payment_type,
            installmentCount: mpInitResult.data.installment_count,
        })

        return {
            initResult: mpInitResult,
            sdkResult: purchaseResult,
        }
    } catch (error: any) {
        if (typeof depositId === 'number') {
            fail(depositId, error.data)
        }

        return Promise.reject(error)
    } finally {
        dispatch(setMpPurchaseLoading(false))
    }
}

const directPurchase = async (data: MPDirectPurchaseData) => {
    let depositId: number | undefined

    try {
        const mpInitDirectResult = await dispatch(
            depositApi.endpoints.masterpassInitDirect.initiate({
                wallet_id: data.walletId,
                amount: data.amount,
                card: data.maskedRtaPan,
                installment_count: data.installmentCount,
            }),
        ).unwrap()

        depositId = mpInitDirectResult.data.deposit_id

        if (mpInitDirectResult.additional_data) {
            MasterPassSDK.setAdditionalParameters(
                mpInitDirectResult.additional_data,
            )
        }

        const purchaseResult = await MasterPassSDK.directPurchase({
            amount: mpInitDirectResult.data.amount.toString(),
            msisdn: mpInitDirectResult.data.user_phone,
            sendSmsLanguage: mpInitDirectResult.data.sms_language,
            token: mpInitDirectResult.data.token,
            orderNo: mpInitDirectResult.data.order_id,
            paymentType: mpInitDirectResult.data.payment_type,
            rtaPan: data.rtaPan,
            cvc: data.cvc,
            expiryDate: data.expiryDate,
            installmentCount: mpInitDirectResult.data.installment_count,
        })

        return {
            initResult: mpInitDirectResult,
            sdkResult: purchaseResult,
        }
    } catch (error: any) {
        if (typeof depositId === 'number') {
            fail(depositId, error.data)
        }

        return Promise.reject(error)
    }
}

const purchaseAndRegister = async (data: MPPurchaseAndRegisterData) => {
    let depositId: number | undefined

    try {
        const mpInitDirectResult = await dispatch(
            depositApi.endpoints.masterpassInitDirect.initiate({
                wallet_id: data.walletId,
                amount: data.amount,
                card: data.maskedRtaPan,
                installment_count: data.installmentCount,
            }),
        ).unwrap()

        depositId = mpInitDirectResult.data.deposit_id

        if (mpInitDirectResult.additional_data) {
            MasterPassSDK.setAdditionalParameters(
                mpInitDirectResult.additional_data,
            )
        }

        const purchaseAndRegisterResult =
            await MasterPassSDK.purchaseAndRegister({
                amount: mpInitDirectResult.data.amount.toString(),
                orderNo: mpInitDirectResult.data.order_id,
                paymentType: mpInitDirectResult.data.payment_type,
                rtaPan: data.rtaPan,
                cvc: data.cvc,
                expiryDate: data.expiryDate,
                accountAliasName: data.accountAliasName,
                msisdn: mpInitDirectResult.data.user_phone,
                referenceNo: mpInitDirectResult.data.reference_code,
                sendSms: 'N',
                sendSmsLanguage: mpInitDirectResult.data.sms_language,
                token: mpInitDirectResult.data.token,
                installmentCount: mpInitDirectResult.data.installment_count,
            })

        return {
            initResult: mpInitDirectResult,
            sdkResult: purchaseAndRegisterResult,
        }
    } catch (error: any) {
        if (typeof depositId === 'number') {
            fail(depositId, error.data)
        }

        return Promise.reject(error)
    }
}

const markOTP = async (depositId: number) => {
    try {
        const markOTPResult = await dispatch(
            depositApi.endpoints.masterpassMarkOTP.initiate({
                deposit_id: depositId,
            }),
        ).unwrap()

        return markOTPResult
    } catch (error) {
        return Promise.reject(error)
    }
}

const makeDeposit = async (data: MPMakeData) => {
    dispatch(setMpMakeLoading(true))

    try {
        const markOTPResult = await dispatch(
            depositApi.endpoints.masterpassMake.initiate({
                deposit_id: data.depositId,
                token: data.token,
            }),
        ).unwrap()

        return markOTPResult
    } catch (error) {
        return Promise.reject(error)
    } finally {
        dispatch(setMpMakeLoading(false))
    }
}

const syncSelectedCard = async (selected: UserCard) => {
    const mpCards = await listCards()

    const card = find(
        mpCards,
        item =>
            item.Name === selected.name && item.Value1 === selected.mask_pan,
    )

    if (card) return

    const firstMpCard = first(mpCards)

    if (!firstMpCard) return

    await selectCard(firstMpCard)
}

export default {
    getMpToken: getMpToken,
    fail: fail,
    checkMasterPass: checkMasterPass,
    listCards: listCards,
    deleteCards: deleteCards,
    register: register,
    validateTransaction: validateTransaction,
    selectCard: selectCard,
    linkCardToClient: linkCardToClient,
    unlink: unlink,
    resendOtp: resendOtp,
    purchase: purchase,
    directPurchase: directPurchase,
    purchaseAndRegister: purchaseAndRegister,
    markOTP: markOTP,
    makeDeposit: makeDeposit,
    syncSelectedCard: syncSelectedCard,
}
