import * as React from 'react'
import type { TopUpContainerLeftProps } from './Container.types'

const TopUpContainerLeft: React.FC<TopUpContainerLeftProps> = ({
    children,
}) => {
    return <div className="flex-grow">{children}</div>
}

export default TopUpContainerLeft
