import dayjs from 'dayjs'
import { groupBy, map } from 'lodash'
import type {
    GroupHistoryItemByDateFunction,
    HistorySection,
} from './AccountActivities.types'

export const groupHistoryByDate: GroupHistoryItemByDateFunction = list => {
    const groupedHistories = groupBy(list, item => {
        return dayjs(item.created_at).format('D MMMM').toLocaleUpperCase()
    })

    return map(groupedHistories, (data, title) => ({
        data,
        title,
    })) as HistorySection[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertArrayToObject = (array: any[]) => {
    const initialValue = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return array.reduce((obj: any, item: any) => {
        return {
            ...obj,
            [item.type]: item,
        }
    }, initialValue)
}
