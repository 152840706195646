import ReactDOM from 'react-dom/client'
import '@core/translations'
import { DayJSMiddlewares, ThemeMiddlewares } from '@core/middlewares'
import { App } from '@core/routes'
import { SentryConfig } from '@core/services'
import { Environment } from '@core/constants'
import '@core/assets/css/global.css'

DayJSMiddlewares.setLocale()
ThemeMiddlewares.SetTheme()

if (Environment.ENV === 'PROD') {
    SentryConfig()
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
