import type { MasterPassHelpQuestion } from './MasterPassHelp.types'

export const MasterPassHelpQuestions: MasterPassHelpQuestion[] = [
    {
        title: '@deposit.MasterPassHelp.question_card_title',
        message: '@deposit.MasterPassHelp.question_card_description',
    },
    {
        title: '@deposit.MasterPassHelp.question_time_title',
        message: '@deposit.MasterPassHelp.question_time_description',
    },
    {
        title: '@deposit.MasterPassHelp.question_number_title',
        message: '@deposit.MasterPassHelp.question_number_description',
    },
    {
        title: '@deposit.MasterPassHelp.question_do_what_title',
        message: '@deposit.MasterPassHelp.question_do_what_description',
    },
]

export const MasterPassHelpUrl = 'https://www.masterpassturkiye.com/login'
