import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { Icon } from '@components'
import { cn } from '@core/utils'

const Switch = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, checked, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={cn(
            'animate-enter peer inline-flex h-8 w-[52px] shrink-0 cursor-pointer items-center overflow-hidden rounded-full border-[1.6px] border-semantic-background-tertiary transition-all disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-transparent data-[state=checked]:bg-solid-brand-5 data-[state=unchecked]:bg-transparent',
            className,
        )}
        checked={checked}
        {...props}
        ref={ref}>
        <SwitchPrimitives.Thumb
            className={cn(
                'pointer-events-none block h-6 w-6 rounded-full bg-solid-core-white fill-solid-ink-9 ring-0 transition-transform data-[state=unchecked]:h-4 data-[state=unchecked]:w-4 data-[state=checked]:translate-x-[22px] data-[state=unchecked]:translate-x-1 data-[state=unchecked]:bg-solid-ink-7',
            )}>
            {checked ? <Icon name="check" size={24} /> : null}
        </SwitchPrimitives.Thumb>
    </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export default Switch
