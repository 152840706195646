import * as React from 'react'
// import { useTranslation } from 'react-i18next'
// import { Icon } from '@components'
import type { HeaderTopProps } from './Header.types'
// import HeaderNotifications from './HeaderNotification'

const HeaderTop: React.FC<HeaderTopProps> = ({ title }) => {
    // const { t } = useTranslation()

    return (
        <div>
            <div className="wallet-detail-header__top">
                <div className="wallet-detail-header__top-left">
                    <h2 className="uppercase">{title}</h2>
                    {/*<div className="wallet-detail-header__gift-point">*/}
                    {/*    <Icon name="security" size={16} color={color} />*/}
                    {/*    <span>*/}
                    {/*        {t('@wallet.WalletDetail.wallet_benefits_title')}*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                </div>
                {/*<div className="wallet-detail-header__top-right">*/}
                {/*    <HeaderNotifications />*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default HeaderTop
