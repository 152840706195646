import React from 'react'
import type { RowProps, RowStaticProperties } from './Row.types'
import RowDescription from './RowDescription'
import RowTitle from './RowTitle'

const Row: React.FC<RowProps> & RowStaticProperties = ({
    className,
    children,
}) => {
    return <div className={className}>{children}</div>
}

Row.Title = RowTitle
Row.Description = RowDescription

export default Row
