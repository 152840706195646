import * as React from 'react'
import type { AccountActivitiesSectionHeaderProps } from './AccountActivities.types'

const AccountActivitiesSectionHeader: React.FC<
    AccountActivitiesSectionHeaderProps
> = ({ title }) => {
    return (
        <div className="animate-enter pb-4 pt-7">
            <p className="animate-enter text-overline-md text-semantic-content-inkLight">
                {title}
            </p>
        </div>
    )
}
export default AccountActivitiesSectionHeader
