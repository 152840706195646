import { omit } from 'lodash'
import type { WalletDetailsResponse } from './WalletApi.types'

export default class WalletApiUtils {
    public static transformWalletDetailsResponse = (
        response: WalletDetailsResponse,
    ) => {
        return {
            data: {
                ...response.data,
                company: {
                    ...response.data.company,
                    deposit_methods: {
                        masterpass:
                            response.data.company.deposit_methods.masterpass,
                        ...omit(response.data.company.deposit_methods, [
                            'masterpass',
                        ]),
                    },
                },
            },
            meta: {
                ...response.meta,
            },
        }
    }
}
