import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimeField } from '@components'
import type { DateSectionProps } from './AccountActivitiesFilter.types'

const DateSection: React.FC<DateSectionProps> = ({
    date,
    label,
    message,
    onChangeValue,
}) => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <DateTimeField
                date={date}
                setDate={onChangeValue}
                InputProps={{
                    label: label,
                    placeholder: t(
                        '@wallet.AccountActivitiesFilter.date_time_place_holder',
                    ),
                    error: Boolean(message),
                    helperText: message,
                }}
            />
        </React.Fragment>
    )
}
export default DateSection
