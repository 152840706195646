import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@components'
import type { AuthMobileHeaderProps } from './Auth.types.ts'

const AuthMobileHeader: React.FC<AuthMobileHeaderProps> = ({
    fullName,
    logo,
    avatarUrl,
}) => {
    const { t } = useTranslation()

    return (
        <div className="header-top__mobile">
            <img src={logo} alt="logo" className="h-8" />
            <div className="flex shrink-0 gap-4">
                <div className="flex flex-col text-center">
                    <span className="text-body-sm-r text-semantic-content-inkLight">
                        {t('content_config.Sidebar.hello')}
                    </span>
                    <span className="text-body-lg-b text-semantic-content-ink">
                        {fullName}
                    </span>
                </div>
                <Avatar>
                    <Avatar.Image
                        className="h-10 w-10 rounded-full object-cover"
                        src={avatarUrl}
                    />
                    <Avatar.Fallback>{fullName[0]}</Avatar.Fallback>
                </Avatar>
            </div>
        </div>
    )
}

export default AuthMobileHeader
