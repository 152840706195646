import {
    createBrowserRouter,
    Navigate,
    type RouteObject,
} from 'react-router-dom'
import { Auth, Payment } from '@core/routes/Layout'
import { CampaignRouter } from '@campaign/router'
import { ErrorRouter } from '@common/router'
import { GuestRouter } from '@guest/router'
import { DepositRouter } from '@deposit/router'
import { MasterPass3D, Payment3D } from '@deposit/views'
import { PaymentRouter } from '@payment/router'
import { WalletRouter } from '@wallet/router'

const routes: RouteObject[] = [
    {
        children: [...GuestRouter], // This is the Guest component
    },
    {
        element: <Auth />,
        children: [...CampaignRouter, ...DepositRouter, ...WalletRouter], // This is the Auth component
    },
    {
        element: <MasterPass3D />,
        path: '/masterpass-3d',
    },
    {
        path: '/payment-3d',
        element: <Payment3D />,
    },

    {
        element: <Payment />,
        children: [...PaymentRouter],
    },
    {
        children: [...ErrorRouter],
    },
    {
        path: '*',
        element: <Navigate to="/404" replace />,
    },
]

export default routes

export const router = createBrowserRouter(routes)
