import { yup } from '@core/middlewares'

export const paymentRefundValidation = yup.object().shape({
    payment_id: yup.number().required(),
    reason: yup
        .object({
            label: yup.string().required(),
            value: yup.string().required(),
        })
        .required(),
    comment: yup.string().max(200).required(),
})
