export const IconSize = (size?: string) => {
    switch (size) {
        case 'tiny':
            return 12
        case 'small':
            return 16
        case 'medium':
            return 16
        case 'large':
            return 24
        case 'huge':
            return 24
        default:
            return 24
    }
}
