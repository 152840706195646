import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import { Toast, type ToastProps } from '@components'
import i18next from '@core/translations'
import type { CampaignDetailToastProps } from './CampaignDetail.types'
import { useTheme } from '@core/utils'

const CampaignDetailToast: React.FC<CampaignDetailToastProps> = ({
    campaignType,
    usageCount,
    multipleUsage,
    userUsed,
    remainderCount,
    campaignWon,
    processCount,
    pointAmount,
    currency,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const formattedEarnablePoint = React.useMemo(() => {
        return CurrencyFormatter.format(
            pointAmount,
            i18next.getLocale(),
            currency,
        )
    }, [currency, pointAmount])

    const formattedWonPoint = React.useMemo(() => {
        return CurrencyFormatter.format(
            campaignWon,
            i18next.getLocale(),
            currency,
        )
    }, [campaignWon, currency])

    const message = React.useMemo(() => {
        return campaignType === 'payment_count_gift'
            ? t('@campaign.CampaignDetail.payment_count_gift_toast_message', {
                  formattedEarnablePoint: formattedEarnablePoint,
                  remainderCount: remainderCount,
              })
            : !multipleUsage && userUsed
              ? t(
                    '@campaign.CampaignDetail.deposit_gift_disposable_toast_message',
                )
              : t('@campaign.CampaignDetail.deposit_gift_toast_message', {
                    usageCount: usageCount,
                })
    }, [
        campaignType,
        formattedEarnablePoint,
        multipleUsage,
        remainderCount,
        t,
        usageCount,
        userUsed,
    ])

    const ToastRightComponent = React.useCallback(
        (type: ToastProps['variant']) => {
            if (campaignType === 'payment_count_gift' && processCount)
                return (
                    <Toast.Progress
                        type={type}
                        className="flex flex-row-reverse items-center gap-2"
                        valueTextStyle={{
                            fontSize: '0.75rem',
                            color: theme.colors.semantic.content.inkInverse,
                        }}
                        value={processCount - remainderCount}
                        totalValue={processCount}>
                        {`${processCount - remainderCount}/${processCount}`}
                    </Toast.Progress>
                )

            return (
                <Toast.Action variant={type} className="shrink-0">
                    {t('@campaign.CampaignDetail.toast_message_chip_title', {
                        formattedWonPoint: formattedWonPoint,
                    })}
                </Toast.Action>
            )
        },
        [
            campaignType,
            formattedWonPoint,
            processCount,
            remainderCount,
            t,
            theme.colors.semantic.content.inkInverse,
        ],
    )

    return (
        <Toast
            as="div"
            message={message}
            LayoutRightComponent={ToastRightComponent}
        />
    )
}
export default CampaignDetailToast
