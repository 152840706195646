import * as React from 'react'
import { map } from 'lodash'
import type { PinInputProps } from './PinInput.types'
import { cn } from '@core/utils'
import './PinInput.css'

const PinInput: React.FC<PinInputProps> = ({
    value,
    inputLength = 6,
    onChangeValue,
    onSubmitValue,
    className,
    ...props
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const currentOtpIndex = React.useRef<number>(0)
    const [otp, setOtp] = React.useState<string[]>(
        new Array(inputLength).fill(''),
    )
    const [activeIndex, setActiveIndex] = React.useState<number>(0)

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target
            const newOtp = [...otp]
            newOtp[currentOtpIndex.current] = value.substring(value.length - 1)

            if (!value) setActiveIndex(currentOtpIndex.current - 1)
            if (value) setActiveIndex(currentOtpIndex.current + 1)

            setOtp(newOtp)
            onChangeValue?.(newOtp.join(''))
        },
        [otp, onChangeValue],
    )

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
            currentOtpIndex.current = index
            if (e.key === 'Backspace')
                setActiveIndex(currentOtpIndex.current - 1)
        },
        [],
    )

    React.useEffect(
        () => {
            if (value && value.length === inputLength) {
                const newOtp = value.split('')
                setOtp(newOtp)
                setActiveIndex(newOtp.length)
                if (onSubmitValue) onSubmitValue?.(value)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputLength, value],
    )

    React.useEffect(() => {
        inputRef.current?.focus()
    }, [activeIndex])

    return (
        <div className="flex gap-2">
            {map(otp, (_, index) => (
                <input
                    ref={index === activeIndex ? inputRef : null}
                    key={index}
                    data-state={otp[index] && 'filled'}
                    type="number"
                    placeholder="○"
                    className={cn('pin-input spin-button-none', className)}
                    value={otp[index]}
                    onChange={handleChange}
                    onKeyDown={e => handleKeyDown(e, index)}
                    {...props}
                />
            ))}
        </div>
    )
}

export default PinInput
