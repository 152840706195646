import type { StepperHeaderConstantsProps } from './AccountActivityDetail.types'

export const StepperHeaderConstants: StepperHeaderConstantsProps[] = [
    {
        key: 'AccountActivityDetail',
        title: '@wallet.AccountActivityDetail.content_header_title',
        description: '@wallet.AccountActivityDetail.content_header_description',
    },
    {
        key: 'PaymentRefund',
        title: '@payment.PaymentRefund.route_header_title',
        goBack: 'AccountActivityDetail',
    },
    {
        key: 'PaymentRefundEftPos',
        title: '@payment.PaymentRefundEftPos.route_header_title',
        goBack: 'AccountActivityDetail',
    },
]
