import * as React from 'react'
import { IconButton } from '@components'
import { cn } from '@core/utils'
import { useCarousel } from './Carousel.context'

const CarouselNext = React.forwardRef<
    HTMLButtonElement,
    React.ComponentProps<typeof IconButton>
>(({ className, variant = 'bg-blurred', size = 'small', ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel()

    return (
        <IconButton
            ref={ref}
            variant={variant}
            size={size}
            name="arrow-right"
            className={cn(
                'absolute h-8 w-8 rounded-full',
                orientation === 'horizontal'
                    ? '-right-12 top-1/2 -translate-y-1/2'
                    : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
                className,
            )}
            disabled={!canScrollNext}
            onClick={scrollNext}
            {...props}>
            <span className="sr-only">Next slide</span>
        </IconButton>
    )
})
CarouselNext.displayName = 'CarouselNext'

export default CarouselNext
