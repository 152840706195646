import * as React from 'react'
import { Icon } from '@components'
import type { HistoryItemProps } from './HistoryItem.types'
import { useContentConfig } from '@core/hooks'
import { useTheme } from '@core/utils'
import { cn } from '@core/utils'
import HistoryItemContent from './HistoryItemContent'
import './HistoryItem.css'

const HistoryItem: React.FC<HistoryItemProps> = ({
    as,
    type,
    subtitle,
    amount,
    point,
    currency,
    datetime,
    contentStyle,
    className,
    ...props
}) => {
    const content = useContentConfig()
    const { colors } = useTheme()
    const Comp = as || 'button'

    const item = React.useMemo(() => {
        return content.historyItem.types.find(element => element.key === type)
    }, [type, content])

    if (!item) return null

    return (
        <Comp
            className={cn(
                'history-item__container animate-enter group',
                className,
            )}
            {...props}>
            <div className="history-item__avatar">
                <Icon
                    name={item.icon}
                    size={24}
                    color={colors.semantic.content.inkMedium}
                />
            </div>
            <div className="history-item__content" style={contentStyle}>
                <HistoryItemContent
                    item={item}
                    subtitle={subtitle}
                    amount={amount}
                    point={point}
                    currency={currency}
                    datetime={datetime}
                />
            </div>
        </Comp>
    )
}

export default HistoryItem
