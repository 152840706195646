import { every } from 'lodash'
import store from '@core/store'
import type { BalanceCheckResponse } from '@deposit/api'
import depositApi from '@deposit/api'
import type { DefaultDepositAmount } from '@wallet/api'

export const getAmount = async (price: number) => {
    const balanceResult = await store
        .dispatch(depositApi.endpoints.balanceCheck.initiate({ price }))
        .unwrap()
    return balanceResult.data
}

export const checkDepositAmount = (
    defaultDepositAmounts: DefaultDepositAmount[],
    result: BalanceCheckResponse['data'],
): boolean => {
    if (result.point_usage.status)
        return every(
            defaultDepositAmounts,
            amountObj => amountObj.amount !== result.deposit_point_and_balance,
        )

    return every(
        defaultDepositAmounts,
        amountObj => amountObj.amount !== result.deposit_balance,
    )
}
