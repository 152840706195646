import * as React from 'react'
import type { BackgroundProps } from './Background.types'
import { LightenDarkenColor } from '@core/utils'

const Background: React.FC<BackgroundProps> = ({ color }) => {
    const light = React.useMemo(() => LightenDarkenColor(color, 40), [color])
    const dark = React.useMemo(() => LightenDarkenColor(color, -20), [color])

    return (
        <div
            className="absolute inset-0 -z-10 h-auto max-w-full overflow-hidden"
            style={{ background: color }}>
            <svg
                className="absolute bottom-0"
                viewBox="0 0 1280 832"
                fill="none">
                <g>
                    <rect width="100%" height="100%" fill={color} />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M761.62 403.285C726.893 368.558 670.589 368.558 635.862 403.285L126.055 913.093C91.3275 947.82 91.3275 1004.12 126.055 1038.85C160.782 1073.58 217.086 1073.58 251.813 1038.85L469.825 820.839C487.195 803.563 515.282 803.592 532.616 820.926C549.98 838.29 549.98 866.442 532.616 883.805L492.899 923.522L531.637 962.26L835.129 658.769L796.391 620.031L784.886 631.536C767.523 648.9 739.371 648.9 722.007 631.536C704.643 614.172 704.643 586.021 722.007 568.657L733.512 557.152L733.512 557.152L761.62 529.044C796.347 494.316 796.347 438.013 761.62 403.285Z"
                        fill={light}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1360.73 432.517C1395.46 397.789 1451.76 397.79 1486.49 432.517C1521.22 467.244 1521.22 523.548 1486.49 558.275L976.157 1068.61C941.43 1103.33 885.126 1103.33 850.399 1068.61C815.672 1033.88 815.671 977.575 850.398 942.848L869.724 923.522L847.135 900.933C845.15 897.304 842.621 893.893 839.547 890.819C836.474 887.746 833.063 885.217 829.433 883.231L806.845 860.643L628.637 1038.85C593.91 1073.58 537.606 1073.58 502.879 1038.85C468.152 1004.12 468.152 947.819 502.879 913.092L955.379 460.592C990.106 425.865 1046.41 425.865 1081.14 460.592C1115.86 495.32 1115.86 551.623 1081.14 586.351L1041.52 625.971L948.317 719.171C930.953 736.535 930.953 764.687 948.317 782.05C965.655 799.389 993.752 799.414 1011.12 782.125L1360.73 432.517Z"
                        fill={light}
                    />
                    <circle
                        cx="1177.4"
                        cy="741.4"
                        r="48.3663"
                        transform="rotate(45 1177.4 741.4)"
                        fill={color}
                    />
                    <ellipse
                        cx="210.036"
                        cy="434.96"
                        rx="33.3466"
                        ry="33.3466"
                        fill={light}
                    />
                    <ellipse cx="982" cy="747" rx="16" ry="16" fill={light} />
                    <rect
                        width="149.853"
                        height="435.618"
                        rx="74.9263"
                        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 217.99 535.924)"
                        fill={light}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M231.169 644.63L231.373 644.834L-21.8813 898.088L-83.0273 836.942L92.8248 661.09C79.3724 678.061 80.4881 702.797 96.1719 718.481C113.057 735.366 140.433 735.366 157.318 718.481L231.169 644.63Z"
                        fill={light}
                    />
                    <rect
                        x="249.256"
                        y="552.502"
                        width="168.321"
                        height="565.305"
                        rx="84.1607"
                        transform="rotate(45 249.256 552.502)"
                        fill={dark}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M302.03 737.771L302.03 737.771L-22.471 1062.27L96.5502 1181.29L403.077 874.766C370.203 907.544 316.982 907.514 284.144 874.677C251.278 841.81 251.278 788.523 284.144 755.656L302.03 737.771Z"
                        fill={dark}
                    />
                </g>
            </svg>
        </div>
    )
}

export default React.memo(Background)
