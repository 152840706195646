import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Sheet } from '@components'
import type {
    ActiveStepsProps,
    StepperHeaderProps,
} from './AccountActivityDetail.types'
import { StepperHeaderConstants } from './Stepper.constants.ts'

const StepperHeader: React.FC<StepperHeaderProps> = ({
    activeStep,
    setActiveStep,
}) => {
    const { t } = useTranslation()

    const header = React.useMemo(
        () => StepperHeaderConstants.find(h => h.key === activeStep),
        [activeStep],
    )

    if (!header) return null

    return (
        <Sheet.Header>
            <div
                data-layout={header.goBack ? 'flex' : 'block'}
                className="data-[layout=flex]:flex data-[layout=flex]:items-center data-[layout=flex]:gap-4">
                {header.goBack ? (
                    <IconButton
                        name="arrow-left-bold"
                        variant="ink-tonal"
                        size="small"
                        onClick={() =>
                            setActiveStep(header.goBack as ActiveStepsProps)
                        }
                    />
                ) : null}
                <Sheet.Title>{t(header.title)}</Sheet.Title>
            </div>
            {header.description && (
                <Sheet.Description>{t(header.description)}</Sheet.Description>
            )}
        </Sheet.Header>
    )
}

export default React.memo(StepperHeader)
