import * as React from 'react'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cn } from '@core/utils'

const SheetTitle = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Title
        ref={ref}
        className={cn(
            'w-10/12 !text-display-lg-b text-semantic-content-ink',
            className,
        )}
        {...props}
    />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

export default SheetTitle
