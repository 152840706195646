import type { RouteObject } from 'react-router-dom'
import i18n from '@core/translations'
import { NotFound } from '@common/views'

export const ErrorRouter: RouteObject[] = [
    {
        path: '/401',
        element: (
            <NotFound
                title={i18n.t('@common.401.title')}
                description={i18n.t('@common.401.description')}
                errorMessages={i18n.t('@common.401.errorMessages')}
            />
        ),
    },
    {
        path: '/404',
        element: (
            <NotFound
                title={i18n.t('@common.404.title')}
                description={i18n.t('@common.404.description')}
                errorMessages={i18n.t('@common.404.errorMessages')}
                actions={[
                    {
                        children: i18n.t('@common.404.actionsButton'),
                        onClick: () => (window.location.href = '/'),
                    },
                ]}
            />
        ),
    },
]
