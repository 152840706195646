import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from '@components'
import { TopUpDepositCard } from '@deposit/components'
import type { PaymentMethodSectionProps } from './TopUp.types'

const DepositMethodSection: React.FC<PaymentMethodSectionProps> = ({
    walletId,
    visible,
    companyId,
}) => {
    const { t } = useTranslation()

    if (!visible || companyId == 222) return null

    return (
        <Row className="mt-12 space-y-4">
            <Row.Title
                title={t('@deposit.TopUp.payment_method_section_title')}
            />
            <TopUpDepositCard walletId={walletId} />
        </Row>
    )
}

export default DepositMethodSection
