import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { HistoryItem } from '@components'
import type { RefundSectionItemProps } from './AccountActivityDetail.types'
import RefundSectionItemInfo from './RefundSectionItemInfo'

const RefundSectionItem: React.FC<RefundSectionItemProps> = ({
    complete,
    status,
    type,
    referenceCode,
    amount,
    currency,
    date,
    reason,
    userComment,
    companyComment,
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-4">
            <HistoryItem
                as="div"
                className="px-0 hover:bg-transparent"
                contentStyle={{ borderBottom: 'none' }}
                type={
                    !complete
                        ? status === 'close'
                            ? 'close'
                            : 'approve'
                        : type === 'capture'
                          ? 'payment_refund'
                          : 'deposit_refund'
                }
                subtitle={`${t('@wallet.AccountActivityDetail.reference_number')}:${referenceCode}`}
                amount={amount}
                currency={currency}
                datetime={dayjs(date).toDate()}
            />
            <div className="space-y-[10px] rounded-[13px] bg-semantic-background-primary p-4">
                <RefundSectionItemInfo
                    title={`${t('@wallet.AccountActivityDetail.refund_reason')}: `}
                    comment={reason}
                />
                {userComment ? (
                    <RefundSectionItemInfo
                        title={`${t('@wallet.AccountActivityDetail.customer_comment')}: `}
                        comment={userComment}
                    />
                ) : null}
                {companyComment ? (
                    <RefundSectionItemInfo
                        title={`${t('@wallet.AccountActivityDetail.company_comment')}: `}
                        comment={companyComment}
                    />
                ) : null}
            </div>
        </div>
    )
}
export default RefundSectionItem
