import * as React from 'react'
import depositApi, { type CardType } from '@deposit/api'
import { DepositMethodsService } from '@deposit/services'

const useSelectedCard = (type: CardType) => {
    const cardListQuery = depositApi.useUserCardListQuery(undefined)

    const selected = React.useMemo(() => {
        if (!cardListQuery.data) return
        return DepositMethodsService.getSelectedCard(
            cardListQuery.data.data,
            type,
        )
    }, [cardListQuery.data, type])

    return {
        card: selected,
        ...cardListQuery,
    }
}

export default useSelectedCard
