import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { SocketService } from '@core/services'
import paymentApi, { type PaymentMinimal } from '@payment/api'

const useGenerateQREvents = (referenceCode: string | undefined) => {
    const navigate = useNavigate()

    const [qrStatusQuery] = paymentApi.useLazyQrStatusQuery()

    React.useEffect(() => {
        const connected = SocketService.getState() === 'connected'

        let attempt = 0
        let interval: ReturnType<typeof setInterval> | undefined

        if (!referenceCode) return

        const polling = () => {
            interval = setInterval(async () => {
                if (attempt >= 30) return clearInterval(interval)

                attempt++

                const result = await qrStatusQuery({
                    qr_content: referenceCode,
                }).unwrap()

                if (result.data.status !== 'appointed') return

                if (result.data.type === 'payment') {
                    navigate('/capture', {
                        state: {
                            payment: result.data.resource as PaymentMinimal,
                            wallet: result.data.wallet,
                        },
                    })
                }
            }, 3000)
        }

        setTimeout(polling, connected ? 5000 : 0)

        return () => {
            attempt = 30
            interval = undefined
            clearInterval(interval)
        }
    }, [navigate, qrStatusQuery, referenceCode])
}

export default useGenerateQREvents
