import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import { Tooltip } from '@components'
import i18n from '@core/translations'
import type { RewardChartTableListItemProps } from './RewardChart.types'
import { useWindowSize } from '@core/hooks'
import { RewardChartTableListItemSizes } from './RewardChart.constants'

const RewardChartTableListItem: React.FC<
    RewardChartTableListItemProps
> = props => {
    const { balance, color, totalBalance, currency } = props
    const locale = i18n.getLocale()
    const window = useWindowSize()

    const RewardChartSize = RewardChartTableListItemSizes({
        width: window.width,
    })

    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const formattedPoint = React.useMemo(() => {
        return CurrencyFormatter.format(balance, locale, currency)
    }, [balance, locale, currency])

    return (
        <React.Fragment>
            <Tooltip.Provider delayDuration={0} skipDelayDuration={500}>
                <Tooltip open={isOpen} onOpenChange={setIsOpen}>
                    <Tooltip.Trigger asChild onClick={() => setIsOpen(!isOpen)}>
                        <div
                            className="reward-chart-table-list-item"
                            style={{
                                width: RewardChartSize.width,
                                height: RewardChartSize.height,
                            }}>
                            <div
                                className="reward-chart-table-list-item__balance"
                                style={{
                                    width: RewardChartSize.width,
                                    height:
                                        (balance / totalBalance) *
                                        RewardChartSize.height,
                                    backgroundColor: color,
                                }}
                            />
                        </div>
                    </Tooltip.Trigger>
                    <Tooltip.Content sideOffset={window.width > 1024 ? 0 : -20}>
                        {formattedPoint}
                    </Tooltip.Content>
                </Tooltip>
            </Tooltip.Provider>
        </React.Fragment>
    )
}

export default RewardChartTableListItem
