import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Button, IconButton, Row, Sheet } from '@components'
import type { MasterPassHelpProps } from './MasterPassHelp.types'
import {
    MasterPassHelpQuestions,
    MasterPassHelpUrl,
} from './MasterPassHelp.constants'

const MasterPassHelp: React.FC<MasterPassHelpProps> = ({ setView }) => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <Sheet.Header>
                <div className="flex items-center gap-4">
                    <IconButton
                        className="flex-shrink-0"
                        name="arrow-left-bold"
                        variant="ink-tonal"
                        size="small"
                        onClick={() => setView('otp')}
                    />
                    <Sheet.Title>
                        {t('@deposit.MasterPassHelp.route_header_title')}
                    </Sheet.Title>
                </div>
            </Sheet.Header>
            <div className="mb-9">
                {map(MasterPassHelpQuestions, (question, index) => (
                    <Row.Description
                        key={index}
                        className="py-6"
                        title={t(question.title)}
                        message={t(question.message)}
                    />
                ))}
            </div>
            <Button
                className="w-full"
                children={t('@deposit.MasterPassHelp.action_button_help')}
                onClick={() => window.open(MasterPassHelpUrl, '_blank')}
            />
        </React.Fragment>
    )
}
export default MasterPassHelp
