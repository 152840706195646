import * as React from 'react'
import { Badge } from '@components'
import type { ToastChipProps } from './Toast.types'
import { getToastActionType } from './Toast.utils'

const ToastAction: React.FC<ToastChipProps> = ({ variant, ...props }) => {
    return (
        <Badge variant={getToastActionType(variant)} size="small" {...props} />
    )
}

ToastAction.displayName = 'Toast.Chip'

export default ToastAction
