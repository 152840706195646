import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DepositCard } from '@components'
import { useAppSelector } from '@core/store'
import { useMasterPassLink } from '@deposit/hooks'
import { useSelectedCard } from '@deposit/hooks'
import { MasterPassService } from '@deposit/services'
import type { MasterPassDepositCardProps } from './MasterPassDepositCard.types'
import { DepositMethodsConstants } from '@deposit/constants'

const MasterPassDepositCard: React.FC<MasterPassDepositCardProps> = ({
    setOpen,
    ...props
}) => {
    const { t } = useTranslation()
    const MasterPassLink = useMasterPassLink()

    const selected = useSelectedCard('masterpass')

    const { mpAccountType, mpAccountLoading } = useAppSelector(
        state => state.depositSlice,
    )

    React.useEffect(() => {
        if (mpAccountType !== 'unknown') return
        MasterPassService.checkMasterPass()
    }, [mpAccountType])

    React.useEffect(() => {
        if (mpAccountType !== 'registered' || !selected.card) return
        MasterPassService.syncSelectedCard(selected.card)
    }, [mpAccountType, selected.card])

    if ((mpAccountLoading || selected.isLoading) && !selected.card)
        return <DepositCard.Loader />

    if (selected.isFetching && selected.card) return <DepositCard.Loader />

    if (mpAccountType === 'not-user')
        return (
            <DepositCard
                avatarImageSource={DepositMethodsConstants.Logos.masterpass}
                title="MasterPass"
                description={t(
                    '@deposit.MasterPassDepositCard.register_description',
                )}
                {...props}
            />
        )

    if (mpAccountType === 'unlinked' || !selected.card)
        return (
            <DepositCard
                avatarImageSource={DepositMethodsConstants.Logos.masterpass}
                title="MasterPass"
                description={t(
                    '@deposit.MasterPassDepositCard.link_description',
                )}
                containerProps={{
                    className: 'cursor-pointer',
                    onClick:
                        mpAccountType === 'unlinked'
                            ? MasterPassLink
                            : () => setOpen(true),
                }}
            />
        )

    return (
        <DepositCard
            avatarImageSource={DepositMethodsConstants.Logos.masterpass}
            title={selected.card.name}
            description={selected.card.mask_pan}
            {...props}
        />
    )
}

export default MasterPassDepositCard
