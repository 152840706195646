/* eslint-disable */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormatter } from '@macellan/formatter'
import i18n from '@core/translations'
import { SocketService } from '@core/services'
import { useAppSelector } from '@core/store'
import { useNotification } from '@core/hooks'
import paymentApi, { type RefundMinimal } from '@payment/api'

export type QrErrorType = 'qr_expired' | 'no_wallet'

export interface QrErrorResponse {
  type: QrErrorType
  message: string
}

const useSocketListener = () => {
  const { t } = useTranslation()
  const { toast, alert } = useNotification()
  const user = useAppSelector(state => state.baseSlice.me)

  const [connected, setConnected] = React.useState<boolean>(false)

  const [refundQrReply] = paymentApi.useRefundQrReplyMutation()

  const handleRefundQrReply = React.useCallback(
    async (reply: boolean, id: number) => {
      await refundQrReply({ request_id: id, reply: reply }).unwrap()

      reply
        ? toast({
            variant: 'success',
            message: t('socket_context.refund_request_event.alert_action_submit_toast'),
            icon: 'check-circle',
            duration: 5000,
          })
        : toast({
            variant: 'error',
            message: t('socket_context.refund_request_event.alert_action_reject_toast'),
            icon: 'warning',
            duration: 5000,
          })
    },
    [refundQrReply, toast],
  )

  const listen = React.useCallback(() => {
    const echo = SocketService.getInstance()
    const channel = echo?.private(`users.${user.id}`)

    if (!channel) return

    channel.error((error: any) => {
      if (error.status === 403) {
        SocketService.connect()
      }
    })

    channel.listen('.refund_request.event', async (event: RefundMinimal) => {
      const showAlert = () =>
        alert({
          title: t('socket_context.refund_request_event.alert_title'),
          message: t('socket_context.refund_request_event.alert_message', {
            amount: CurrencyFormatter.format(event.amount, i18n.getLocale(), event.company.currency),
          }),
          icon: 'info',
          actions: [
            {
              children: t('socket_context.refund_request_event.alert_action_submit'),
              onClick: async () => {
                await handleRefundQrReply(true, event.id)
              },
            },
            {
              variant: 'secondary',
              children: t('socket_context.refund_request_event.alert_action_reject'),
              onClick: async () => {
                await handleRefundQrReply(false, event.id)
              },
            },
          ],
        })

      return setTimeout(showAlert, 750)
    })

    channel.listen('.qr.error.event', async (event: QrErrorResponse) => {
      toast({ icon: 'warning', message: event.message, duration: 5000 })
    })
  }, [handleRefundQrReply, user.id, alert, toast, t])

  React.useEffect(() => {
    if (!connected) {
      SocketService.connect()
      setConnected(true)
    }

    return () => {
      SocketService.disconnect()
      setConnected(false)
    }
  }, [])

  React.useEffect(() => {
    if (!connected || !user.id) return
    listen()
  }, [connected, listen, user.id])
}

export default useSocketListener
