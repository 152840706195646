import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit'
import { values } from 'lodash'
import { emitter, UrlUtils } from '@core/utils'

// eslint-disable-next-line no-unused-vars
const handleError: Middleware = _store => next => action => {
    if (isRejectedWithValue(action)) {
        let message: string = action?.payload?.data?.message

        if (action.payload.status === 422) {
            const errors = action.payload.data.errors
            const errorMessages = values(errors)
            if (errorMessages?.[0]?.[0]) message = errorMessages[0][0]
        }

        if (action.payload.status === 401)
            return (window.location.href = '/401')

        const ignored = UrlUtils.checkIsEndpointIgnored(
            action.meta.baseQueryMeta.request.url,
            action.payload.status,
        )

        if (!ignored) {
            emitter.emit('addToast', {
                variant: 'error',
                message: message,
                icon: 'warning',
                duration: 5000,
            })
        }
    }
    return next(action)
}

export default {
    handleError: handleError,
}
