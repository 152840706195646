import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Badge } from '@components'
import { AccountActivitiesSectionHeader } from '@wallet/views'
import type { CategorySectionProps } from './AccountActivitiesFilter.types'
import { Categories } from './AccountActivitiesFilter.constants'

const CategorySection: React.FC<CategorySectionProps> = ({
    category,
    handleOnClick,
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-2">
            <AccountActivitiesSectionHeader
                title={t('@wallet.AccountActivitiesFilter.categories')}
            />
            <div className="flex flex-wrap gap-2">
                {map(Categories, (item, index) => (
                    <Badge
                        key={item.type + index.toString()}
                        variant={
                            item.type === category?.type
                                ? 'brand-tonal'
                                : 'outlined'
                        }
                        children={t(
                            `@wallet.AccountActivitiesFilter.${item.type}`,
                        )}
                        style={{ borderWidth: 1 }}
                        size="medium"
                        onClick={() => handleOnClick(item)}
                    />
                ))}
            </div>
        </div>
    )
}
export default CategorySection
