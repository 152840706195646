import React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge } from '@components'
import i18n from '@core/translations'
import { CustomAmountField } from '@deposit/components'
import walletApi from '@wallet/api'
import type { DepositChipsProps } from './DepositChips.types'
import { cn } from '@core/utils'

const DepositChips: React.FC<DepositChipsProps> = ({
    walletId,
    amount,
    isVisibleCustomAmount,
    isVisibleFooterComponent = true,
    setIsCustomAmount,
    onChangeAmount,
    className,
    ...props
}) => {
    const { t } = useTranslation()

    const walletDetailsQuery = walletApi.useWalletDetailsQuery({
        wallet_id: walletId,
    })

    const wallet = React.useMemo(() => {
        return walletDetailsQuery.data?.data
    }, [walletDetailsQuery.data?.data])

    const handleOnChangeValue = React.useCallback(
        (amount: number) => {
            if (isVisibleCustomAmount) {
                setIsCustomAmount(false)
            }

            onChangeAmount(amount)
        },
        [isVisibleCustomAmount, onChangeAmount, setIsCustomAmount],
    )

    React.useEffect(() => {
        if (isVisibleCustomAmount)
            return setIsCustomAmount(isVisibleCustomAmount)
    }, [isVisibleCustomAmount, setIsCustomAmount])

    if (!wallet) return null

    return (
        <div>
            <div
                className={cn('flex flex-wrap gap-2 lg:gap-4', className)}
                {...props}>
                {map(
                    wallet.company.settings.default_deposit_amounts,
                    (item, index) => (
                        <Badge
                            key={index}
                            data-state={item.amount === amount}
                            children={CurrencyFormatter.format(
                                item.amount,
                                i18n.getLocale(),
                                wallet.company.currency,
                            )}
                            variant={
                                !isVisibleCustomAmount && item.amount === amount
                                    ? 'brand-tonal'
                                    : 'outlined'
                            }
                            className="shrink-0 px-6 data-[state=false]:hover:!bg-semantic-background-primary"
                            onClick={() => handleOnChangeValue(item.amount)}
                        />
                    ),
                )}
                {isVisibleFooterComponent ? (
                    <Badge
                        variant={
                            isVisibleCustomAmount ? 'brand-tonal' : 'outlined'
                        }
                        className="shrink-0 px-6 hover:!bg-semantic-background-primary"
                        children={t('@deposit.TopUp.deposit_chips_other_label')}
                        onClick={() => {
                            setIsCustomAmount(!isVisibleCustomAmount)
                            onChangeAmount(0)
                        }}
                    />
                ) : null}
            </div>
            <CustomAmountField
                amount={amount}
                currency={wallet.company.currency}
                maximumDeposit={wallet.company.settings.maximum_deposit}
                minimumDeposit={wallet.company.settings.minimum_deposit}
                isVisible={isVisibleCustomAmount}
                onChangeAmount={onChangeAmount}
            />
        </div>
    )
}

export default DepositChips
