import * as React from 'react'
import * as ToastPrimitives from '@radix-ui/react-toast'
import type { ToastViewportProps } from './Toast.types'
import { cn } from '@core/utils'

const ToastViewport = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Viewport>,
    ToastViewportProps
>(({ isViewing, className, ...props }, ref) => {
    if (!isViewing) return null

    return (
        <ToastPrimitives.Viewport
            ref={ref}
            className={cn(
                'fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse gap-2 p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[435px]',
                className,
            )}
            {...props}
        />
    )
})
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

export default ToastViewport
