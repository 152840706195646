export default {
    401: {
        title: 'Yetkisiz Erişim',
        description: 'Bu sayfaya erişim yetkiniz bulunmamaktadır.',
        errorMessages: 'HTTP ERROR 401',
    },
    404: {
        title: 'Tüh! Aradığınız sayfa burada değil',
        description:
            'Aradığınız sayfa sitemizden kaldırılmış ya da adresi değiştirilmiş olabilir.',
        errorMessages: 'HTTP ERROR 404',
        actionsButton: 'Anasayfaya Git',
    },
}
