import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { map } from 'lodash'
import { EmptySheet, HistoryItem, Sheet } from '@components'
import walletApi from '@wallet/api'
import {
    AccountActivitiesSectionHeader,
    AccountActivityDetail,
    groupHistoryByDate,
} from '@wallet/views'
import type { AccountActivitySectionListProps } from './AccountActivityList.types'
import { usePagination } from '@core/hooks'
import { useNotification } from '@core/hooks'
import AccountActivityLoader from './AccountActivityLoader.tsx'

const AccountActivitySectionList: React.FC<AccountActivitySectionListProps> = ({
    config,
}) => {
    const { t } = useTranslation()
    const { notifications } = useNotification()

    const [openSheetId, setOpenSheetId] = React.useState<number | null>(null)

    const paginate = usePagination(walletApi.useBalanceHistoryListQuery, {
        ...config,
    })

    const segmentedHistories = React.useMemo(() => {
        if (!paginate.data) return []
        return groupHistoryByDate(paginate.data)
    }, [paginate.data])

    const hasMore = React.useMemo(() => {
        return paginate.meta?.paginate.has_more
    }, [paginate.meta?.paginate.has_more])

    React.useEffect(() => {
        if (notifications.some(({ type }) => type === 'alert'))
            return setOpenSheetId(null)
    }, [notifications])

    if (paginate.query.isLoading)
        return <AccountActivityLoader className="container pb-8" limit={7} />

    if (!paginate.data || !paginate.meta) return null

    return (
        <InfiniteScroll
            dataLength={paginate.data.length}
            hasMore={hasMore}
            next={paginate.more}
            loader={<AccountActivityLoader limit={7} />}
            className="container">
            {map(segmentedHistories, (section, key) => {
                if (
                    paginate.query.isFetching &&
                    paginate.query.originalArgs?.page === 1
                )
                    return null

                return (
                    <React.Fragment key={key}>
                        <AccountActivitiesSectionHeader title={section.title} />
                        {map(section.data, item => (
                            <Sheet
                                key={item.id}
                                open={openSheetId === item.id}
                                onOpenChange={isOpen =>
                                    setOpenSheetId(isOpen ? item.id : null)
                                }>
                                <Sheet.Trigger className="w-full" asChild>
                                    <div
                                        onClick={() => setOpenSheetId(item.id)}>
                                        <HistoryItem
                                            type={item.type}
                                            amount={item.amount}
                                            point={item.total_earned_points}
                                            currency={item.company.currency}
                                            datetime={dayjs(
                                                item.created_at,
                                            ).toDate()}
                                        />
                                    </div>
                                </Sheet.Trigger>
                                <Sheet.Content className="overflow-y-scroll">
                                    <AccountActivityDetail id={item.id} />
                                </Sheet.Content>
                            </Sheet>
                        ))}
                    </React.Fragment>
                )
            })}
            {paginate.query.isFetching && <AccountActivityLoader limit={7} />}
            {paginate.data.length === 0 && !paginate.query.isFetching && (
                <EmptySheet
                    icon="info"
                    title={t(
                        '@wallet.AccountActivitySectionList.empty_sheet_title',
                    )}
                    description={t(
                        '@wallet.AccountActivitySectionList.empty_sheet_description',
                    )}
                    className="max-w-full pb-8 pt-16"
                />
            )}
        </InfiniteScroll>
    )
}
export default AccountActivitySectionList
