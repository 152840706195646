import * as React from 'react'
import { IconButton } from '@components'
import type { InstallmentCardFooterProps } from './InstallmentCard.types'

const InstallmentCardFooter: React.FC<InstallmentCardFooterProps> = ({
    canOpen,
    isOpened,
    buttonText,
    ...props
}) => {
    return (
        <div
            className="z-10 flex cursor-pointer gap-2 bg-white py-5"
            {...props}>
            <span className="text-body-lg-b text-semantic-content-ink">
                {!isOpened ? buttonText.more : buttonText.hide}
            </span>
            {canOpen ? (
                <IconButton
                    variant="primary-tonal"
                    size="tiny"
                    name={!isOpened ? 'caret-down' : 'caret-up'}
                />
            ) : null}
        </div>
    )
}
export default InstallmentCardFooter
