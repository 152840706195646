import CampaignTranslations from '@campaign/translations'
import CommonTranslations from '@common/translations'
import GuestTranslations from '@guest/translations'
import DepositTranslations from '@deposit/translations'
import PaymentTranslations from '@payment/translations'
import WalletTranslations from '@wallet/translations'

export default {
    '@campaign': CampaignTranslations.en,
    '@guest': GuestTranslations.en,
    '@deposit': DepositTranslations.en,
    '@payment': PaymentTranslations.en,
    '@wallet': WalletTranslations.en,
    '@common': CommonTranslations.en,
    'socket_context': {
        refund_request_event: {
            alert_title: 'Refund Request',
            alert_message: 'Do you approve the refund of {{amount}}?',
            alert_action_submit: 'Accept',
            alert_action_reject: 'Reject',
            alert_action_submit_toast: 'Refund successfully processed',
            alert_action_reject_toast: 'Refund process canceled',
        },
        payment_event: {
            toast_error_message: 'Payment Failed',
            toast_no_balance_message: 'Insufficient balance for payment',
            toast_expire_message: 'Payment has expired',
            toast_device_offline_message: 'Connection could not be established',
            toast_callback_no: 'Device is not responding',
            toast_discount_point_change:
                'Transaction canceled due to changes in the discount',
        },
    },
    'context': {
        TopUpProvider: {
            alert_success_title: 'Balance loading was successful',
            toast_success_message:
                'You can check wallet transactions to view the transaction details',
            alert_success_action: 'Close',
        },
    },
    'content_config': {
        Alert: {
            close_timer: 'Will close in {{time}} sec.',
        },
        Sidebar: {
            hello: 'Hello',
            my_transactions: 'MY TRANSACTIONS',
            return_to_site: 'Return to Site',
            wallet: 'Wallet',
            account_activity: 'Account Activity',
            campaigns: 'Campaigns',
        },
        Footer: {
            about_us: 'What is Macellan Wallet?',
            customer_service: 'Customer Service & Contact',
            faq: 'Frequently Asked Questions',
            copyright: `Macellan SuperApp © ${new Date().getFullYear()}. All rights reserved.`,
        },
        CampaignItem: {
            details_button_text: 'Details',
            disabled_button_text: 'Used',
            remaining_time: 'Remaining Time',
            day: 'Day',
            point_suffix: 'points gift',
        },
        HistoryItem: {
            refund: 'Cancellation / Refund',
            topUp: 'Balance Load',
            capture: 'Balance Spending',
            refund_approval: 'Refund Approval Pending',
            refund_reject: 'Refund Rejected',
        },
        RewardChart: {
            table_type_title: 'LOAD AND EARN',
            progress_type_title: 'SPEND AND EARN',
            progress_type_description: 'spending remaining',
            table_type_description:
                'Total points earned for using Macellan SuperApp',
        },
        WalletCard: {
            placeholders_point: 'Points',
        },
    },
    'hooks': {
        useTopUp: {
            message: 'Add a payment method.',
        },
    },
    'validation': {
        error_message: {
            /* Mixed Validation Messages */
            mixed_required: 'This field is required.',
            mixed_default: 'The entered value is not of the correct type',
            mixed_one_of: 'You entered an invalid value',
            /* String Validation Messages */
            string_length: 'This field must be {{length}} characters long',
            string_min: 'This field must be at least {{min}} characters long',
            string_max: 'This field must be at most {{max}} characters long',
            string_email: 'The entered value must be in email format',
            string_matches: 'Enter a value in the correct format',
            string_url: 'The entered value must be in URL format',
            /* Number Validation Messages */
            number_min: 'The entered number cannot be less than {{min}}',
            number_max: 'The entered number cannot be greater than {{max}}',
            number_less_than:
                'The entered number must be greater than {{less}}',
            number_more_than: 'The entered number must be less than {{more}}',
            number_positive: 'The entered value must be greater than zero',
            number_negative: 'The entered value must be less than zero',
            number_integer: 'The entered value must be an integer',
            /* Date Validation Messages */
            date_min: 'The entered date cannot be earlier than {{min}}',
            date_max: 'The entered date cannot be later than {{max}}',
            /* Array Validation Messages */
            array_length: 'The specified number of elements must be {{length}}',
            array_min:
                'The specified number of elements must be at least {{min}}',
            array_max:
                'The specified number of elements must be at most {{max}}',
        },
    },
}
