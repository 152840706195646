import * as React from 'react'
import { map } from 'lodash'
import { DetailSection } from '@components'
import type { DetailSectionProps } from './AccountActivityDetail.types'

const DetailSections: React.FC<DetailSectionProps> = ({ details }) => {
    if (!details) return null

    return (
        <React.Fragment>
            {map(details, (item, index) => (
                <DetailSection
                    key={index}
                    title={item.title}
                    data={item.sections}
                />
            ))}
        </React.Fragment>
    )
}
export default DetailSections
