import * as React from 'react'
import { Carousel, RewardChart } from '@components'
import type { RewardTopUpChartProps } from './CampaignRewardList.types'

const RewardTopUpCharts: React.FC<RewardTopUpChartProps> = ({
    data,
    color,
    currency,
}) => {
    if (!data) return null

    return (
        <Carousel.Item className="basis-full md:basis-1/2 lg:basis-full xl:basis-1/2">
            <RewardChart.Table
                amount={data.total_of_last_earned_deposit_gift_point_amounts}
                amountList={data.last_earned_deposit_gift_point_amounts}
                color={color}
                currency={currency}
            />
        </Carousel.Item>
    )
}

export default RewardTopUpCharts
