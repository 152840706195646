import type React from 'react'
import { truncate } from 'lodash'

export const shouldShowFullContent = (
    content: string,
    limitContent: number,
) => {
    return content.length <= limitContent
}

export const getDisplayedContent = (
    content: string,
    limitContent: number,
    showMore: boolean,
) => {
    return showMore ? content : truncate(content, { length: limitContent })
}

export const getButtonText = (
    showMore: boolean,
    buttonText?: { hide?: string; more?: string },
) => {
    return showMore ? buttonText?.hide : buttonText?.more
}

export const expandOrCollapseContent = (
    contentRef: React.RefObject<HTMLDivElement>,
    showMore: boolean,
) => {
    if (contentRef.current)
        return (contentRef.current.style.maxHeight = showMore
            ? `${contentRef.current.scrollHeight}px`
            : '180px')
}
