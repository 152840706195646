import type * as React from 'react'
import type { RewardChartStaticProperties } from './RewardChart.types'
import RewardChartLoader from './RewardChartLoader'
import RewardChartProgress from './RewardChartProgress'
import RewardChartTable from './RewardChartTable'

const RewardChart: React.FC & RewardChartStaticProperties = () => {
    return null
}

RewardChart.Progress = RewardChartProgress
RewardChart.Table = RewardChartTable
RewardChart.Loader = RewardChartLoader

export default RewardChart
