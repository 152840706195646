import * as React from 'react'
import { ScrollArea } from '@components'
import type { WalletContractDescriptionProps } from './WalletContract.types'

const WalletContractDescription: React.FC<WalletContractDescriptionProps> = ({
    description,
}) => {
    return (
        <ScrollArea className="wallet-contract-description w-full flex-1 px-10">
            <div
                className="my-10"
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </ScrollArea>
    )
}
export default WalletContractDescription
