import OnboardingImage from '@core/assets/images/header.png'

export const HeaderOnboardingContents = [
    {
        title: '@guest.Onboarding.swiper_items_1_title',
        description: '@guest.Onboarding.swiper_items_1_description',
        image: OnboardingImage,
        duration: 5000,
    },
    {
        title: '@guest.Onboarding.swiper_items_2_title',
        description: '@guest.Onboarding.swiper_items_2_description',
        image: OnboardingImage,
        duration: 5000,
    },
    {
        title: '@guest.Onboarding.swiper_items_3_title',
        description: '@guest.Onboarding.swiper_items_3_description',
        image: OnboardingImage,
        duration: 5000,
    },
]
