import * as React from 'react'
import { DayPicker } from 'react-day-picker'
import { Icon } from '@components'
import i18n from '@core/translations'
import { getCalendarLocale } from './Calendar.utils'
import { cn } from '@core/utils'
import { buttonStyles } from '../Button/Button.styles'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

const Calendar: React.FC<CalendarProps> = ({
    className,
    classNames,
    showOutsideDays = true,
    ...props
}) => {
    return (
        <DayPicker
            locale={getCalendarLocale(
                localStorage.getItem('settings.locale') || i18n.language,
            )}
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center',
                caption_label: 'text-sm font-medium',
                nav: 'space-x-1 flex items-center',
                nav_button: cn(
                    buttonStyles({ variant: 'primary' }),
                    '!h-7 !w-7 min-h-7 rounded-md bg-transparent border-semantic-background-tertiary !fill-semantic-content-ink p-0 opacity-50 hover:opacity-100 focus:ring-0',
                ),
                nav_button_previous: '!absolute left-1',
                nav_button_next: '!absolute right-1',
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex',
                head_cell:
                    'text-semantic-content-ink rounded-md w-8 font-normal text-[0.8rem]',
                row: 'flex w-full mt-2',
                cell: cn(
                    'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-semantic-content-ink [&:has([aria-selected].day-outside)]:bg-tint-ink-10 [&:has([aria-selected].day-range-end)]:rounded-r-md',
                    props.mode === 'range'
                        ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
                        : '[&:has([aria-selected])]:rounded-md',
                ),
                day: cn(
                    buttonStyles({ variant: 'ghost-on-light' }),
                    'h-8 w-8 p-0 font-normal aria-selected:opacity-100 no-underline border-none focus:ring-0 min-h-8 rounded-md',
                ),
                day_range_start: 'day-range-start',
                day_range_end: 'day-range-end',
                day_selected:
                    'bg-semantic-content-ink !text-semantic-content-inkInverse hover:bg-semantic-background-primary hover:text-semantic-content-inkInverse focus:bg-semantic-content-ink focus:text-semantic-content-inkInverse',
                day_today: 'bg-tint-ink-10 !text-semantic-content-ink',
                day_outside:
                    'day-outside text-semantic-content-ink opacity-50 aria-selected:bg-gray-50 aria-selected:!text-tint-ink-60 aria-selected:opacity-20',
                day_disabled:
                    'text-semantic-content-ink !bg-transparent opacity-50',
                day_range_middle:
                    'aria-selected:bg-semantic-content-ink aria-selected:text-semantic-content-inkInverse',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                IconLeft: ({ ...props }) => (
                    <Icon name="caret-left" size={16} {...props} />
                ),
                IconRight: ({ ...props }) => (
                    <Icon name="caret-right" size={16} {...props} />
                ),
            }}
            {...props}
        />
    )
}

Calendar.displayName = 'Calendar'

export default Calendar
