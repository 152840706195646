import { reduce } from 'lodash'
import baseApi from '@core/api'
import type {
    CampaignDetailsData,
    CampaignDetailsResponse,
    CampaignNewsListData,
    CampaignNewsListResponse,
} from './CampaignApi.types'

const campaignApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        campaignNewsList: builder.query<
            CampaignNewsListResponse,
            CampaignNewsListData
        >({
            query: data => {
                const { campaign_types, ...dataWithoutCampaignTypes } = data

                const campaignTypesParams = reduce(
                    campaign_types,
                    (accumulator, currentValue, key) => {
                        accumulator[`campaign_types[${key}]`] = currentValue
                        return accumulator
                    },
                    {} as Record<string, string>,
                )

                return {
                    url: 'v2/campaign-news/list',
                    method: 'GET',
                    params: {
                        ...dataWithoutCampaignTypes,
                        ...campaignTypesParams,
                    },
                }
            },
            merge: (currentData, newData, { arg }) => {
                if (!arg.page || arg.page === 1) return newData

                currentData.data.push(...newData.data)
                currentData.meta.paginate = newData.meta.paginate
            },
        }),
        campaignDetails: builder.query<
            CampaignDetailsResponse,
            CampaignDetailsData
        >({
            query: data => ({
                url: 'v2/campaign-news/details/' + data.company_id,
                method: 'GET',
            }),
            providesTags: (response, error) =>
                (response?.data.details.campaign.type === 'deposit_gift' ||
                    response?.data.details.campaign.type ===
                        'cross_marketing_deposit_gift') &&
                !error
                    ? ['DepositCampaignDetail']
                    : [],
        }),
    }),
    overrideExisting: true,
})

export default campaignApi
