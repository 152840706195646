import * as React from 'react'
import type {
    SectionBarProps,
    SectionBarStaticProperties,
} from './SectionBar.types'
import { RenderLayoutRightComponent } from './SectionBar.utils'
import SectionBarLoader from './SectionBarLoader'

const SectionBar: React.FC<SectionBarProps> & SectionBarStaticProperties = ({
    title,
    description,
    LayoutRightComponent,
    LayoutRightComponentProps,
    MobileRightComponentProps,
    NavigateLink,
}) => {
    return (
        <div className="space-y-2">
            <div className="flex items-center justify-between gap-2">
                <h2 className="animate-enter line-clamp-1 text-display-lg-b text-semantic-content-ink">
                    {title}
                </h2>
                <RenderLayoutRightComponent
                    LayoutRightComponent={LayoutRightComponent}
                    LayoutRightComponentProps={LayoutRightComponentProps}
                    MobileRightComponentProps={MobileRightComponentProps}
                    NavigateLink={NavigateLink}
                />
            </div>
            {description && (
                <p className="animate-enter text-body-md-r text-semantic-content-inkLight">
                    {description}
                </p>
            )}
        </div>
    )
}

SectionBar.Loader = SectionBarLoader

export default SectionBar
