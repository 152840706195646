import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import baseApi from '@core/api'
import { useAppDispatch } from '@core/store'
import guestApi from '@guest/api'
import type {
    OTPVerifyLocationProps,
    ResendVerifyMethods,
} from './OTPVerify.types'
import { useNotification } from '@core/hooks'
import { authSubmitByType } from '../Onboarding/Onboarding.utils'
import { MailMaskUtils } from './OTPVerify.utils'
import { Environment } from '@core/constants'
import OTPVerifyContent from './OTPVerifyContent'
import './OTPVerify.css'

const OTPVerify: React.FC = () => {
    const navigate = useNavigate()
    const location: OTPVerifyLocationProps = useLocation()
    const { t } = useTranslation()
    const { toast } = useNotification()
    const dispatch = useAppDispatch()

    const resendVerify = React.useRef<ResendVerifyMethods>(null)

    const [loginPhoneVerify] = guestApi.useLoginPhoneVerifyMutation()

    const [code, setCode] = React.useState<string>('')

    const handleAutoVerifyCode = React.useCallback((verifyCode: string) => {
        setTimeout(() => {
            setCode(verifyCode)
        }, 3000)
    }, [])

    const handleVerifyLogin = React.useCallback(
        async (value: string) => {
            try {
                const result = await loginPhoneVerify({
                    code: value,
                    phone: location.state.auth_data.phone.raw,
                }).unwrap()

                localStorage.setItem(
                    'user.access_token',
                    result.data.token.access_token,
                )
                localStorage.setItem(
                    'user.refresh_token',
                    result.data.token.refresh_token,
                )
                localStorage.setItem(
                    'user.wallet_id',
                    String(result.data.wallet_id),
                )
                await dispatch(baseApi.endpoints?.me.initiate())

                if (!result.data.wallet_id)
                    return navigate(`/wallet-contract`, {
                        replace: true,
                        state: {
                            company_id: result.data.company_id,
                            redirect_page: result.data.redirect_page,
                            trigger_data: result.data.trigger_data,
                        },
                    })

                if (result.data.redirect_page === 'deposit')
                    return navigate('/topup', {
                        replace: true,
                        state: {
                            walletId: result.data.wallet_id,
                            triggerData: result.data.trigger_data,
                        },
                    })

                navigate('/', { replace: true })
            } catch {
                setCode('')
            }
        },
        [
            loginPhoneVerify,
            navigate,
            location.state.auth_data.phone.raw,
            dispatch,
        ],
    )

    const handleResendVerify = React.useCallback(async () => {
        const result = await authSubmitByType({
            phone: location.state.auth_data.phone.raw,
        })

        toast({
            variant: 'default',
            duration: 5000,
            message: t('@guest.OTPVerify.resend_toast_message'),
            icon: 'time',
        })

        if (result.data.code) {
            handleAutoVerifyCode(result.data.code)
        }

        resendVerify.current?.resetTimer(true)
    }, [location.state.auth_data.phone.raw, toast, t, handleAutoVerifyCode])

    const handleSubmitValue = React.useCallback(
        (value: string) => {
            return handleVerifyLogin(value)
        },
        [handleVerifyLogin],
    )

    React.useEffect(() => {
        if (Environment.ENV !== 'PROD' && location.state.code) {
            handleAutoVerifyCode(location.state.code)
        }
    }, [handleAutoVerifyCode, location.state.code])

    return (
        <div className="otp-content">
            <div className="otp-content-header">
                <h3>
                    {t('@guest.OTPVerify.hello_full_name', {
                        fullName: location.state.auth_data.first_name,
                    })}
                </h3>
                <p>{MailMaskUtils(location.state.auth_data.email)}</p>
            </div>
            <OTPVerifyContent
                state={location.state}
                code={code}
                setCode={setCode}
                onResendVerify={handleResendVerify}
                onSubmitValue={handleSubmitValue}
            />
            <div className="otp-content-footer">
                <p>{t('@guest.OTPVerify.sms_not_received')}</p>
                <Button
                    children={t('@guest.OTPVerify.send_to_email')}
                    variant="link"
                    size="tiny"
                />
            </div>
        </div>
    )
}

export default OTPVerify
