import * as React from 'react'

//eslint-disable-next-line
const usePrevious = <T extends unknown>(value: T): T => {
    const reference = React.useRef<T>(value)

    React.useEffect(() => {
        reference.current = value
    }, [value])

    return reference.current
}

export default usePrevious
