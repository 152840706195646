import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DepositCard, Row, Sheet } from '@components'
import { useAppSelector } from '@core/store'
import { useSelectedCard } from '@deposit/hooks'
import { TopUpAnotherCard } from '@deposit/views'
import type { OtherDepositMethodSectionProps } from './TopUp.types'

const OtherDepositMethodSection: React.FC<OtherDepositMethodSectionProps> = ({
    walletId,
    companyId,
    amount,
    enabledMasterPass,
    isCustomAmount,
}) => {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState<boolean>(false)

    const selected = useSelectedCard('masterpass')

    const { mpAccountLoading } = useAppSelector(state => state.depositSlice)

    if (!enabledMasterPass || companyId == 222) return null

    return (
        <Row className="mt-12 space-y-4">
            <Row.Title
                title={t('@deposit.TopUp.other_payment_method_section_title')}
            />
            {enabledMasterPass ? (
                (mpAccountLoading || selected.isLoading) && !selected.card ? (
                    <DepositCard.Loader logoSize={32} className="h-14" />
                ) : (
                    <DepositCard.Other
                        title={t(
                            '@deposit.TopUp.other_deposit_method_deposit_card_title',
                        )}
                        avatarIcon="credit-card"
                        onClick={() => setOpen(true)}
                    />
                )
            ) : null}

            <Sheet open={open} onOpenChange={setOpen}>
                <Sheet.Content className="overflow-y-scroll">
                    <TopUpAnotherCard
                        walletId={walletId}
                        amount={amount}
                        isCustomAmount={isCustomAmount}
                        setOpen={setOpen}
                    />
                </Sheet.Content>
            </Sheet>
        </Row>
    )
}
export default OtherDepositMethodSection
