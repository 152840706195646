import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import type { PopoverProps, PopoverStaticProperties } from './Popover.types.ts'
import PopoverContent from './PopoverContent'

const Popover: React.FC<PopoverProps> & PopoverStaticProperties = ({
    children,
}) => {
    return <PopoverPrimitive.Root>{children}</PopoverPrimitive.Root>
}

export const PopoverTrigger = PopoverPrimitive.Trigger

export const PopoverAnchor = PopoverPrimitive.Anchor

Popover.Content = PopoverContent
Popover.Trigger = PopoverTrigger
Popover.Anchor = PopoverAnchor

export default Popover
