import * as React from 'react'
import { Skeleton } from '@components'
import type { CampaignSegmentedListLoaderProps } from './CampaignSegmentedList.types'
import { cn } from '@core/utils'

import { clsx } from 'clsx'

const CampaignSegmentedListLoader: React.FC<
    CampaignSegmentedListLoaderProps
> = ({ tabsVisible = true, limit = 3, className, ...props }) => {
    return (
        <div className={cn('space-y-6', className)} {...props}>
            {tabsVisible && (
                <div className="flex gap-8 border-b-[1.6px] border-semantic-background-tertiary pb-2">
                    <Skeleton className="h-[33px] w-32" />
                    <Skeleton className="h-[33px] w-32" />
                </div>
            )}
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                {Array.from({ length: limit }).map((_, index) => (
                    <Skeleton
                        key={index}
                        className={clsx(
                            'h-[215px] rounded-3xl',
                            index === 0 && 'col-span-2 xl:col-span-3',
                        )}
                    />
                ))}
            </div>
        </div>
    )
}
export default CampaignSegmentedListLoader
