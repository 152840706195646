import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@components'
import type { PaymentRefundEftPosProps } from './PaymentRefundEftPos.types'
import { splitOrderId } from './PaymentRefundEftPos.utils'

const PaymentRefundEftPos: React.FC<PaymentRefundEftPosProps> = ({
    orderId,
}) => {
    const { t } = useTranslation()

    return (
        <div className="space-y-8">
            <div className="space-y-4">
                <div className="grid h-[160px] place-items-center rounded-[13px] bg-semantic-background-primary">
                    <div className="flex flex-col gap-2 text-center">
                        <strong className="text-label-md text-semantic-content-inkMedium">
                            {t('@payment.PaymentRefundEftPos.order_title')}
                        </strong>
                        <span className="text-display-lg-b text-semantic-content-ink">
                            {splitOrderId(orderId)}
                        </span>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <IconButton
                        as="span"
                        name="info"
                        className="shrink-0 bg-semantic-content-inkLight after:hidden"
                    />
                    <div className="flex flex-col gap-1 py-5">
                        <strong className="text-body-md-b text-semantic-content-ink">
                            {t('@payment.PaymentRefundEftPos.title')}
                        </strong>
                        <p className="text-body-md-r text-semantic-content-inkLight">
                            {t('@payment.PaymentRefundEftPos.description')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PaymentRefundEftPos
