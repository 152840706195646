import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stepper } from '@components'
import paymentApi from '@payment/api'
import { PaymentRefund, PaymentRefundEftPos } from '@payment/views'
import walletApi from '@wallet/api'
import type {
    AccountActivityDetailProps,
    ActiveStepsProps,
} from './AccountActivityDetail.types.ts'
import { useDetailsByType } from './AccountActivityDetail.hooks'
import DetailSections from './DetailSections'
import PaymentRefundHistorySection from './PaymentRefundHistorySection'
import RefundSection from './RefundSection'
import StepperHeader from './StepperHeader'

const AccountActivityDetail: React.FC<AccountActivityDetailProps> = ({
    id,
}) => {
    const { t } = useTranslation()
    const [activeId, setActiveId] = React.useState<number>(id)
    const [activeStep, setActiveStep] = React.useState<ActiveStepsProps>(
        'AccountActivityDetail',
    )

    const balanceHistoryDetailsQuery = walletApi.useBalanceHistoryDetailsQuery(
        { id: activeId },
        { refetchOnMountOrArgChange: true },
    )

    const [refundRequestPreCheckQuery] =
        paymentApi.useLazyRefundRequestPreCheckQuery()

    const wallet = React.useMemo(
        () => balanceHistoryDetailsQuery.data?.data.wallet,
        [balanceHistoryDetailsQuery.data?.data.wallet],
    )

    const refundRequests = React.useMemo(
        () =>
            balanceHistoryDetailsQuery.data?.data.historyable.refund_requests ??
            [],
        [balanceHistoryDetailsQuery.data?.data.historyable.refund_requests],
    )

    const details = useDetailsByType(balanceHistoryDetailsQuery.data?.data)

    const navigateRefund = React.useCallback(async () => {
        if (!balanceHistoryDetailsQuery.data?.data.historyable || !wallet?.id)
            return

        await refundRequestPreCheckQuery({
            payment_id: balanceHistoryDetailsQuery.data.data.historyable.id,
        }).unwrap()

        if (
            balanceHistoryDetailsQuery.data?.data.historyable.gateway ===
                'eftpos' &&
            balanceHistoryDetailsQuery.data.data.historyable.order_id
        ) {
            return setActiveStep('PaymentRefundEftPos')
        }
        return setActiveStep('PaymentRefund')
    }, [
        balanceHistoryDetailsQuery.data?.data.historyable,
        refundRequestPreCheckQuery,
        wallet?.id,
    ])

    if (!wallet || !balanceHistoryDetailsQuery.data || !details) return null

    return (
        <Stepper activeStep={activeStep}>
            <StepperHeader
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />
            <Stepper.Step step="AccountActivityDetail">
                {balanceHistoryDetailsQuery.data.data.type === 'capture' ? (
                    <Button
                        className="w-full focus:ring-0"
                        children={t(
                            '@wallet.AccountActivityDetail.refund_button_title',
                        )}
                        variant="ink-tonal"
                        onClick={navigateRefund}
                    />
                ) : undefined}
                <DetailSections details={details} />
                <RefundSection
                    refundRequests={refundRequests}
                    type={balanceHistoryDetailsQuery.data.data.type}
                    currency={wallet.company.currency}
                />
                {balanceHistoryDetailsQuery.data.data.type ===
                'payment_refund' ? (
                    <PaymentRefundHistorySection
                        paymentData={
                            balanceHistoryDetailsQuery.data?.data?.historyable
                                ?.payment
                        }
                        setId={setActiveId}
                        currency={wallet.company.currency}
                        loading={balanceHistoryDetailsQuery.isFetching}
                    />
                ) : null}
            </Stepper.Step>
            <Stepper.Step step="PaymentRefund">
                <PaymentRefund
                    paymentId={
                        balanceHistoryDetailsQuery.data?.data.historyable.id ??
                        0
                    }
                    setActiveStep={setActiveStep}
                />
            </Stepper.Step>
            <Stepper.Step step="PaymentRefundEftPos">
                <PaymentRefundEftPos
                    orderId={
                        balanceHistoryDetailsQuery.data?.data.historyable
                            .order_id ?? 0
                    }
                />
            </Stepper.Step>
        </Stepper>
    )
}

export default AccountActivityDetail
