import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'
import { replace } from 'lodash'
import { Navigation, Spinner, Timer, type TimerMethods } from '@components'
import { TimerUtils } from '@guest/utils'
import paymentApi from '@payment/api'
import useGenerateQREvents from './GenerateQR.hooks'

const GenerateQR: React.FC = () => {
    const { t } = useTranslation()

    const timer = React.useRef<TimerMethods>(null)

    const [qrGenerate, qrGenerateResult] = paymentApi.useQrGenerateMutation()

    const referenceCode = React.useMemo(() => {
        if (!qrGenerateResult.data?.data.reference_code) return
        return qrGenerateResult.data?.data.reference_code.toString()
    }, [qrGenerateResult.data?.data.reference_code])

    useGenerateQREvents(referenceCode)

    const duration = React.useMemo(() => {
        if (!qrGenerateResult.data?.data) return

        return TimerUtils.calculateDuration(
            qrGenerateResult.data.data.expires_at,
            qrGenerateResult.data.data.server_time,
        )
    }, [qrGenerateResult.data?.data])

    const createQrByReferenceCode = React.useCallback(async () => {
        await qrGenerate().unwrap()
        timer.current?.resetTimer(true)
    }, [qrGenerate])

    React.useEffect(() => {
        createQrByReferenceCode()
    }, [createQrByReferenceCode])

    if (
        !referenceCode ||
        typeof duration !== 'number' ||
        qrGenerateResult.isLoading
    )
        return (
            <div className="grid h-screen w-full place-items-center">
                <Spinner size={32} />
            </div>
        )

    return (
        <React.Fragment>
            <Navigation title={t('@payment.GenerateQR.route_header_title')} />
            <div className="flex h-[calc(100vh-50px)] flex-col items-center justify-center gap-6">
                <QRCodeSVG
                    value={`ALT-${referenceCode}-PAY`}
                    level="H"
                    className="h-[calc(100vw*0.5)] w-[calc(100vw*0.5)]"
                />
                <span className="text-display-hg text-semantic-content-ink">
                    {replace(referenceCode, /(\d{3})(\d{3})/, '$1 $2')}
                </span>
                <Timer.Circle
                    ref={timer}
                    size={96}
                    duration={duration}
                    className="w-fit"
                    valueTextStyle={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onFinished={createQrByReferenceCode}
                />
            </div>
        </React.Fragment>
    )
}
export default GenerateQR
