export default {
    Onboarding: {
        swiper_items_1_title: 'Discover Opportunities',
        swiper_items_1_description:
            'Use digital wallets of brands\nin a single app, don’t miss out on opportunities!',
        swiper_items_2_title: 'Create Wallet',
        swiper_items_2_description:
            'Choose the brand you want,\ncreate your digital wallet instantly!',
        swiper_items_3_title: 'Pay Quickly and Securely',
        swiper_items_3_description:
            'Make your purchase through Macellan SuperApp,\npay securely with a QR code!',
        login_button: 'Login',
        register_button: 'Register',
        copyright: `Macellan Advanced Technology Inc. © ${new Date().getFullYear()}. All rights reserved.`,
    },
    OTPVerify: {
        hello_full_name: 'Hello {{fullName}}',
        content_header_title: 'Enter Verification Code',
        content_header_description:
            'Enter the code sent to your phone number {{phone_number}}',
        resend_toast_message: 'Your verification code has been resent',
        resend_verify_button: 'Resend',
        resend_verify_second: 'sec',
        sms_not_received: 'Didn’t receive the SMS code?',
        send_to_email: 'Send to my email',
        update_phone_success_message: 'Phone number updated successfully',
    },
    CompanyWalletSubscribe: {
        route_header_title: '{{company_name}} WALLET AGREEMENT',
        add_button_title: 'I have read and agree',
        agreement_description:
            'By adding the wallet, you are considered to have accepted the <0 href="{{agreement_url}}">Wallet Agreement</0>',
        agreement_message:
            'Approve the wallet agreement to take advantage of the opportunity!',
        success_message: 'Your wallet has been created successfully',
    },
}
