import * as React from 'react'
import { map } from 'lodash'
import { Carousel } from '@components'
import { RewardChart } from '@components'
import type { RewardWonsChartProps } from './CampaignRewardList.types'

const RewardWonsChart: React.FC<RewardWonsChartProps> = ({ data, color }) => {
    if (!data.length) return null

    return (
        <React.Fragment>
            {map(data, campaign => (
                <Carousel.Item
                    key={campaign.id.toString()}
                    className="basis-full md:basis-1/2 lg:basis-full xl:basis-1/2">
                    <RewardChart.Progress
                        name={campaign.name}
                        processCount={campaign.process_count}
                        remainderCount={campaign.user_process_remainder}
                        color={color}
                    />
                </Carousel.Item>
            ))}
        </React.Fragment>
    )
}

export default RewardWonsChart
