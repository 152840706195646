import * as React from 'react'
import Lottie from 'react-lottie'
import type { OnboardingItemProps } from './Onboarding.types'

const OnboardingItem: React.FC<OnboardingItemProps> = ({
    color,
    currentIndex,
    currentItem,
    handleOnAnimationFinish,
}) => {
    return (
        <React.Fragment>
            <div
                className="onboarding-lottie-container"
                style={{ background: color + '33' }}>
                <Lottie
                    data-lottie={currentIndex}
                    key={`${currentIndex}-lottie`}
                    isClickToPauseDisabled={true}
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: currentItem.source,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: handleOnAnimationFinish,
                        },
                    ]}
                    height="100%"
                    width="100%"
                />
            </div>
            <div className="onboarding-content">
                <h2 data-title={currentIndex} key={`${currentIndex}-title`}>
                    {currentItem.title}
                </h2>
                <p
                    data-description={currentIndex}
                    key={`${currentIndex}-description`}>
                    {currentItem.description}
                </p>
            </div>
        </React.Fragment>
    )
}

export default OnboardingItem
