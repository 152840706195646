import * as React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import type { ButtonProps } from './Button.types'
import { getIconComponents } from './Button.utils'
import { IconSize } from './Button.utils'
import { cn } from '@core/utils'
import Spinner from '../Spinner/Spinner'
import { buttonStyles } from './Button.styles'

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant,
            size,
            rounded,
            className,
            leftIcon,
            rightIcon,
            loading,
            children,
            asChild = false,
            disabled,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : 'button'

        const { leftIconComponent, rightIconComponent } = getIconComponents({
            leftIcon,
            rightIcon,
            size,
        })

        return (
            <Comp
                ref={ref}
                className={cn(
                    buttonStyles({ variant, size, rounded, className }),
                )}
                disabled={loading || disabled}
                {...props}>
                {!loading && leftIconComponent}
                {!loading && <Slottable>{children}</Slottable>}
                {!loading && rightIconComponent}
                {loading && <Spinner size={IconSize(size)} />}
            </Comp>
        )
    },
)

Button.displayName = 'Button'

export default Button
