import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MasterPassSDK } from '@macellan/masterpass-sdk'
import { MasterPassService } from '@deposit/services'
import { useNotification } from '@core/hooks'
import { emitter, useTheme } from '@core/utils'

const useMasterPassLink = () => {
    const { t } = useTranslation()
    const theme = useTheme().colors
    const { toast, alert } = useNotification()

    const linkMpAccount = React.useCallback(async () => {
        await MasterPassService.linkCardToClient()
            .then(result => {
                if (
                    result.responseCode ===
                    MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP
                ) {
                    emitter.emit('masterpass-otp', {
                        otpType: 'link',
                    })
                }
            })
            .catch(error => {
                toast({
                    variant: 'error',
                    icon: 'warning',
                    message: error.data.message,
                    duration: 5000,
                })
            })
    }, [toast])

    return React.useCallback(() => {
        return alert({
            icon: 'credit-card',
            iconColor: theme.semantic.content.ink,
            title: t('@deposit.MasterPassLink.info_sheet_title'),
            message: t('@deposit.MasterPassLink.info_sheet_description'),
            actions: [
                {
                    children: t(
                        '@deposit.MasterPassLink.info_sheet_approve_button',
                    ),
                    onClick: linkMpAccount,
                    loading: true,
                },
                {
                    children: t(
                        '@deposit.MasterPassLink.info_sheet_cancel_button',
                    ),
                    variant: 'secondary',
                },
            ],
        })
    }, [alert, linkMpAccount, t, theme])
}

export default useMasterPassLink
