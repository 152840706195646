import { cva } from 'class-variance-authority'

export const buttonStyles = cva(
    [
        'w-fit',
        'flex',
        'justify-center',
        'items-center',
        'gap-1',
        'focus:outline-none',
        'disabled:cursor-not-allowed',
        'disabled:bg-semantic-background-primary',
        'disabled:!text-semantic-content-disabled',
        'disabled:fill-semantic-content-disabled',
        'disabled:after:hover:!bg-transparent',
        'transition duration-300 ease-in-out',
        'relative',
        'overflow-hidden',
        'border-[1.6px]',
        'border-transparent',
        'after:hover:bg-tint-ink-10 after:content-[""] after:absolute after:inset-0 after:transition after:duration-300 after:ease-in-out',
        'animate-in fade-in',
    ],
    {
        variants: {
            variant: {
                'primary': [
                    'bg-solid-brand-5',
                    '!text-semantic-content-inkInverse fill-semantic-background-inkInverse',
                    'focus:ring-tint-brand-20',
                ],
                'primary-tonal': [
                    'bg-solid-brand-1',
                    '!text-semantic-content-brand fill-semantic-content-brand',
                    'focus:ring-tint-brand-10',
                ],
                'secondary': [
                    'bg-transparent',
                    'border-semantic-background-tertiary',
                    '!text-semantic-content-ink fill-semantic-content-ink',
                    'focus:ring-tint-ink-5',
                ],
                'secondary-on-dark': [
                    'bg-transparent',
                    'border-tint-white-40',
                    '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
                    'focus:ring-tint-white-40',
                ],
                'ink': [
                    'bg-semantic-content-ink',
                    'border-transparent',
                    '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
                    'focus:ring-tint-ink-40',
                    'after:hover:bg-tint-ink-10',
                ],
                'ink-tonal': [
                    'bg-semantic-background-secondary',
                    'border-transparent',
                    '!text-semantic-content-ink fill-semantic-content-ink',
                    'focus:ring-tint-ink-5',
                ],
                'ink-inverse': [
                    'bg-solid-core-white',
                    'border-transparent',
                    '!text-semantic-content-ink fill-solid-ink-9',
                    'focus:ring-tint-white-40',
                ],
                'ghost-on-dark': [
                    'bg-solid-core-transparent',
                    'border-transparent',
                    '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
                    'focus:ring-tint-white-40',
                    'after:hover:!bg-semantic-content-inkInverse/10',
                    'underline',
                ],
                'ghost-on-light': [
                    'bg-solid-core-transparent',
                    'border-transparent',
                    '!text-semantic-content-ink fill-semantic-content-ink',
                    'focus:ring-tint-ink-40',
                    'underline',
                ],
                'bg-blurred': [
                    'bg-tint-white-60',
                    'border-transparent',
                    '!text-semantic-content-ink fill-solid-ink-9',
                    'focus:ring-tint-white-10',
                ],
                'link': [
                    'bg-solid-core-transparent',
                    'border-transparent',
                    '!text-semantic-content-ink fill-semantic-content-ink',
                    'focus:ring-tint-ink-40',
                    'after:hover:bg-transparent',
                    'hover:!text-tint-ink-80',
                    '!px-0 !py-0 focus:!ring-0',
                    'active:!text-tint-ink-60',
                    '!duration-0',
                    'underline',
                ],
            },
            size: {
                tiny: [
                    'px-2',
                    'py-1',
                    'text-body-sm-b',
                    'min-h-6',
                    'rounded-[7px]',
                    'focus:ring-2',
                ],
                small: [
                    'px-4',
                    'py-1',
                    'text-body-md-b',
                    'min-h-8',
                    'rounded-[9px]',
                    'focus:ring-2',
                ],
                medium: [
                    'px-4',
                    'py-1',
                    'text-body-md-b',
                    'min-h-10',
                    'rounded-[13px]',
                    'focus:ring-4',
                ],
                large: [
                    'px-4',
                    'py-1',
                    'text-body-md-b',
                    'min-h-12',
                    'rounded-[13px]',
                    'focus:ring-4',
                ],
            },
            rounded: {
                pill: ['!rounded-full'],
                default: '',
            },
        },
        defaultVariants: {
            variant: 'primary',
            size: 'large',
            rounded: 'default',
        },
    },
)
