import * as React from 'react'
import type { TopUpContentProps } from './Content.types'

const TopUpContent: React.FC<TopUpContentProps> = ({ children }) => {
    return (
        <div className="container flex py-8 lg:justify-center lg:py-[72px]">
            <div className="w-full xl:w-[436px]">{children}</div>
        </div>
    )
}

export default TopUpContent
