import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { type ToastProps } from '@components'
import baseApi from '@core/api'
import store from '@core/store'
import i18n from '@core/translations'
import type { PaymentMinimal } from '@payment/api'
import { useNotification } from '@core/hooks'
import { useTheme } from '@core/utils'

const dispatch = store.dispatch

const usePaymentEvents = () => {
    const theme = useTheme()

    const navigate = useNavigate()
    const { toast, alert } = useNotification()

    return React.useCallback(
        async (event: PaymentMinimal) => {
            const toastParams: Omit<ToastProps, 'open'> = {
                variant: 'error',
                icon: 'warning',
                message: i18n.t(
                    'socket_context.payment_event.toast_error_message',
                ),
                duration: 5000,
            }

            if (event.status === 'no_balance') {
                toastParams.message = i18n.t(
                    'socket_context.payment_event.toast_no_balance_message',
                )
            }

            if (event.status === 'expire') {
                toastParams.message = i18n.t(
                    'socket_context.payment_event.toast_expire_message',
                )
            }

            if (event.status === 'device_offline') {
                toastParams.message = i18n.t(
                    'socket_context.payment_event.toast_device_offline_message',
                )
            }

            if (event.status === 'callback_waiting') {
                return
            }

            if (event.status === 'callback_no') {
                toastParams.message = i18n.t(
                    'socket_context.payment_event.toast_callback_no',
                )
            }

            if (event.status === 'service_error') {
                toastParams.message = ''
            }

            if (event.status === 'discount_point_change') {
                toastParams.message = i18n.t(
                    'socket_context.payment_event.toast_discount_point_change',
                )

                navigate('/', {
                    replace: true,
                    state: {
                        walletId: event.original_wallet_id ?? event.wallet_id,
                    },
                })
            }

            if (event.status === 'approval') {
                return
            }

            if (event.status === 'approved') {
                return
            }

            if (event.status === 'pre_complete') {
                return
            }

            if (event.status === 'complete') {
                dispatch(baseApi.util.invalidateTags(['WalletDetail']))

                navigate('/', {
                    replace: true,
                    state: {
                        walletId: event.original_wallet_id ?? event.wallet_id,
                    },
                })

                return setTimeout(
                    () =>
                        alert({
                            title: i18n.t(
                                '@payment.utils.PaymentAlert.alert_title',
                            ),
                            message: i18n.t(
                                '@payment.utils.PaymentAlert.alert_message',
                            ),
                            iconColor: theme.colors.semantic.content.success,
                            icon: 'check-circle',
                            actions: [
                                {
                                    children: 'Kapat',
                                },
                            ],
                        }),
                    750,
                )
            }

            toast(toastParams)
        },
        [alert, theme, navigate, toast],
    )
}

export default usePaymentEvents
