const sendPostMessageUtils = (message: string, type?: string) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message)
    }

    if (type) {
        window.dispatchEvent(
            new CustomEvent(type, {
                detail: message,
            }),
        )
    }

    window.parent.postMessage(
        {
            message: message,
        },
        '*',
    )
}

export default sendPostMessageUtils
