import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { filter } from 'lodash'
import type { AccountType, MasterPassCard } from '@macellan/masterpass-sdk'
import type { DepositState } from './DepositSlice.types'

const initialState: DepositState = {
    mpCards: [],
    mpCardsLoading: false,
    mpAccountType: 'unknown',
    mpAccountLoading: false,
    mpDeleteLoading: false,
    mpRegisterLoading: false,
    mpLinkLoading: false,
    mpUnlinkLoading: false,
    mpResendOTPLoading: false,
    mpValidateTransactionLoading: false,
    mpMakeLoading: false,
    mpPurchaseLoading: false,
    upHasCompleted: false,
}

const DepositSlice = createSlice({
    name: 'depositSlice',
    initialState: initialState,
    reducers: {
        setMpCards(state, action: PayloadAction<MasterPassCard[]>) {
            state.mpCards = action.payload
        },
        removeMpCards(state, action: PayloadAction<MasterPassCard['Name']>) {
            state.mpCards = filter(
                state.mpCards,
                (card: MasterPassCard) => card.Name !== action.payload,
            )
        },
        setMpCardsLoading(state, action: PayloadAction<boolean>) {
            state.mpCardsLoading = action.payload
        },
        setMpAccountType(state, action: PayloadAction<AccountType>) {
            state.mpAccountType = action.payload
        },
        setMpAccountLoading(state, action: PayloadAction<boolean>) {
            state.mpAccountLoading = action.payload
        },
        setMpDeleteLoading(state, action: PayloadAction<boolean>) {
            state.mpDeleteLoading = action.payload
        },
        setMpRegisterLoading(state, action: PayloadAction<boolean>) {
            state.mpRegisterLoading = action.payload
        },
        setMpLinkLoading(state, action: PayloadAction<boolean>) {
            state.mpLinkLoading = action.payload
        },
        setMpUnLinkLoading(state, action: PayloadAction<boolean>) {
            state.mpUnlinkLoading = action.payload
        },
        setMpResendOTPLoading(state, action: PayloadAction<boolean>) {
            state.mpResendOTPLoading = action.payload
        },
        setMpValidateTransactionLoading(state, action: PayloadAction<boolean>) {
            state.mpValidateTransactionLoading = action.payload
        },
        setMpMakeLoading(state, action: PayloadAction<boolean>) {
            state.mpMakeLoading = action.payload
        },
        setMpPurchaseLoading(state, action: PayloadAction<boolean>) {
            state.mpPurchaseLoading = action.payload
        },
        setUpHasCompleted(state, action) {
            state.upHasCompleted = action.payload
        },
    },
})

export const {
    setMpCards,
    setMpCardsLoading,
    removeMpCards,
    setMpAccountType,
    setMpAccountLoading,
    setMpDeleteLoading,
    setMpRegisterLoading,
    setMpLinkLoading,
    setMpUnLinkLoading,
    setMpResendOTPLoading,
    setMpValidateTransactionLoading,
    setMpMakeLoading,
    setMpPurchaseLoading,
    setUpHasCompleted,
} = DepositSlice.actions
export default DepositSlice.reducer
