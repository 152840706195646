import * as React from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import type { AvatarProps, AvatarStaticProperties } from './Avatar.types'
import { cn } from '@core/utils'
import AvatarFallback from './AvatarFallback'
import AvatarImage from './AvatarImage'

const AvatarBase: React.ForwardRefRenderFunction<
    HTMLDivElement,
    AvatarProps
> = ({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn(
            'animate-enter relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full',
            className,
        )}
        {...props}
    />
)

const Avatar = React.forwardRef(AvatarBase) as React.ForwardRefExoticComponent<
    AvatarProps & React.RefAttributes<HTMLDivElement>
> &
    AvatarStaticProperties

Avatar.Image = AvatarImage
Avatar.Fallback = AvatarFallback

Avatar.displayName = 'Avatar'

export default Avatar
