import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@core/utils'

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(
            'inline-flex h-8 items-center border-b-[1.6px] border-semantic-background-tertiary',
            className,
        )}
        {...props}
    />
))
TabsList.displayName = TabsPrimitive.List.displayName

export default TabsList
