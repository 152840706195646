import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DepositCard, Sheet, Stepper } from '@components'
import type { CardType } from '@deposit/api'
import { MasterPassDepositCard } from '@deposit/components'
import { DepositMethodsService } from '@deposit/services'
import { MasterPassCardRegister, MasterPassCards } from '@deposit/views'
import walletApi from '@wallet/api'
import type { TopUpDepositCardProps } from './TopUpDepositCard.types'

const TopUpDepositCard: React.FC<TopUpDepositCardProps> = ({
    walletId,
    ...props
}) => {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState<boolean>(false)
    const [view, setView] = React.useState<'cards' | 'cardRegister'>('cards')

    const [depositMethodLoading, setDepositMethodLoading] =
        React.useState<boolean>(true)

    const [depositMethod, setDepositMethod] = React.useState<CardType>()

    const walletDetailsQuery = walletApi.useWalletDetailsQuery({
        wallet_id: walletId,
    })

    const handleLastDepositMethod = React.useCallback(async () => {
        setDepositMethodLoading(true)

        const companyDepositMethods =
            walletDetailsQuery.data?.data.company.deposit_methods

        if (!companyDepositMethods) return

        const result = await DepositMethodsService.getLastDepositMethod(
            walletId,
            companyDepositMethods,
        )

        if (result) setDepositMethod(result as CardType)

        setDepositMethodLoading(false)
    }, [walletDetailsQuery.data?.data.company.deposit_methods, walletId])

    React.useEffect(() => {
        handleLastDepositMethod()
    }, [handleLastDepositMethod])

    const DepositCardRightComponent = React.useMemo(() => {
        return (
            <Button
                children={t('@deposit.TopUpDepositCard.change_button')}
                variant="primary"
                size="tiny"
                rounded="pill"
                onClick={() => setOpen(true)}
            />
        )
    }, [t])

    if (depositMethodLoading) {
        return <DepositCard.Loader />
    }

    if (depositMethod !== 'masterpass') return null

    return (
        <React.Fragment>
            <MasterPassDepositCard
                LayoutRightComponent={DepositCardRightComponent}
                setOpen={setOpen}
                {...props}
            />
            <Sheet
                open={open}
                onOpenChange={isOpen => {
                    setOpen(isOpen)
                }}>
                <Sheet.Content className="overflow-y-scroll">
                    <Stepper activeStep={view}>
                        <Stepper.Step step="cards">
                            <MasterPassCards
                                setOpen={setOpen}
                                setView={setView}
                            />
                        </Stepper.Step>
                        <Stepper.Step step="cardRegister">
                            <MasterPassCardRegister
                                setOpen={setOpen}
                                setView={setView}
                            />
                        </Stepper.Step>
                    </Stepper>
                </Sheet.Content>
            </Sheet>
        </React.Fragment>
    )
}

export default TopUpDepositCard
