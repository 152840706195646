import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { MasterPassSDK } from '@macellan/masterpass-sdk'
import type { CardType } from '@deposit/api'
import { MasterPassService } from '@deposit/services'
import { MaskCard } from '@deposit/utils'
import type { TopUpAnotherCardForm } from '@deposit/views'
import { useNotification } from '@core/hooks'
import { emitter, MaskUtils } from '@core/utils'

export interface useTopUpAnotherCardData {
    walletId: number
    installmentCount: number | null
}

const useTopUpAnotherCard = (
    data: useTopUpAnotherCardData,
    // eslint-disable-next-line no-unused-vars
    handleCompletePurchase: (success: boolean, depositMethod: CardType) => void,
) => {
    const navigate = useNavigate()
    const { toast } = useNotification()
    const [loading, setLoading] = React.useState(false)

    const handleInit = React.useCallback(
        async (values: TopUpAnotherCardForm) => {
            setLoading(true)

            const rtaPanRawValue = MaskUtils.getRawValue(
                'credit-card',
                values.creditCardNo,
            )

            const maskedRtaPan = MaskCard(rtaPanRawValue)

            const expireDateRawValue = MaskUtils.getRawValue(
                'date-time',
                values.expireDate,
                'MM/YY',
            )

            const expiryYear = expireDateRawValue
                .getFullYear()
                .toString()
                .slice(2, 4)

            const expiryMonth = (
                '0' + (expireDateRawValue.getMonth() + 1).toString()
            ).slice(-2)

            const expiryDate = expiryYear + expiryMonth

            let purchaseResult = null

            try {
                if (values.saveCard) {
                    purchaseResult =
                        await MasterPassService.purchaseAndRegister({
                            walletId: data.walletId,
                            amount: values.amount,
                            rtaPan: rtaPanRawValue,
                            maskedRtaPan: maskedRtaPan,
                            cvc: values.cvcNo,
                            expiryDate: expiryDate,
                            accountAliasName: values.name,
                            installmentCount: data.installmentCount,
                        })
                } else {
                    purchaseResult = await MasterPassService.directPurchase({
                        walletId: data.walletId,
                        amount: values.amount,
                        rtaPan: rtaPanRawValue,
                        maskedRtaPan: maskedRtaPan,
                        cvc: values.cvcNo,
                        expiryDate: expiryDate,
                        installmentCount: data.installmentCount,
                    })

                    if (
                        purchaseResult.sdkResult.responseCode ===
                            MasterPassSDK.Constants.ResponseCodes.SUCCESS ||
                        purchaseResult.sdkResult.responseCode ===
                            MasterPassSDK.Constants.ResponseCodes.SUCCESS_EMPTY
                    ) {
                        const mpMakeDepositResult =
                            await MasterPassService.makeDeposit({
                                depositId:
                                    purchaseResult.initResult.data.deposit_id,
                                token: purchaseResult.sdkResult.token,
                            })

                        handleCompletePurchase(true, 'masterpass')
                        setLoading(false)
                        return mpMakeDepositResult
                    }
                }

                if (
                    purchaseResult.sdkResult.responseCode ===
                    MasterPassSDK.Constants.ResponseCodes.VALIDATE_OTP
                ) {
                    await MasterPassService.markOTP(
                        purchaseResult.initResult.data.deposit_id,
                    )

                    emitter.emit('masterpass-otp', {
                        otpType: 'purchase',
                        depositId: purchaseResult.initResult.data.deposit_id,
                    })
                }

                if (
                    purchaseResult.sdkResult.responseCode ===
                    MasterPassSDK.Constants.ResponseCodes.VALIDATE_3D_SECURE
                ) {
                    navigate('/masterpass-3d', {
                        state: {
                            depositId:
                                purchaseResult.initResult.data.deposit_id,
                            threeDUrl:
                                purchaseResult.sdkResult.url3D +
                                '&returnUrl=' +
                                purchaseResult.initResult.data.callback_url,
                        },
                    })
                }
            } catch (error: any /* eslint-disable-line @typescript-eslint/no-explicit-any */) {
                toast({
                    variant: 'error',
                    icon: 'warning',
                    message: error.message,
                    duration: 5000,
                })
            }

            setLoading(false)
        },
        [
            data.installmentCount,
            data.walletId,
            handleCompletePurchase,
            navigate,
            toast,
        ],
    )

    return {
        loading: loading,
        init: handleInit,
    }
}

export default useTopUpAnotherCard
