import * as React from 'react'
import { SectionBar } from '@components'
import { cn } from '@core/utils'
import AccountActivityLoader from './AccountActivityLoader'

const AccountActivityListLoader: React.FC<
    React.HTMLAttributes<HTMLDivElement>
> = ({ className, ...props }) => {
    return (
        <div className={cn('space-y-6', className)} {...props}>
            <SectionBar.Loader />
            <AccountActivityLoader limit={5} />
        </div>
    )
}
export default AccountActivityListLoader
