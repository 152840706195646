import * as React from 'react'
import { forwardRef } from 'react'
import CurrencyInput, {
    type CurrencyInputProps,
} from 'react-currency-input-field'
import { cn } from '@core/utils'
import './Input.css'

const InputCurrency: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<CurrencyInputProps> &
        React.RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, CurrencyInputProps>(
    ({ className, ...props }, ref) => {
        return (
            <CurrencyInput
                ref={ref}
                className={cn('currency-input', className)}
                {...props}
            />
        )
    },
)

InputCurrency.displayName = 'InputCurrency'

export default InputCurrency
