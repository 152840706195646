import type {
    GetToastActionTypeFunction,
    GetToastProgressColorsFunction,
} from './Toast.types'

export const getToastActionType: GetToastActionTypeFunction = type => {
    switch (type) {
        case 'suggestion':
            return 'brand'
        case 'success':
            return 'ink-inverse'
        case 'error':
            return 'ink-inverse'
        default:
            return 'yellow'
    }
}

export const getToastProgressColors: GetToastProgressColorsFunction = (
    type,
    theme,
) => {
    if (type === 'suggestion') {
        return {
            background: theme.colors.semantic.content.ink,
            indicator: theme.colors.semantic.content.brand,
            text: theme.colors.semantic.content.ink,
        }
    }

    if (type === 'success' || type === 'error') {
        return {
            background: theme.colors.tint.white[20],
            indicator: theme.colors.semantic.content.inkInverse,
            text: theme.colors.semantic.content.inkInverse,
        }
    }

    return {
        background: theme.colors.tint.white[20],
        indicator: theme.colors.semantic.content.brand,
        text: theme.colors.semantic.content.inkInverse,
    }
}
