import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@core/utils'

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'mt-6 ring-offset-solid-ink-3 duration-300 ease-linear animate-in focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-semantic-content-ink focus-visible:ring-offset-2 data-[state=active]:fade-in-75',
            className,
        )}
        {...props}
    />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export default TabsContent
