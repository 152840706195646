import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import baseApi from '@core/api'
import { DayJSMiddlewares, ThemeMiddlewares } from '@core/middlewares'
import { setPlatform } from '@core/slices/BaseSlice.ts'
import { useAppDispatch } from '@core/store'
import i18n from '@core/translations'
import { Splash } from '@guest/views'
import { setLocalStorage } from './Initialize.utils'

const Initialize: React.FC = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token') || ''
    const dispatch = useAppDispatch()

    const [isNavigate, setIsNavigate] = React.useState<boolean>(false)

    baseApi.useAppInfoQuery({
        platform: 'ios',
        version: '1.18.0',
    })

    const InitializeQuery = baseApi.useInitializeQuery({ token })

    const initialize = React.useMemo(
        () => InitializeQuery.data?.data,
        [InitializeQuery.data],
    )

    const InitializeApp = React.useCallback(async () => {
        localStorage.clear()

        if (!token) return navigate('/404', { replace: true })

        if (!initialize) return

        i18n.changeLanguage(initialize.auth_data.locale)
        setLocalStorage(token, initialize)

        dispatch(setPlatform(initialize.platform))

        DayJSMiddlewares.setLocale()
        ThemeMiddlewares.SetTheme(initialize.company.color)

        await dispatch(baseApi.endpoints.me.initiate()).unwrap()
        setIsNavigate(true)
    }, [dispatch, initialize, navigate, token])

    React.useEffect(() => {
        InitializeApp()
    }, [InitializeApp])

    return <Splash initialize={initialize} isNavigate={isNavigate} />
}
export default Initialize
