import type * as React from 'react'
import type { CampaignItemStaticProperties } from './CampaignItem.types'
import CampaignItemBox from './CampaignItemBox'
import CampaignItemCard from './CampaignItemCard'
import './CampaignItem.css'

const CampaignItem: React.FC & CampaignItemStaticProperties = () => {
    return null
}
CampaignItem.displayName = 'CampaignItem'

CampaignItem.Box = CampaignItemBox
CampaignItem.Card = CampaignItemCard

export default CampaignItem
