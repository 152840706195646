import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { Button, Row, Textarea } from '@components'
import paymentApi, { type RefundReason } from '@payment/api'
import type {
    PaymentRefundForm,
    PaymentRefundProps,
} from './PaymentRefund.types'
import { useNotification } from '@core/hooks'
import { paymentRefundValidation } from './PaymentRefund.validations'
import PaymentRefundReasonList from './PaymentRefundReasonList'

const PaymentRefund: React.FC<PaymentRefundProps> = ({
    paymentId,
    setActiveStep,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()
    const refundReasonListQuery = paymentApi.useRefundReasonListQuery(undefined)
    const [refundRequest, refundRequestResult] =
        paymentApi.useRefundRequestMutation()

    const handleSubmit = React.useCallback(
        async (values: PaymentRefundForm) => {
            try {
                const result = await refundRequest({
                    payment_id: values.payment_id,
                    reason: values.reason.value,
                    comment: values.comment,
                }).unwrap()

                if (result.data.status === 'open') {
                    toast({
                        variant: 'success',
                        icon: 'check-circle',
                        duration: 5000,
                        message: t(
                            '@payment.PaymentRefund.refund_request_success',
                        ),
                    })
                    setActiveStep('AccountActivityDetail')
                }
            } catch {
                setActiveStep('AccountActivityDetail')
            }
        },
        [refundRequest, setActiveStep, t, toast],
    )

    const formik = useFormik<PaymentRefundForm>({
        initialValues: {
            payment_id: paymentId,
            reason: { label: '', value: '' },
            comment: '',
        },
        onSubmit: handleSubmit,
        validationSchema: paymentRefundValidation,
    })

    const handleOnClickChip = React.useCallback(
        (reason: RefundReason) => {
            formik.setFieldValue('reason', reason)
        },
        [formik],
    )

    if (!refundReasonListQuery.data) return null

    return (
        <form className="space-y-4" onSubmit={formik.handleSubmit}>
            <Row>
                <Row.Title
                    title={t('@payment.PaymentRefund.refund_title')}
                    className="pb-4 pt-7"
                />
                <PaymentRefundReasonList
                    reasons={refundReasonListQuery.data.data}
                    activeReason={formik.values.reason}
                    onClick={handleOnClickChip}
                />
            </Row>
            <Row>
                <Row.Title
                    title={t('@payment.PaymentRefund.refund_title')}
                    className="pb-4 pt-7"
                />
                <Textarea
                    placeholder={t(
                        '@payment.PaymentRefund.comment_placeholder',
                    )}
                    value={formik.values.comment}
                    onBlur={formik.handleBlur('comment')}
                    onChange={formik.handleChange('comment')}
                />
            </Row>
            <Button
                type="submit"
                children={t('@payment.PaymentRefund.submit_button')}
                loading={refundRequestResult.isLoading}
                disabled={!formik.isValid}
                className="w-full"
            />
        </form>
    )
}
export default PaymentRefund
