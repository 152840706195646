import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, WalletCard } from '@components'
import paymentApi from '@payment/api'
import type { CaptureWalletProps } from './Capture.types'
import { useNotification } from '@core/hooks'
import DepositToast from './DepositToast'

const CaptureWallet: React.FC<CaptureWalletProps> = ({
    wallet,
    payment,
    paymentId,
    status,
    onChangePointUsage,
    onCaptureAvailable,
}) => {
    const { t } = useTranslation()
    const { toast } = useNotification()

    const [isPointChecked, setPointChecked] = React.useState<boolean>(false)
    const [disabledPointChecked, setDisabledPointChecked] =
        React.useState<boolean>(false)
    const [topUpSuccess, setTopUpSuccess] = React.useState<boolean>(false)

    const [assignWallet] = paymentApi.useAssignWalletMutation()
    const [preApproval, preApprovalResult] =
        paymentApi.useLazyPreApprovalQuery()

    const pointUsage = React.useMemo(() => {
        return preApprovalResult.data?.data.point_usage
    }, [preApprovalResult.data?.data.point_usage])

    const depositAmount = React.useMemo(() => {
        const preApprovalResultData = preApprovalResult.data?.data

        if (!preApprovalResultData) return 0
        return isPointChecked
            ? preApprovalResultData.deposit_point_and_balance
            : preApprovalResultData.deposit_balance
    }, [isPointChecked, preApprovalResult.data?.data])

    const handlePointChecked = React.useCallback(
        (value: boolean, hideToast?: boolean) => {
            if (!pointUsage) return

            if (!pointUsage.status && !hideToast) {
                toast({
                    icon: 'warning',
                    message: pointUsage.message,
                    duration: 5000,
                })
                setDisabledPointChecked(true)
            }

            const checkResult = pointUsage.status ? value : false
            setPointChecked(checkResult)
            onChangePointUsage(checkResult)
        },
        [onChangePointUsage, pointUsage, toast],
    )

    React.useEffect(() => {
        onCaptureAvailable(depositAmount === 0 || topUpSuccess)
    }, [depositAmount, onCaptureAvailable, topUpSuccess])

    React.useEffect(() => {
        handlePointChecked(true, true)
    }, [handlePointChecked])

    React.useEffect(() => {
        if (status !== 'approval') return

        assignWallet({
            paymentId: paymentId,
            wallet_id: wallet.id,
            wallet_share_id: undefined,
        }).then(() => preApproval({ paymentId: paymentId }))
    }, [paymentId, preApproval, assignWallet, status, wallet.id])

    return (
        <div className="container pt-10">
            <Row className="space-y-4">
                <Row.Title title={t('@payment.Capture.wallet_row_title')} />
                <WalletCard.Paying
                    title={wallet.company.name}
                    logo={wallet.company.logo}
                    currency={wallet.company.currency}
                    points={wallet.point}
                    amount={wallet.balance}
                    color={wallet.company.color}
                    disabledPointChecked={disabledPointChecked}
                    isPointChecked={isPointChecked}
                    handlePointChecked={handlePointChecked}
                />
            </Row>
            <DepositToast
                wallet={wallet}
                payment={payment}
                walletId={wallet.id}
                depositAmount={depositAmount}
                onComplete={() => setTopUpSuccess(true)}
            />
        </div>
    )
}
export default CaptureWallet
