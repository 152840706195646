import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { find, map } from 'lodash'
import { type MasterPassCard } from '@macellan/masterpass-sdk'
import { Button, DepositCard, Sheet } from '@components'
import { useAppSelector } from '@core/store'
import depositApi from '@deposit/api'
import { MasterPassService } from '@deposit/services'
import type { MasterPassCardsProps } from './MasterPassCards.types'
import { useNotification } from '@core/hooks'
import { DepositMethodsConstants } from '@deposit/constants'
import MasterPassCardsLoader from './MasterPassCardsLoader'
import MasterPassCardsNew from './MasterPassCardsNew'

const MasterPassCards: React.FC<MasterPassCardsProps> = ({
    setOpen,
    setView,
}) => {
    const { t } = useTranslation()
    const { alert, toast } = useNotification()

    const cardListQuery = depositApi.useUserCardListQuery(undefined)

    const { mpCards, mpCardsLoading, mpAccountType } = useAppSelector(
        state => state.depositSlice,
    )

    const selectedMpCard = React.useMemo(() => {
        if (!cardListQuery.data?.data) return null
        return find(
            cardListQuery.data?.data,
            item => item.type === 'masterpass',
        )
    }, [cardListQuery.data?.data])

    const selectMpCard = React.useCallback(
        (item: MasterPassCard) => {
            setOpen(false)
            MasterPassService.selectCard(item)
        },
        [setOpen],
    )

    const deleteMpCard = React.useCallback(
        async (accountAliasName: string) => {
            await MasterPassService.deleteCards(accountAliasName)
                .then(result => {
                    if (!result.length) setOpen(false)
                })
                .catch(error => {
                    toast({
                        variant: 'error',
                        icon: 'warning',
                        message: error.data.message,
                    })
                })
        },
        [setOpen, toast],
    )

    const showDeleteAlert = React.useCallback(
        (accountAliasName: string) => {
            setOpen(false)

            alert({
                title: t('@deposit.MasterPassCards.mp_delete_alert_title'),
                message: t('@deposit.MasterPassCards.mp_delete_alert_message'),
                actions: [
                    {
                        loading: true,
                        children: t(
                            '@deposit.MasterPassCards.mp_delete_alert_approve_button',
                        ),
                        onClick: () => deleteMpCard(accountAliasName),
                    },
                    {
                        variant: 'secondary',
                        children: t(
                            '@deposit.MasterPassCards.mp_delete_alert_cancel_button',
                        ),
                    },
                ],
            })
        },
        [alert, deleteMpCard, setOpen, t],
    )

    const getMasterPassCards = React.useCallback(async () => {
        await MasterPassService.checkMasterPass()
        if (mpAccountType !== 'registered') return
        await MasterPassService.listCards()
    }, [mpAccountType])

    React.useEffect(() => {
        getMasterPassCards()
    }, [getMasterPassCards])

    return (
        <React.Fragment>
            <Sheet.Header>
                <Sheet.Title>
                    {t('@deposit.MasterPassCards.route_header_title')}
                </Sheet.Title>
                <Sheet.Description>
                    {t('@deposit.MasterPassCards.content_header_description')}
                </Sheet.Description>
            </Sheet.Header>
            <div className="space-y-4">
                {map(mpCards, (item, key) => (
                    <DepositCard
                        key={key}
                        title={item.Name}
                        description={item.Value1}
                        selected={item.Name === selectedMpCard?.name}
                        avatarImageSource={
                            DepositMethodsConstants.Logos.masterpass
                        }
                        LayoutRightComponent={
                            <Button
                                children={t(
                                    '@deposit.MasterPassCards.delete_alert_button',
                                )}
                                size="tiny"
                                rounded="pill"
                                onClick={() => showDeleteAlert(item.Name)}
                            />
                        }
                        onClick={() => selectMpCard(item)}
                    />
                ))}
                <MasterPassCardsLoader
                    mpCardsLoading={mpCardsLoading}
                    mpCardsLength={mpCards.length}
                />
                <MasterPassCardsNew
                    mpCardsLength={mpCards.length}
                    setView={setView}
                />
            </div>
        </React.Fragment>
    )
}
export default MasterPassCards
