import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from '@components'
import { DepositChips } from '@deposit/components'
import type { AmountSectionProps } from './AmountSection.types'

const AmountSection: React.FC<AmountSectionProps> = ({
    walletId,
    amount,
    isVisibleCustomAmount,
    setCustomAmount,
    onChangeAmount,
    ...props
}) => {
    const { t } = useTranslation()

    return (
        <Row className="space-y-4">
            <Row.Title title={t('@deposit.TopUp.amount_section_title')} />
            <DepositChips
                walletId={walletId}
                amount={amount}
                isVisibleCustomAmount={isVisibleCustomAmount}
                setIsCustomAmount={setCustomAmount}
                onChangeAmount={onChangeAmount}
                {...props}
            />
        </Row>
    )
}

export default AmountSection
