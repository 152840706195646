import { initReactI18next } from 'react-i18next'
import { find, keys } from 'lodash'
import en from './en'
import tr from './tr'

import i18n from 'i18next'

export type TranlatorLocale = 'tr' | 'en'

const DefaultLocale = 'en'

const resources = {
    tr: { translation: tr },
    en: { translation: en },
}

const getLocale = () => {
    return i18n.language?.slice(0, 2).toLowerCase() as TranlatorLocale
}

const getSupportedLocale = () => {
    const storageLocale = localStorage.getItem('settings.locale')

    if (storageLocale) return storageLocale as TranlatorLocale

    const resultLocale = find(
        keys(resources),
        supported => supported === getLocale(),
    )

    return (resultLocale ?? DefaultLocale) as TranlatorLocale
}

const changeLanguage = (locale: TranlatorLocale) => {
    i18n.changeLanguage(locale)
}

i18n.use(initReactI18next).init({
    returnNull: false,
    compatibilityJSON: 'v3',
    resources: resources,
    lng: getSupportedLocale(),
    fallbackLng: DefaultLocale,
    interpolation: { escapeValue: false },
    nsSeparator: '.',
})

export default { ...i18n, getLocale, getSupportedLocale, changeLanguage }
