import { cva } from 'class-variance-authority'

export const toastStyles = cva(
    [
        'group pointer-events-auto relative h-[56px] px-4 items-center justify-between gap-3 rounded-[20px] flex w-full overflow-hidden transition-all data-[as=div]:animate-in data-[as=div]:zoom-in-95 data-[as=div]:duration-300 data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
    ],
    {
        variants: {
            variant: {
                default: [
                    'bg-solid-ink-8',
                    'text-semantic-content-inkInverse',
                    'fill-semantic-content-inkInverse',
                ],
                suggestion: [
                    '!bg-solid-yellow-1',
                    'text-semantic-content-ink',
                    'fill-semantic-content-ink',
                ],
                success: [
                    'bg-solid-green-6',
                    'text-semantic-content-inkInverse',
                    'fill-semantic-content-inkInverse',
                ],
                error: [
                    'bg-semantic-content-error',
                    'text-semantic-content-inkInverse',
                    'fill-semantic-content-inkInverse',
                ],
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)
