import * as React from 'react'
import type { StepperProps, StepperStaticProperties } from './Stepper.types'
import { cn } from '@core/utils'
import StepperStep from './StepperStep'

const Stepper: React.FC<StepperProps> & StepperStaticProperties = ({
    activeStep,
    children,
    className,
    ...props
}) => {
    return (
        <div className={cn('animate-enter', className)} {...props}>
            {React.Children.map(children, child => {
                const isActive = child.props.step === activeStep
                return React.cloneElement(child, {
                    isActive,
                })
            })}
        </div>
    )
}

Stepper.displayName = 'Stepper'

Stepper.Step = StepperStep
export default Stepper
