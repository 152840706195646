import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HistoryRefundCard } from '@components'
import type { PaymentRefundHistorySectionProps } from './AccountActivityDetail.types'

const PaymentRefundHistorySection: React.FC<
    PaymentRefundHistorySectionProps
> = ({ paymentData, setId, currency, loading }) => {
    const { t } = useTranslation()

    const handleChangeId = React.useCallback(() => {
        if (!paymentData) return null
        setId(paymentData?.history?.id)
    }, [paymentData, setId])

    if (!paymentData) return null

    return (
        <React.Fragment>
            <HistoryRefundCard
                avatarIcon="top-up"
                amount={paymentData?.final_total}
                currency={currency}
                title={t(
                    '@wallet.AccountActivityDetail.payment_refund_history_spent_amount',
                )}
                onClick={handleChangeId}
                loading={loading}
            />
        </React.Fragment>
    )
}
export default PaymentRefundHistorySection
