import * as React from 'react'
import { map } from 'lodash'
import { Timer, type TimerMethods } from '@components'
import type { OnboardingSwiperProps } from './Onboarding.types'
import { OnboardingSwiperContents } from './Onboarding.constants'
import OnboardingItem from './OnboardingItem'

const OnboardingSwiper: React.FC<OnboardingSwiperProps> = ({ color }) => {
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)

    const currentItem = React.useMemo(() => {
        return OnboardingSwiperContents[currentIndex]
    }, [currentIndex])

    const timerRefs = React.useMemo(() => {
        return OnboardingSwiperContents.map(() =>
            React.createRef<TimerMethods>(),
        )
    }, [])

    const swiperItemDurations = React.useMemo(() => {
        return map(OnboardingSwiperContents, item => item.duration)
    }, [])

    const handleOnAnimationFinish = React.useCallback(() => {
        if (currentIndex < OnboardingSwiperContents.length - 1) {
            setCurrentIndex(currentIndex + 1)
            timerRefs[currentIndex + 1]?.current?.resetTimer(true)
            return
        }

        timerRefs.forEach((timer, index) =>
            timer?.current?.resetTimer(index === 0),
        )

        setCurrentIndex(0)
    }, [currentIndex, timerRefs])

    return (
        <React.Fragment>
            <OnboardingItem
                color={color}
                currentIndex={currentIndex}
                currentItem={currentItem}
                handleOnAnimationFinish={handleOnAnimationFinish}
            />
            <div className="onboarding-timer">
                {map(OnboardingSwiperContents, (_, index) => (
                    <Timer.Bar
                        ref={timerRefs[index]}
                        key={index}
                        auto={index === currentIndex}
                        duration={swiperItemDurations[currentIndex]}
                    />
                ))}
            </div>
        </React.Fragment>
    )
}

export default OnboardingSwiper
