import * as React from 'react'
import type { CurrencyCode } from '@macellan/formatter'
import { CurrencyFormatter } from '@macellan/formatter'
import { useContentConfig } from '@core/hooks'

export const useFormatCurrency = (point: number, currency: CurrencyCode) => {
    const content = useContentConfig()

    const currencySymbol = React.useMemo(() => {
        return CurrencyFormatter.getSymbol(currency)
    }, [currency])

    const formattedAmount = React.useMemo(() => {
        return CurrencyFormatter.format(point, content.locale, currency)
    }, [point, content.locale, currency])

    const formattedText = React.useMemo(() => {
        return CurrencyFormatter.formatToDetails(
            point,
            content.locale,
            currency,
        ).digid
    }, [content.locale, currency, point])

    return {
        currencySymbol: currencySymbol,
        formattedAmount: formattedAmount,
        formattedText: formattedText,
    }
}
