import * as React from 'react'
import { map } from 'lodash'
import { DepositCard } from '@components'
import type { MasterPassCardsLoaderProps } from './MasterPassCards.types'

const MasterPassCardsLoader: React.FC<MasterPassCardsLoaderProps> = ({
    mpCardsLoading,
    mpCardsLength,
}) => {
    if (!mpCardsLoading || mpCardsLength !== 0) return null

    return (
        <React.Fragment>
            {map([...Array(1)], (_, index) => (
                <DepositCard.Loader key={index.toString()} />
            ))}
        </React.Fragment>
    )
}
export default MasterPassCardsLoader
