import { CreditCardFormatter, DateTimeFormatter } from '@macellan/formatter'
import { yup } from '@core/middlewares'

export const mpRegisterValidationSchema = yup.object().shape({
    rtaPan: yup
        .string()
        .required()
        .test({
            name: 'rta-pan-validation',
            test: (value: string) => CreditCardFormatter.validate(value),
        }),
    accountAliasName: yup.string().required().min(3),
    expiryDate: yup
        .string()
        .required()
        .test({
            name: 'expiry-date-validation',
            test: (value: string) => DateTimeFormatter.validate(value, 'MM/YY'),
        }),
    agreement: yup.bool().required().oneOf([true]),
})
