import * as React from 'react'
import { Button } from '@components'
import type { CampaignItemCardProps } from './CampaignItem.types'
import { useFormatCurrency } from './CampaignItem.hooks'
import { useContentConfig } from '@core/hooks'
import { cn } from '@core/utils'

const CampaignItemCard: React.FC<CampaignItemCardProps> = ({
    image,
    isUsed,
    point,
    currency,
    description,
    className,
    actionButtonClick,
}) => {
    const content = useContentConfig()
    const { currencySymbol, formattedText } = useFormatCurrency(point, currency)

    return (
        <div
            className={cn(
                'campaign-item-card__container animate-enter',
                className,
            )}
            style={{ backgroundImage: `url(${image})` }}>
            <div data-is-used={isUsed} className="campaign-item-card">
                <div className="campaign-item-card__content">
                    <div className="campaign-item-card__content__point">
                        <span>{currencySymbol + formattedText}</span>
                        <span>{content.campaignItem.pointSuffix}</span>
                    </div>
                    <p>{description}</p>
                </div>
                <Button
                    className="w-full"
                    children={
                        isUsed
                            ? content.campaignItem.disabledButtonText
                            : content.campaignItem.detailsButtonText
                    }
                    variant="primary"
                    rounded="pill"
                    size="tiny"
                    onClick={actionButtonClick}
                    disabled={isUsed}
                />
            </div>
        </div>
    )
}
CampaignItemCard.displayName = 'CampaignItemCard'

export default CampaignItemCard
