import type { InitializeResponse } from '@core/api'

export const setLocalStorage = (
    token: string,
    initializeData: InitializeResponse['data'],
): void => {
    localStorage.setItem('user.waas_token', token)
    localStorage.setItem('user.wallet_id', String(initializeData.wallet_id))
    localStorage.setItem(
        'settings.locale',
        String(initializeData.auth_data.locale),
    )

    if (initializeData.token) {
        localStorage.setItem(
            'user.access_token',
            initializeData.token.access_token,
        )
        localStorage.setItem(
            'user.refresh_token',
            initializeData.token.refresh_token,
        )
    }
}
