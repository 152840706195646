import * as React from 'react'
import { Button, ModuleItem } from '@components'
import type { WalletModuleItem, WalletModuleParams } from '@wallet/hooks'
import { useWindowSize } from '@core/hooks'

type ModuleButtonProps = {
    module: WalletModuleItem
    params: WalletModuleParams
}

export const ModuleButton = ({ module, params }: ModuleButtonProps) => {
    const ScreenWidth = useWindowSize().width

    if (ScreenWidth < 1024) {
        return (
            <React.Fragment>
                {module.type === 'button' && (
                    <ModuleItem.Button
                        children={module.title}
                        variant={params.index === 0 ? 'primary' : 'secondary'}
                        icon={module.icon}
                        onClick={module.callback}
                    />
                )}
                {module.type === 'component' && module.component}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {module.type === 'button' && (
                <Button
                    children={module.title}
                    variant="ink-inverse"
                    leftIcon={module.icon}
                    className="pl-4 pr-6"
                    onClick={module.callback}
                />
            )}
            {module.type === 'component' && module.component}
        </React.Fragment>
    )
}
