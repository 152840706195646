import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { filter } from 'lodash'
import { useAppSelector } from '@core/store'
import walletApi from '@wallet/api'
import { WalletDetailLoader } from '@wallet/components'
import CampaignSection from './CampaignSection'
import HistorySection from './HistorySection'
import WalletDetailHeader from './WalletDetailHeader'
import './WalletDetail.css'

const WalletDetail = () => {
    const location = useLocation().state
    const walletId =
        location?.walletId || localStorage.getItem('user.wallet_id')

    const company = useAppSelector(state => state.baseSlice.company)

    const walletDetailsQuery = walletApi.useWalletDetailsQuery(
        { wallet_id: walletId },
        { refetchOnMountOrArgChange: true },
    )

    const wallet = React.useMemo(
        () => walletDetailsQuery.data?.data,
        [walletDetailsQuery.data],
    )

    const modules = React.useMemo(() => {
        return filter(
            walletDetailsQuery.data?.meta.waas_modules_sort_list,
            item => item.status,
        )
    }, [walletDetailsQuery.data?.meta])

    if (walletDetailsQuery.isLoading) return <WalletDetailLoader />

    if (!wallet) return null

    return (
        <React.Fragment>
            <WalletDetailHeader
                title={wallet.company.name}
                color={company.color}
                balance={wallet.balance}
                point={wallet.point}
                currency={wallet.company.currency}
                walletId={walletId}
                modules={modules}
            />
            <CampaignSection
                walletId={walletId}
                companyId={wallet.company_id}
                color={company.color}
                currency={wallet.company.currency}
            />
            <HistorySection walletId={walletId} />
        </React.Fragment>
    )
}

export default WalletDetail
