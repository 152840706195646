import * as React from 'react'
import { Skeleton } from '@components'
import { cn } from '@core/utils'

const RewardChartLoader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    ...props
}) => {
    return (
        <Skeleton
            className={cn(
                'h-[170px] w-full rounded-[20px] lg:h-60 lg:rounded-3xl',
                className,
            )}
            {...props}
        />
    )
}
export default RewardChartLoader
