/** @type {import('tailwindcss').Config} */

import TailwindAnimation from 'tailwindcss-animate'

export default {
    content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
    theme: {
        container: {
            center: true,
            screens: {
                '2xl': '1360px',
                'xl': '1280px',
                'lg': '1024px',
                'md': '100%',
                'sm': '100%',
            },
            padding: {
                'DEFAULT': '24px',
                'sm': '24px',
                'lg': '40px',
                'xl': '40px',
                '2xl': '40px',
            },
        },
        extend: {
            width: {
                '70': '280px',
            },
            minHeight: {
                inherit: 'inherit',
            },
            colors: {
                semantic: {
                    content: {
                        brand: 'var(--content-brand)',
                        inkInverse: 'var(--content-ink-inverse)',
                        inkInverseTonal: 'var(--content-ink-inverse-tonal)',
                        ink: 'var(--content-ink)',
                        inkMedium: 'var(--content-ink-medium)',
                        inkLight: 'var(--content-ink-light)',
                        disabled: 'var(--content-disabled)',
                        error: 'var(--content-error)',
                        success: 'var(--content-success)',
                        info: 'var(--content-info)',
                        link: 'var(--content-link)',
                    },
                    background: {
                        inkInverse: 'var(--background-ink-inverse)',
                        primary: 'var(--background-primary)',
                        secondary: 'var(--background-secondary)',
                        tertiary: 'var(--background-tertiary)',
                        quaternary: 'var(--background-quaternary)',
                    },
                },
                solid: {
                    core: {
                        black: 'var(--solid-core-black)',
                        white: 'var(--solid-core-white)',
                        transparent: 'var(--solid-core-transparent)',
                    },
                    brand: {
                        1: 'var(--brand-1)',
                        2: 'var(--brand-2)',
                        3: 'var(--brand-3)',
                        4: 'var(--brand-4)',
                        5: 'var(--brand-5)',
                        6: 'var(--brand-6)',
                        7: 'var(--brand-7)',
                    },
                    ink: {
                        1: 'var(--ink-1)',
                        2: 'var(--ink-2)',
                        3: 'var(--ink-3)',
                        4: 'var(--ink-4)',
                        5: 'var(--ink-5)',
                        6: 'var(--ink-6)',
                        7: 'var(--ink-7)',
                        8: 'var(--ink-8)',
                        9: 'var(--ink-9)',
                    },
                    blue: {
                        1: 'var(--blue-1)',
                        2: 'var(--blue-2)',
                        3: 'var(--blue-3)',
                        4: 'var(--blue-4)',
                        5: 'var(--blue-5)',
                        6: 'var(--blue-6)',
                        7: 'var(--blue-7)',
                    },
                    green: {
                        1: 'var(--green-1)',
                        2: 'var(--green-2)',
                        3: 'var(--green-3)',
                        4: 'var(--green-4)',
                        5: 'var(--green-5)',
                        6: 'var(--green-6)',
                        7: 'var(--green-7)',
                    },
                    purple: {
                        1: 'var(--purple-1)',
                        2: 'var(--purple-2)',
                        3: 'var(--purple-3)',
                        4: 'var(--purple-4)',
                        5: 'var(--purple-5)',
                        6: 'var(--purple-6)',
                        7: 'var(--purple-7)',
                    },
                    yellow: {
                        1: 'var(--yellow-1)',
                        2: 'var(--yellow-2)',
                        3: 'var(--yellow-3)',
                        4: 'var(--yellow-4)',
                        5: 'var(--yellow-5)',
                        6: 'var(--yellow-6)',
                        7: 'var(--yellow-7)',
                    },
                    red: {
                        1: 'var(--red-1)',
                        2: 'var(--red-2)',
                        3: 'var(--red-3)',
                        4: 'var(--red-4)',
                        5: 'var(--red-5)',
                        6: 'var(--red-6)',
                        7: 'var(--red-7)',
                    },
                    orange: {
                        1: 'var(--orange-1)',
                        2: 'var(--orange-2)',
                        3: 'var(--orange-3)',
                        4: 'var(--orange-4)',
                        5: 'var(--orange-5)',
                        6: 'var(--orange-6)',
                        7: 'var(--orange-7)',
                    },
                    sand: {
                        1: 'var(--sand-1)',
                        2: 'var(--sand-2)',
                        3: 'var(--sand-3)',
                        4: 'var(--sand-4)',
                        5: 'var(--sand-5)',
                        6: 'var(--sand-6)',
                        7: 'var(--sand-7)',
                    },
                },
                tint: {
                    brand: {
                        5: 'var(--brand-tint-5)',
                        10: 'var(--brand-tint-10)',
                        20: 'var(--brand-tint-20)',
                        40: 'var(--brand-tint-40)',
                        60: 'var(--brand-tint-60)',
                        80: 'var(--brand-tint-80)',
                        100: 'var(--brand-tint-100)',
                    },
                    ink: {
                        5: 'var(--ink-tint-5)',
                        10: 'var(--ink-tint-10)',
                        20: 'var(--ink-tint-20)',
                        40: 'var(--ink-tint-40)',
                        60: 'var(--ink-tint-60)',
                        80: 'var(--ink-tint-80)',
                        100: 'var(--ink-tint-100)',
                    },
                    white: {
                        5: 'var(--white-tint-5)',
                        10: 'var(--white-tint-10)',
                        20: 'var(--white-tint-20)',
                        40: 'var(--white-tint-40)',
                        60: 'var(--white-tint-60)',
                        80: 'var(--white-tint-80)',
                        100: 'var(--white-tint-100)',
                    },
                    blue: {
                        5: 'var(--blue-tint-5)',
                        10: 'var(--blue-tint-10)',
                        20: 'var(--blue-tint-20)',
                        40: 'var(--blue-tint-40)',
                        60: 'var(--blue-tint-60)',
                        80: 'var(--blue-tint-80)',
                        100: 'var(--blue-tint-100)',
                    },
                    green: {
                        5: 'var(--green-tint-5)',
                        10: 'var(--green-tint-10)',
                        20: 'var(--green-tint-20)',
                        40: 'var(--green-tint-40)',
                        60: 'var(--green-tint-60)',
                        80: 'var(--green-tint-80)',
                        100: 'var(--green-tint-100)',
                    },
                    purple: {
                        5: 'var(--purple-tint-5)',
                        10: 'var(--purple-tint-10)',
                        20: 'var(--purple-tint-20)',
                        40: 'var(--purple-tint-40)',
                        60: 'var(--purple-tint-60)',
                        80: 'var(--purple-tint-80)',
                        100: 'var(--purple-tint-100)',
                    },
                    yellow: {
                        5: 'var(--yellow-tint-5)',
                        10: 'var(--yellow-tint-10)',
                        20: 'var(--yellow-tint-20)',
                        40: 'var(--yellow-tint-40)',
                        60: 'var(--yellow-tint-60)',
                        80: 'var(--yellow-tint-80)',
                        100: 'var(--yellow-tint-100)',
                    },
                    red: {
                        5: 'var(--red-tint-5)',
                        10: 'var(--red-tint-10)',
                        20: 'var(--red-tint-20)',
                        40: 'var(--red-tint-40)',
                        60: 'var(--red-tint-60)',
                        80: 'var(--red-tint-80)',
                        100: 'var(--red-tint-100)',
                    },
                    orange: {
                        5: 'var(--orange-tint-5)',
                        10: 'var(--orange-tint-10)',
                        20: 'var(--orange-tint-20)',
                        40: 'var(--orange-tint-40)',
                        60: 'var(--orange-tint-60)',
                        80: 'var(--orange-tint-80)',
                        100: 'var(--orange-tint-100)',
                    },
                },
            },
            fontSize: {
                'display-xs': [
                    '14px',
                    {
                        lineHeight: '16px',
                        fontWeight: '600',
                        letterSpacing: '-0.02em',
                    },
                ],
                'display-sm': [
                    '18px',
                    {
                        lineHeight: '24px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'display-md-b': [
                    '20px',
                    {
                        lineHeight: '32px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'display-md-r': [
                    '20px',
                    {
                        lineHeight: '32px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'display-lg': [
                    '24px',
                    {
                        lineHeight: '32px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'display-lg-b': [
                    '24px',
                    {
                        lineHeight: '32px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'display-hg': [
                    '28px',
                    {
                        lineHeight: '40px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'title-sm': [
                    '32px',
                    {
                        lineHeight: '48px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'title-sm-b': [
                    '32px',
                    {
                        lineHeight: '48px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'title-md': [
                    '36px',
                    {
                        lineHeight: '48px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'title-lg-b': [
                    '56px',
                    {
                        lineHeight: '56px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'title-lg-r': [
                    '56px',
                    {
                        lineHeight: '56px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'body-sm-b': [
                    '11px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'body-sm-r': [
                    '11px',
                    {
                        lineHeight: '16px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'body-md-b': [
                    '13px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'body-md-r': [
                    '13px',
                    {
                        lineHeight: '16px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'body-lg-b': [
                    '15px',
                    {
                        lineHeight: '24px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'body-lg-r': [
                    '15px',
                    {
                        lineHeight: '24px',
                        fontWeight: '400',
                        letterSpacing: '-0.02em',
                    },
                ],
                'label-sm': [
                    '11px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'label-md': [
                    '12px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'label-lg': [
                    '14px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'link-sm': [
                    '11px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'link-md': [
                    '13px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'link-lg': [
                    '15px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '-0.02em',
                    },
                ],
                'overline-md': [
                    '10px',
                    {
                        lineHeight: '12px',
                        fontWeight: '700',
                        letterSpacing: '0.04em',
                    },
                ],
                'overline-lg': [
                    '13px',
                    {
                        lineHeight: '16px',
                        fontWeight: '700',
                        letterSpacing: '0.04em',
                    },
                ],
            },
        },
    },
    plugins: [TailwindAnimation],
}
